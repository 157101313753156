import React from "react";
import { Button } from "@mui/material";

export const ModalType = {
  DeleteRow: 0,
  SaveChanges: 1,
};

function DeleteRowModal({ modaltype, onClose, onConfirmDelete }) {
  const getDeleteRowModalTitle = () => {
    switch (modaltype) {
      case ModalType.DeleteRow:
        return "THE TRANSLATED ITEM ASSOCIATED WITH THIS TEXT WILL ALSO BE DELETED";
      case ModalType.SaveChanges:
        return "WOULD YOU LIKE TO SAVE CHANGES?";
      default:
        console.warn("modal type not set!");
        return "";
    }
  };
  const getDeleteRowModalMsg = () => {
    switch (modaltype) {
      case ModalType.DeleteRow:
        return "Do you wish to continue?";
      case ModalType.SaveChanges:
        return "Changes will be saved to the database";
      default:
        console.warn("modal type not set!");
        return "";
    }
  };

  return (
    <div>
      <div className="warningModal">
        <h3 className="warning padding">
          {getDeleteRowModalTitle()}
          <br />
          <br />
          <span className="warningModal_fontColor">
            {getDeleteRowModalMsg()}
          </span>
        </h3> 
        <div className="buttonStyles">
          <Button
            variant="contained"
            color="primary"
            className="warningModal-btn continue"
            onClick={() => {
              onConfirmDelete();
              onClose();
            }}
          >
            CONTINUE
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="warningModal-btn cancel"
            onClick={() => onClose()}
          >
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DeleteRowModal;
