import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from './authentication.service';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        let perms
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        } else {
            perms = (currentUser.perms)
            if (props.location.pathname.includes("dupes") && !perms.dupes) {
                return <Redirect to={{ pathname: '/' }} />
            } else if (props.location.pathname.includes("admin") && !perms.admin) {
                return <Redirect to={{ pathname: '/' }} />
            } else if (props.location.pathname.includes("import") && !perms.import) {
                return <Redirect to={{ pathname: '/' }} />
            } else if (props.location.pathname.includes("personas") && !(perms.p1 || perms.p2 || perms.p3)) {
                return <Redirect to={{ pathname: '/' }} />
            } else if (props.location.pathname.includes("personas/edit") && !(perms.p2)) {
                return <Redirect to={{ pathname: '/personas' }} />
            } else if (props.location.pathname.includes("content") && !(perms.c1 || perms.c2 || perms.c3)) {
                return <Redirect to={{ pathname: '/' }} />
            } else if (props.location.pathname.includes("content/edit") && !perms.c2) {
                if (!props.location.pathname.includes("/new") && perms.c1) {
                    return <Redirect to={{ pathname: '/' }} />
                }
            }
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)