import React from "react";
import { Link } from "react-router-dom";
import Header from "../header/header";
import { authenticationService } from "../scripts/authentication.service";
import { URL } from "../scripts/constants";
import {
  Autocomplete,
  Button,
  TextField,
  LinearProgress,
  Checkbox,
} from "@mui/material";
import { cf } from "../scripts/custom-fetch";
import { withSnackbar } from "notistack";
import Users from "./components/users";
import Settings from "./components/settings";
import Audit from "./components/audit";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Lang from "./components/lang";
// import Langids from "./components/langids";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const permOptions = [
  { value: "dev", label: "Developer" },
  { value: "admin", label: "Admin" },
  { value: "c1", label: "Content Add" },
  { value: "c2", label: "Content Edit" },
  { value: "c3", label: "Content Delete" },
  { value: "p1", label: "Persona Add" },
  { value: "p2", label: "Persona Edit" },
  { value: "p3", label: "Persona Delete" },
  { value: "import", label: "Import" },
  { value: "dupes", label: "Duplicate Manager" },
];

class admin extends React.Component {
  constructor(props) {
    super(props);

    this.handlePerms = this.handlePerms.bind(this);
    this.handleCheck = this.handleCheck.bind(this);

    this.state = {
      error: null,
      errors: {},
      isLoaded: false,
      items: [],
      currentUser: authenticationService.currentUserValue,
      edit: false,
      langs: [],
      countries: [],
      fetching: false,
      settings: {
        id: 1,
        christmas: 0,
        easter: 0,
        halloween: 0,
        newyear: 0,
        online: 1,
      },
      page: 0,
      user: "",
      pass: "",
      pic: "",
      selectPerms: [{ value: "admin", label: "Admin" }],
      perms: {
        admin: 0,
        dev: 0,
        c1: 0,
        c2: 0,
        c3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        import: 0,
        dupes: 0,
      },
      enabled: 1,
      posting: false,
      open: false,
    };
  }

  setPage = (page) => {
    this.setState({ page: page });
  };

  open() {
    this.setState({
      open: true,
      edit: false,
      user: "",
      pass: "",
      pic: "",
      perms: {
        admin: 0,
        dev: 0,
        c1: 0,
        c2: 0,
        c3: 0,
        p1: 0,
        p2: 0,
        p3: 0,
        import: 0,
        dupes: 0,
      },
      enabled: 1,
    });
  }

  openEdit = (user) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
      },
      body: JSON.stringify({
        user: user,
      }),
    };

    this.setState(
      {
        fetching: true,
      },
      () => {
        cf.get(`${process.env.REACT_APP_API_GET}/admin/getUser`, options).then(
          (result) => {
            let perms = {
              admin: result[0].admin,
              dev: result[0].dev,
              c1: result[0].c1,
              c2: result[0].c2,
              c3: result[0].c3,
              p1: result[0].p1,
              p2: result[0].p2,
              p3: result[0].p3,
              import: result[0].import,
              dupes: result[0].dupes,
            };
            let temp = this.getPerms(perms);
            this.setState({
              edit: true,
              open: true,
              user: result[0].username,
              pass: "negative",
              pic: result[0].pic,
              perms: perms,
              selectPerms: temp,
              enabled: result[0].enabled,
              fetching: false,
            });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    );
  };

  close = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
    });
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  getSettings = () => {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    cf.get(`${process.env.REACT_APP_API_GET}/settings`, options).then(
      (result) => {
        this.setState({
          settings: result.settings,
        });
      }
    );
  };

  getLang = () => {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    cf.get(`${process.env.REACT_APP_API_GET}/lang/getAllLang`, options).then(
      (result) => {
        this.setState({
          langs: result,
        });
      }
    );
  };

  getCountry = () => {
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    cf.get(
      `${process.env.REACT_APP_API_GET}/country/getAllCountry`,
      options
    ).then((result) => {
      this.setState({
        country: result,
      });
    });
  };

  enableUser = (user) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
      },
      body: JSON.stringify({
        user: user,
      }),
    };

    cf.get(`${process.env.REACT_APP_API_POST}/admin/enable`, options).then(
      (result) => {
        console.log(result);
        this.callPersona();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  disableUser = (user) => {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
      },
      body: JSON.stringify({
        user: user,
      }),
    };

    cf.get(`${process.env.REACT_APP_API_POST}/admin/disable`, options).then(
      (result) => {
        console.log(result);
        this.callPersona();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  validate = () => {
    let errors = {};
    let valid = true;

    if (!this.state.user || this.state.user.length < 1) {
      valid = false;
      errors.name = "Username must exist";
    }

    if (!this.state.pass || this.state.pass.length < 1) {
      valid = false;
      errors.channel_name = "Password must exist";
    } else if (this.state.pass.length < 8) {
      valid = false;
      errors.channel_name = "Password must be longer than 8 characters";
    }

    this.setState({ errors: errors });
    return valid;
  };

  postUser(user, pass, pic, perms) {
    this.props.enqueueSnackbar("Validating", { variant: "info" });
    if (this.validate()) {
      this.props.enqueueSnackbar("Saving", { variant: "info" });
      this.setState({
        posting: true,
      });

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
        },
        body: JSON.stringify({
          user: user,
          pass: pass,
          pic: pic,
          perms: perms,
        }),
      };

      if (this.state.edit) {
        cf.get(
          `${process.env.REACT_APP_API_POST}/admin/updateUser`,
          options
        ).then(
          (result) => {
            console.log(result);
            this.props.enqueueSnackbar("Saved", { variant: "success" });
            this.callPersona();
            this.setState({
              posting: false,
              edit: false,
              open: false,
            });
          },
          (error) => {
            this.props.enqueueSnackbar("Save Failed - Please Try Again", {
              variant: "warning",
            });
            console.log(error);
          }
        );
      } else {
        cf.get(`${process.env.REACT_APP_API_POST}/admin/addUser`, options).then(
          (result) => {
            console.log(result);
            this.props.enqueueSnackbar("Saved", { variant: "success" });
            this.callPersona();
            this.setState({
              posting: false,
              open: false,
            });
          },
          (error) => {
            this.props.enqueueSnackbar("Save Failed - Please Try Again", {
              variant: "warning",
            });
            console.log(error);
          }
        );
      }
    } else {
      this.props.enqueueSnackbar("Validation Failed Please Check Your Inputs", {
        variant: "error",
      });
    }
  }

  handleCheck(event) {
    let settings = this.state.settings;
    let currentUser = this.state.currentUser;

    if (currentUser.perms.dev) {
      switch (event.target.name) {
        case "online":
          if (settings.online) {
            this.props.enqueueSnackbar("Enable Maintenance", {
              variant: "info",
            });
            settings.online = 0;
          } else {
            this.props.enqueueSnackbar("Disable Maintenance", {
              variant: "info",
            });
            settings.online = 1;
          }
          break;
        case "christmas":
          if (settings.christmas) {
            this.props.enqueueSnackbar("Disable Christmas", {
              variant: "info",
            });
            settings.christmas = 0;
          } else {
            this.props.enqueueSnackbar("Enable Christmas", { variant: "info" });
            settings.christmas = 1;
          }
          break;
        case "halloween":
          if (settings.halloween) {
            this.props.enqueueSnackbar("Disable Halloween", {
              variant: "info",
            });
            settings.halloween = 0;
          } else {
            this.props.enqueueSnackbar("Enable Halloween", { variant: "info" });
            settings.halloween = 1;
          }
          break;
        case "easter":
          if (settings.easter) {
            this.props.enqueueSnackbar("Disable Easter", { variant: "info" });
            settings.easter = 0;
          } else {
            this.props.enqueueSnackbar("Enable Easter", { variant: "info" });
            settings.easter = 1;
          }
          break;
        case "newyear":
          if (settings.newyear) {
            this.props.enqueueSnackbar("Disable New Year", { variant: "info" });
            settings.newyear = 0;
          } else {
            this.props.enqueueSnackbar("Enable New Year", { variant: "info" });
            settings.newyear = 1;
          }
          break;
        default:
          console.log("Invalid Type");
      }
      this.setSetting(settings);
      this.setState({
        settings: settings,
      });
    } else {
      this.props.enqueueSnackbar("Sorry your not a developer", {
        variant: "info",
      });
    }
  }

  setSetting(settings) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
      },
      body: JSON.stringify({ settings: settings }),
    };
    cf.get(URL + "/admin/setSettings", options).then((result) => {
      this.props.enqueueSnackbar("Updated Settings", { variant: "success" });
      console.log(result);
    });
  }

  callPersona() {
    const options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
      },
    };
    cf.get(URL + "/admin/getUsers", options).then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result,
        });
      },
      (error) => {
        this.setState({
          isLoaded: true,
          error,
        });
      }
    );
  }

  componentDidMount() {
    this.callPersona();
    this.getSettings();
    this.getLang();
    this.getCountry();
  }

  handlePerms(event, newValue) {
    let perms = {
      admin: 0,
      dev: 0,
      c1: 0,
      c2: 0,
      c3: 0,
      p1: 0,
      p2: 0,
      p3: 0,
      import: 0,
      dupes: 0,
    };
    newValue.forEach((item) => {
      perms[item.value] = 1;
    });
    this.setState({
      perms: perms,
    });
  }

  getPerms(data) {
    let perms = permOptions.filter((obj) => {
      return data[obj.value];
    });
    return perms;
  }

  render() {
    const {
      error,
      errors,
      isLoaded,
      items,
      user,
      settings,
      pass,
      pic,
      perms,
      selectPerms,
      open,
      fetching,
      page,
      // langs,
      // country,
    } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return (
        <>
          <Header />
          <div style={{ width: "50%" }}>
            <LinearProgress />
          </div>
        </>
      );
    } else {
      return (
        <>
          <Header />
          <div className="social">
            <div className="top-selector-container">
                <div className="top-selector">
              <div
                className={page === 0 ? "items selected" : "items"}
                onClick={() => this.setPage(0)}
              >
                Enabled
              </div>

              <div
                className={page === 1 ? "items selected" : "items"}
                onClick={() => this.setPage(1)}
              >
                Disabled
              </div>
              <div
                className={page === 2 ? "items selected" : "items"}
                onClick={() => this.setPage(2)}
              >
                Site Settings
              </div>
              <div
                className={page === 3 ? "items selected" : "items"}
                onClick={() => this.setPage(3)}
              >
                Logs
              </div>
              {/* <div className={page === 4 ? "items selected" : "items"} onClick={ () => this.setPage(4)}>Languages</div> */}
              {/* <div
                className={page === 5 ? "items selected" : "items"}
                onClick={() => this.setPage(5)}
              >
                Lang IDs
              </div> */}
            </div> 
            </div>
         
            <div className="box">
              {(page === 0 || page === 1) && (
                <Users
                  page={page}
                  items={items}
                  openEdit={this.openEdit}
                  disableUser={this.disableUser}
                  enableUser={this.enableUser}
                />
              )}
              {page === 2 && (
                <Settings settings={settings} handleCheck={this.handleCheck} />
              )}
              {page === 3 && <Audit />}
              {page === 4 && <Lang />}
              {/* {page === 5 && <Langids country={country} langs={langs} />} */}
            </div>
            {(page === 0 || page === 1) && (
              <div className="links">
                <Link to="/">
                  <Button
                    variant="contained"
                    color="primary"
                    className="spacer"
                  >
                    Back
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.open()}
                >
                  New
                </Button>
              </div>
            )}
          </div>
          <div className={open ? "user-box" : "user-box hide"}>
            <div
              className={
                open ? "user-container" : "user-container user-container-closed"
              }
            >
              <TextField
                error={!!errors.user}
                helperText={errors.user}
                variant="outlined"
                label="User"
                name="user"
                value={user}
                onChange={(e) => this.handleChange(e)}
              />
              <TextField
                error={!!errors.pass}
                helperText={errors.pass}
                style={{ marginTop: "10px" }}
                variant="outlined"
                label="Pass"
                name="pass"
                type="password"
                value={pass}
                onChange={(e) => this.handleChange(e)}
              />
              <TextField
                style={{ marginTop: "10px" }}
                variant="outlined"
                label="Pic"
                name="pic"
                value={pic}
                onChange={(e) => this.handleChange(e)}
              />
              {fetching ? (
                ""
              ) : (
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  defaultValue={selectPerms}
                  renderInput={(params) => (
                    <TextField
                      style={{ marginTop: "10px" }}
                      variant="outlined"
                      {...params}
                      label="Permissions"
                    />
                  )}
                  options={permOptions}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  getOptionLabel={(option) => option.label}
                  onChange={this.handlePerms}
                />
              )}
              <Button
                style={{ marginTop: "10px" }}
                variant="contained"
                color="primary"
                onClick={() => this.postUser(user, pass, pic, perms)}
              >
                Submit
              </Button>
              <Button
                style={{ marginTop: "10px" }}
                variant="contained"
                color="primary"
                onClick={(e) => this.close(e)}
              >
                Close
              </Button>
            </div>
          </div>
        </>
      );
    }
  }
}

export default withSnackbar(admin);
