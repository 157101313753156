import React from 'react';
import {Link} from "react-router-dom";
import Header from "../../header/header";
import {authenticationService} from "../../scripts/authentication.service";
import { CSVReader, CSVDownloader } from 'react-papaparse';
import {Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {cf} from "../../scripts/custom-fetch";
import {withSnackbar} from "notistack";

const fileNames = {
    content: {
        shortName: 'mcw-1.2',
        longName: 'LS - Main Content - v1.2',
        displayName: 'Main Content - v1.2'
    },
    contentPersona: {
        shortName: 'mcp-1.2',
        longName: 'LS - Main Content With Persona - v1.2',
        displayName: 'Main Content With Persona - v1.2'
    },
    stims: {
        shortName: 'msw-1.3',
        longName: 'LS - Main Sponsored Content - v1.3',
        displayName: 'Main Sponsored Content - v1.3'
    },
    stimsPersona: {
        shortName: 'msp-1.3',
        longName: 'LS - Main Sponsored With Persona - v1.3',
        displayName: 'Main Sponsored With Persona - v1.3'
    },
    story: {
        shortName: 'stw-1.2',
        longName: 'LS - Story Content - v1.2',
        displayName: 'Story Content - v1.2'
    },
    storyPersona: {
        shortName: 'stp-1.2',
        longName: 'LS - Story Content With Persona - v1.2',
        displayName: 'Story Content With Persona - v1.2'
    },
    reel: {
        shortName: 'rcw-1.0',
        longName: 'LS - Reel Content - v1.0',
        displayName: 'Reel Content - v1.0'
    },
    reelPersona: {
        shortName: 'rcp-1.0',
        longName: 'LS - Reel Content With Persona - v1.0',
        displayName: 'Reel Content With Persona - v1.0'
    },
    reelStim: {
        shortName: 'rsw-1.0',
        longName: 'LS - Reel Stim - v1.0',
        displayName: 'Reel Stim - v1.0'
    },
    reelStimPersona: {
        shortName: 'rsp-1.0',
        longName: 'LS - Reel Stim With Persona - v1.0',
        displayName: 'Reel Stim With Persona - v1.0'
    },
    music: {
        shortName: 'mmw-1.0',
        longName: 'LS - Music Content - v1.0',
        displayName: 'Music Content - v1.0'
    },
    musicPlaylist: {
        shortName: 'mmp-1.0',
        longName: 'LS - Music Content With Playlist - v1.0',
        displayName: 'Music Content With Playlist - v1.0'
    },
    musicStim: {
        shortName: 'mpw-1.0',
        longName: 'LS - Music Sponsored Content - v1.0',
        displayName: 'Music Sponsored Content - v1.0'
    },
    musicStimPersona: {
        shortName: 'mpp-1.0',
        longName: 'LS - Music Sponsored Content With Playlist - v1.0',
        displayName: 'Music Sponsored Content With Playlist - v1.0'
    },
}

class Import extends React.Component {
    constructor(props) {
        super(props);

        this.handleRemove = this.handleRemove.bind(this);
        this.import = this.import.bind(this);
        this.checkInfo = this.checkInfo.bind(this);

        this.state = {
            error: null,
            isSending: true,
            response: [],
            msg: "",
            data: [],
            currentUser: authenticationService.currentUserValue,
            type: 1,
        };
    }

    checkInfo() {
        this.props.enqueueSnackbar('Checking Data', {variant:"info"});
        let data = this.state.data[0]
        switch (this.state.type){
            case 1:
                if (data.data.file === fileNames.content.shortName || data.data.file === fileNames.contentPersona.shortName) {
                    this.import()
                } else {
                    this.props.enqueueSnackbar('Incorrect Type Selected', {variant:"error"});
                }
                break;
            case 2:
                if (data.data.file === fileNames.stims.shortName || data.data.file === fileNames.stimsPersona.shortName) {
                    this.import()
                } else {
                    this.props.enqueueSnackbar('Incorrect Type Selected', {variant:"error"});
                }
                break;
            case 3:
                if (data.data.file === fileNames.story.shortName || data.data.file === fileNames.storyPersona.shortName) {
                    this.import()
                } else {
                    this.props.enqueueSnackbar('Incorrect Type Selected', {variant:"error"});
                }
                break;
            case 4:
                if (data.data.file === fileNames.content.shortName || data.data.file === fileNames.contentPersona.shortName) {
                    this.import()
                } else {
                    this.props.enqueueSnackbar('Incorrect Type Selected', {variant:"error"});
                }
                break;
            case 5:
                if (data.data.file === fileNames.content.shortName || data.data.file === fileNames.contentPersona.shortName) {
                    this.import()
                } else {
                    this.props.enqueueSnackbar('Incorrect Type Selected', {variant:"error"});
                }
                break;
            case 6:
                if (data.data.file === fileNames.reel.shortName || data.data.file === fileNames.reelPersona.shortName) {
                    this.import()
                } else {
                    this.props.enqueueSnackbar('Incorrect Type Selected', {variant:"error"});
                }
                break;
            case 7:
                if (data.data.file === fileNames.reelStim.shortName || data.data.file === fileNames.reelStimPersona.shortName) {
                    this.import()
                } else {
                    this.props.enqueueSnackbar('Incorrect Type Selected', {variant:"error"});
                }
                break;
            case 10:
                if (data.data.file === fileNames.music.shortName || data.data.file === fileNames.musicPlaylist.shortName) {
                    this.import()
                } else {
                    this.props.enqueueSnackbar('Incorrect Type Selected', {variant:"error"});
                }
                break;
            case 11:
                if (data.data.file === fileNames.musicStim.shortName || data.data.file === fileNames.musicStimPersona.shortName) {
                    this.import()
                } else {
                    this.props.enqueueSnackbar('Incorrect Type Selected', {variant:"error"});
                }
                break;
            default:
                this.props.enqueueSnackbar('Type out of bounds contact admin', {variant:"error"});
                break;
        }
    }

    import() {
        this.props.enqueueSnackbar('Importing', {variant:"info"});
        this.setState({
            isSending: true
        }, () => {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                body: JSON.stringify({
                    'data': this.state.data,
                    'type': this.state.type
                })
            };

            cf.get(`${process.env.REACT_APP_API_POST}/import/import`, options)
                .then((result) => {
                        console.log(result)
                        if (result.result.contentFailed.length > 0 || result.result.linkingFailed.length > 0 || result.result.profilesFailed.length > 0) {
                            this.props.enqueueSnackbar('Saved with errors see below', {variant:"warning"});
                        } else {
                            this.props.enqueueSnackbar('Saved', {variant:"success"});
                        }
                        this.setState({
                            response: result.result,
                            isSending: false,
                        })
                    },
                    (error) => {
                        console.log(error)
                        this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                    }
                )
        })
    }

    handleDrop = (data) => {
        console.log(data);
        this.setState({
            data: data,
            isSending: false,
        })
    }

    handleErr(err, file, inputElem, reason) {
        console.log(err);
    }

    handleRemove(data) {
        console.log(data);
        this.setState({
            data: data,
            isSending: true
        })
    }

    selectImportType = (event) => {
        this.setState({type: event.target.value})
    }

    render() {
        const { error, isSending, type, response } = this.state;
        console.log(response)
        if (error) {
            return <div>Error: {error.message}</div>;
        } else {
            return (
                <>
                    <Header/>
                    <div className="social">
                        <div className="box post-edit" style={{alignItems: "flex-start"}}>
                            <div className="post-panel" style={{width:"400px"}}>
                                <div id="CONTENT">
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "desc": "Example Feed Item",
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "location": "London",
                                                    "comments": "12",
                                                    "likes": "52",
                                                    "shares": "1",
                                                    "posted_on": "2d",
                                                    "viewability_tracking": false,
                                                    "like": true,
                                                    "love": false,
                                                    "haha": true,
                                                    "wow": false,
                                                    "sad": false,
                                                    "angry": false,
                                                    "lang_id": 1,
                                                    "file": fileNames.content.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.content.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.content.displayName}</Button>
                                    </CSVDownloader>
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "desc": "Example Feed Item",
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "location": "London",
                                                    "comments": "12",
                                                    "likes": "52",
                                                    "shares": "1",
                                                    "posted_on": "2d",
                                                    "viewability_tracking": false,
                                                    "persona": 14,
                                                    "order": 1,
                                                    "like": true,
                                                    "love": false,
                                                    "haha": true,
                                                    "wow": false,
                                                    "sad": false,
                                                    "angry": false,
                                                    "lang_id": 1,
                                                    "file": fileNames.contentPersona.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.contentPersona.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.contentPersona.displayName}</Button>
                                    </CSVDownloader>
                                </div>
                                <div id="ADS">
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "desc": "Example AD Item",
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "promo_desc": "Example AD Desc",
                                                    "promo_desc_2": "Example AD Desc 2",
                                                    "promo_link": "www.example.com",
                                                    "promo_button": "Shop Now",
                                                    "location": "Sponsored",
                                                    "comments": "12",
                                                    "likes": "52",
                                                    "shares": "1",
                                                    "posted_on": "Sponsored",
                                                    "promoted": true,
                                                    "viewability_tracking": true,
                                                    "like": true,
                                                    "love": false,
                                                    "haha": true,
                                                    "wow": false,
                                                    "sad": false,
                                                    "angry": false,
                                                    "lang_id": 1,
                                                    "file": fileNames.stims.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.stims.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.stims.displayName}</Button>
                                    </CSVDownloader>
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "desc": "Example AD Item",
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "promo_desc": "Example AD Desc",
                                                    "promo_desc_2": "Example AD Desc 2",
                                                    "promo_link": "www.example.com",
                                                    "promo_button": "Shop Now",
                                                    "location": "Sponsored",
                                                    "comments": "12",
                                                    "likes": "52",
                                                    "shares": "1",
                                                    "posted_on": "Sponsored",
                                                    "promoted": true,
                                                    "viewability_tracking": true,
                                                    "persona": 14,
                                                    "order": 1,
                                                    "like": true,
                                                    "love": false,
                                                    "haha": true,
                                                    "wow": false,
                                                    "sad": false,
                                                    "angry": false,
                                                    "lang_id": 1,
                                                    "file": fileNames.stimsPersona.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.stimsPersona.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.stimsPersona.displayName}</Button>
                                    </CSVDownloader>
                                </div>
                                <div id="STORY">
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "cover": "https://i.imgur.com/PJ79wPV.png",
                                                    "cta": "Shop Now",
                                                    "link": "www.example.com",
                                                    "posted_on": "Sponsored",
                                                    "promoted": false,
                                                    "viewability_tracking": false,
                                                    "lang_id": 1,
                                                    "file": fileNames.story.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.story.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.story.displayName}</Button>
                                    </CSVDownloader>
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "cover": "https://i.imgur.com/PJ79wPV.png",
                                                    "cta": "Shop Now",
                                                    "link": "www.example.com",
                                                    "posted_on": "Sponsored",
                                                    "promoted": false,
                                                    "viewability_tracking": false,
                                                    "lang_id": 1,
                                                    "persona": 14,
                                                    "order": 1,
                                                    "file": fileNames.storyPersona.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.storyPersona.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.storyPersona.displayName}</Button>
                                    </CSVDownloader>
                                </div>
                                <div id="REEL">
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "desc": "Example Desc",
                                                    "cover_text": "Wow Great Snap",
                                                    "cover_src": "https://i.imgur.com/PJ79wPV.png",
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "song": "Everybody Needs Somebody to Love",
                                                    "comments": "12",
                                                    "likes": "122",
                                                    "shares": "13",
                                                    "lang_id": 1,
                                                    "file": fileNames.reel.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.reel.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.reel.displayName}</Button>
                                    </CSVDownloader>
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "desc": "Example Desc",
                                                    "cover_text": "Wow Great Snap",
                                                    "cover_src": "https://i.imgur.com/PJ79wPV.png",
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "song": "Everybody Needs Somebody to Love",
                                                    "comments": "12",
                                                    "likes": "122",
                                                    "shares": "13",
                                                    "lang_id": 1,
                                                    "persona": 14,
                                                    "order": 1,
                                                    "file": fileNames.reelPersona.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.reelPersona.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.reelPersona.displayName}</Button>
                                    </CSVDownloader>
                                </div>
                                <div id="REEL STIM">
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "desc": "Example Desc",
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "song": "Everybody Needs Somebody to Love",
                                                    "comments": "12",
                                                    "likes": "122",
                                                    "shares": "13",
                                                    "promo_a":"Promo Text",
                                                    "promo_b":"Promo Button / CTA",
                                                    "promo_c":"Promo Card / Promo Top",
                                                    "promo_d":"Promo Card Title / Promo Desc",
                                                    "promo_e":"Promo Rating",
                                                    "skip_time": 5,
                                                    "skippable": false,
                                                    "sound": false,
                                                    "lang_id": 1,
                                                    "file": fileNames.reelStim.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.reelStim.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.reelStim.displayName}</Button>
                                    </CSVDownloader>
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "name": "Steve",
                                                    "pic": "https://i.imgur.com/I6m9rKK.png",
                                                    "verified": false,
                                                    "company": false,
                                                    "desc": "Example Desc",
                                                    "src": "https://i.imgur.com/PJ79wPV.png",
                                                    "song": "Everybody Needs Somebody to Love",
                                                    "comments": "12",
                                                    "likes": "122",
                                                    "shares": "13",
                                                    "promo_a":"Promo Text",
                                                    "promo_b":"Promo Button / CTA",
                                                    "promo_c":"Promo Card / Promo Top",
                                                    "promo_d":"Promo Card Title / Promo Desc",
                                                    "promo_e":"Promo Rating",
                                                    "skip_time": 5,
                                                    "skippable": false,
                                                    "sound": false,
                                                    "persona": 14,
                                                    "order": 1,
                                                    "file": fileNames.reelStimPersona.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.reelStimPersona.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.reelStimPersona.displayName}</Button>
                                    </CSVDownloader>
                                </div>
                                <div id="MUSIC">
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "songName": "Merry-Go-Round",
                                                    "author": "MAN WITH A MISSION",
                                                    "song": "https://content.lumen-research.com/look-a-like-music/JRock/Merry-Go-Round.mp3",
                                                    "src": "https://i.imgur.com/qgqzZAt.png",
                                                    "lang_id": 1,
                                                    "file": fileNames.music.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.music.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.music.displayName}</Button>
                                    </CSVDownloader>
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "songName": "Merry-Go-Round",
                                                    "author": "MAN WITH A MISSION",
                                                    "song": "https://content.lumen-research.com/look-a-like-music/JRock/Merry-Go-Round.mp3",
                                                    "src": "https://i.imgur.com/qgqzZAt.png",
                                                    "lang_id": 1,
                                                    "playlist": 1,
                                                    "order": 1,
                                                    "file": fileNames.musicPlaylist.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.musicPlaylist.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.musicPlaylist.displayName}</Button>
                                    </CSVDownloader>
                                </div>
                                <div id="MUSIC AD">
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "promoText": "Wow so Cool",
                                                    "promoText2": "Its an ad jimmy",
                                                    "song": "https://content.lumen-research.com/look-a-like-music/JRock/Merry-Go-Round.mp3",
                                                    "src": "https://i.imgur.com/qgqzZAt.png",
                                                    "lang_id": 1,
                                                    "file": fileNames.musicStim.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.musicStim.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.musicStim.displayName}</Button>
                                    </CSVDownloader>
                                    <CSVDownloader
                                        data={() => {
                                            return [
                                                {
                                                    "title": "Example",
                                                    "promoText": "Wow so Cool",
                                                    "promoText2": "Its an ad jimmy",
                                                    "song": "https://content.lumen-research.com/look-a-like-music/JRock/Merry-Go-Round.mp3",
                                                    "src": "https://i.imgur.com/qgqzZAt.png",
                                                    "lang_id": 1,
                                                    "persona": 1,
                                                    "order": 1,
                                                    "file": fileNames.musicStimPersona.shortName
                                                }
                                            ]}
                                        }
                                        filename={fileNames.musicStimPersona.longName}
                                        type={'link'}
                                    >
                                        <Button style={{marginBottom: "10px", width: "100%"}} variant="contained">{fileNames.musicStimPersona.displayName}</Button>
                                    </CSVDownloader>
                                </div>
                            </div>
                            <div className="import">
                                <FormControl style={{marginBottom:"20px", width:"100%"}} variant="outlined">
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        value={type}
                                        onChange={this.selectImportType}
                                        label="Type"
                                    >
                                        <MenuItem value={1}>Main Content</MenuItem>
                                        <MenuItem value={2}>Main Sponsored</MenuItem>
                                        <MenuItem value={3}>Story Content/Sponsored</MenuItem>
                                        <MenuItem value={4}>Watch Content</MenuItem>
                                        <MenuItem value={5}>Watch Sponsored</MenuItem>
                                        <MenuItem value={6}>Reel Content</MenuItem>
                                        <MenuItem value={7}>Reel Sponsored</MenuItem>
                                        <MenuItem value={10}>Music Content</MenuItem>
                                        <MenuItem value={11}>Music Sponsored</MenuItem>
                                    </Select>
                                </FormControl>
                                <CSVReader
                                    onDrop={this.handleDrop}
                                    onError={this.handleErr}
                                    onRemoveFile={this.handleRemove}
                                    addRemoveButton
                                    config={{
                                        header: true,
                                        skipEmptyLines: true,
                                        transform: ( test => {return test.replace(/TRUE/g, "1").replace(/FALSE/g, "0").replace(/true/g, "1").replace(/false/g, "0")} ),
                                    }}
                                    style={{
                                        dropFile: {
                                            width: 200,
                                            height: "100%",
                                            color: "black"
                                        }
                                    }}
                                >
                                    <span>Drop CSV file here or click to upload.</span>
                                </CSVReader>

                                <div className="errors">
                                    {(response.profilesFailed) && (
                                        <div>
                                            <div className="titles">Profiles Failed</div>
                                            {response.profilesFailed.map((item) => (
                                                <div className="error-item">{JSON.stringify(item,null, 1)}</div>
                                            ))}
                                        </div>
                                    )}
                                    {(response.contentFailed) && (
                                        <div>
                                            <div className="titles">Content Failed</div>
                                            {response.contentFailed.map((item) => (
                                                <div className="error-item">{JSON.stringify(item,null, 1)}</div>
                                            ))}
                                        </div>
                                    )}
                                    {(response.linkingFailed) && (
                                        <div>
                                            <div className="titles">Linking Failed</div>
                                            {response.linkingFailed.map((item) => (
                                                <div className="error-item">{JSON.stringify(item,null, 1)}</div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div style={{width:"400px"}} />
                        </div>
                        <div className="links">
                            <Button variant="contained" className="spacer" disabled={isSending} onClick={this.checkInfo}>Import</Button>
                            <Button variant="contained"><Link to="/content">Back</Link></Button>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(Import);
