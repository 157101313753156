import {authenticationService} from "../scripts/authentication.service";
import {cf} from "../scripts/custom-fetch";

/**
 * Get the language options
 */
async function getLangs() {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`}
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/lang/getAllLang`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get the country options
 */
async function getCountries() {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`}
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/country/getAllCountry`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}


let exports = {
    getLangs,
    getCountries
}

export default exports;