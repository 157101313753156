import { BehaviorSubject } from 'rxjs';

import { handleResponse } from './handle-response';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

export const authenticationService = {
    login,
    logout,
    reToke,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${process.env.REACT_APP_API_POST}/user/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('currentUser', JSON.stringify(user));
            currentUserSubject.next(user);

            return user;
        });
}

function reToke(user) {
    return new Promise(function(resolve, reject) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"token": user.refreshToken})
        }

        fetch(`${process.env.REACT_APP_API_POST}/user/token`, requestOptions)
            .then((result) => {
                if (result.ok) {
                    result.text().then(text => {
                        const user = text && JSON.parse(text);
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        currentUserSubject.next(user);
                        resolve(user)
                    })
                } else {
                    resolve(false)
                }
            })
            .catch( err => {
                reject(err)
            })
    })
}

function logout(user) {
    var requestOptions = null
    try {
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"token": user.refreshToken})
        }
    } catch (e) {
        requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        }
    }

    fetch(`${process.env.REACT_APP_API_POST}/user/logout`, requestOptions)
        .then(res => res.text())
        .then(res => {
            console.log(res)
            localStorage.removeItem('currentUser');
            currentUserSubject.next(null);
            window.location.reload();
        })
        .catch(err => {
            console.log(err)
            localStorage.removeItem('currentUser');
            currentUserSubject.next(null)
            window.location.reload();
        });
}
