import React from 'react';
import {authenticationService} from "../scripts/authentication.service";
import Header from "../header/header";
import {Link} from "react-router-dom";
import {
    TextField,
    Switch,
    FormControlLabel,
    Button,
    LinearProgress,
    Avatar,
    FormControl,
    InputLabel, Select, MenuItem
} from '@mui/material'
import {withSnackbar} from "notistack";
import {cf} from "../scripts/custom-fetch";

class MusicEdit extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        this.state = {
            id: this.props.match.params.id,
            profile: null,
            isLoaded: false,
            error: null,
            errors: {},
            posting: false,
            locked: false,
        };
    }

    getMusic() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.id})
        };

        if (this.state.id === 'new') {
            console.log("New")
            this.setState({
                isLoaded: true,
                music: {
                    id: 'new',
                    title: '',
                    songName: '',
                    author: '',
                    song: '',
                    src: '',
                    type: 1,
                    viewability_tracking: 0,
                    lang_id: 1,
                }
            });
        } else {
            cf.get(`${process.env.REACT_APP_API_GET}/music/getMusic`, options)
                .then((result) => {
                        this.setState({
                            isLoaded: true,
                            music: result[0]
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }

    validate = () => {
        let music = this.state.music
        let errors = {}
        let valid = true
        if (!music.title || music.title.length < 1) {
            valid = false
            errors.title = "Title must exist"
        }
        if (escape(music.title).length >= 1024) {
            valid = false
            errors.title = "Title too long"
        }

        if (!music.songName || music.songName < 1) {
            valid = false
            errors.name = "Name must exist"
        }

        this.setState({errors: errors})
        return valid
    }

    updateMusic() {
        this.props.enqueueSnackbar('Validating', {variant:"info"});
        if(!this.validate()) {
            this.props.enqueueSnackbar('Validation Failed Please Check Your Inputs', {variant:"error"});
            return
        }
        this.props.enqueueSnackbar('Saving', {variant:"info"});
        let temp = this.state.music
        this.setState({
            posting: true,
        }, () => {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                body: JSON.stringify({
                    'id':this.state.id,
                    'content':temp
                })
            };

            if (this.state.id === 'new') {
                cf.get(`${process.env.REACT_APP_API_POST}/music/newMusic`, options)
                    .then((result) => {
                            console.log(result)
                            this.setState({
                                posting: false,
                                locked: true,
                            }, () => {
                                if (!!result) {
                                    this.props.enqueueSnackbar('Saved', {variant:"success"});
                                    this.props.enqueueSnackbar("To edit further find item in content list", { variant: "info" });
                                } else {
                                    this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                                }
                            });
                        },
                        (error) => {
                            console.log(error)
                            this.setState({
                                posting: false
                            }, () => {
                                this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                            });
                        }
                    )
            } else {
                cf.get(`${process.env.REACT_APP_API_POST}/music/setMusic`, options)
                    .then((result) => {
                            console.log(result)
                            this.setState({
                                posting: false
                            }, () => {
                                if (!!result) {
                                    this.props.enqueueSnackbar('Saved', {variant:"success"});
                                } else {
                                    this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                                }
                            });
                        },
                        (error) => {
                            console.log(error)
                            this.setState({
                                posting: false
                            }, () => {
                                this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                            });
                        }
                    )
            }
        })
    }

    componentDidMount() {
        this.getMusic()
    }

    handleChange(event) {
        let music = this.state.music;
        music[event.target.name] = event.target.value;
        this.setState({music: music})
    }

    handleCheck(event) {
        let music = this.state.music;
        if (music[event.target.name] === 1) {
            music[event.target.name] = 0
        } else {
            music[event.target.name] = 1
        }
        this.setState({music: music})
    }

    updateType = (event) => {
        let music = this.state.music
        music.type = event.target.value
        this.setState({music: music})
    }

    render() {
        const { error, errors, isLoaded, music, posting, locked } = this.state
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header />
                    <div className="social">
                        <div className="box post-edit">
                            <div className="post-panel">
                                <TextField error={!!errors.title} helperText={errors.title} style={{marginBottom: "10px"}} variant="outlined" label="Title" value={music.title} name="title" onChange={this.handleChange} />
                                <TextField error={!!errors.songName} helperText={errors.songName} style={{marginBottom: "10px"}} variant="outlined" label="Song Name" value={music.songName} name="songName" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Author" value={music.author} name="author" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Song" value={music.song} name="song" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Cover" value={music.src} name="src" onChange={this.handleChange} />
                                <FormControl style={{width:"100%"}} variant="outlined" color="primary" focused>
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        value={music.type}
                                        onChange={this.updateType}
                                        className="foreInputWhite"
                                        label="Type"
                                    >
                                        <MenuItem value={1}>Music</MenuItem>
                                        <MenuItem value={2}>Podcast</MenuItem>
                                        <MenuItem value={3}>Radio</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                    className="spacer"
                                    control={
                                        <Switch
                                            checked={music.viewability_tracking}
                                            onChange={this.handleCheck}
                                            name="viewability_tracking"
                                            color="primary"
                                        />
                                    }
                                    label="Viewability Tracking"
                                />
                            </div>
                            <div className="profile-container">
                                <div className="image">
                                    <Avatar src={music.src} sx={{ width: 200, height: 200 }} variant="rounded" />
                                </div>
                                <div>
                                    <h2>Title: {music.title}</h2>
                                </div>
                                <div>
                                    <h2>Music: {music.song}</h2>
                                </div>
                                <div>
                                    <h2>Song Name: {music.songName}</h2>
                                </div>
                                <div>
                                    <h2>Author: {music.author}</h2>
                                </div>
                            </div>
                            <div style={{width: "350px"}} />
                        </div>
                        <div className="links">
                            <Link to="/musicCont"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button disabled={posting || locked} variant="contained" color="primary" onClick={() => this.updateMusic()} >Save</Button>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default withSnackbar(MusicEdit)
