import React from "react";
import { authenticationService } from "../scripts/authentication.service";

import {Button, TextField, InputAdornment, Alert, AlertTitle} from "@mui/material";
import Person from '@mui/icons-material/Person';
import Lock from '@mui/icons-material/Lock'

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.handleUser = this.handleUser.bind(this)
        this.handlePass = this.handlePass.bind(this)
        this.login = this.login.bind(this)

        if (authenticationService.currentUserValue) {
            this.props.history.push('/');
        }

        this.state = {
            submit: false,
            error: null,
            user: '',
            pass: '',
        }
    }

    handleUser(event) {
        this.setState({user: event.target.value})
    }

    handlePass(event) {
        this.setState({pass: event.target.value})
    }

    login(e) {
        e.preventDefault()
        this.setState({
            submit: true,
        }, () => {
            authenticationService.login(this.state.user, this.state.pass)
                .then(
                    user => {
                        const { from } = this.props.location.state || { from: { pathname: "/" } };
                        this.props.history.push(from);
                    },
                    error => {
                        this.setState({
                            submit: false,
                            error: true,
                        }, () => {
                            console.log("no Login")
                        })
                    }
                );
        })
    }

    render() {
        const { error, submit } = this.state
        return (
            <div className="login">
                {error ? (
                    <>
                        <Alert style={{marginBottom: "20px"}} severity="error" variant="filled">
                            <AlertTitle sx={{textAlign: "left"}}>Error</AlertTitle>
                            Incorrect username or password
                        </Alert>
                    </>
                ) : ""}
                <form className="box">
                    <TextField
                        error={error ? "error" : ""}
                        label="Username"
                        onChange={this.handleUser}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            ),
                        }}/>
                    <TextField
                        className="separator"
                        error={error ? "error" : ""}
                        type="password"
                        label="Password"
                        onChange={this.handlePass}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            ),
                        }}/>
                    <Button type="submit" disabled={submit} className="separator" variant="contained" color="primary" onClick={(e) => this.login(e)}>Login</Button>
                </form>
            </div>
        )
    }
}

export { LoginPage };
