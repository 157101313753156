import React from "react";
import {Button, Card, CardActions, CardContent, CardMedia, Tooltip} from "@mui/material";

class users extends React.Component {
    render() {
        return (
            <div className="items">
                {this.props.items.map((item) => (
                    <>
                    {this.props.page !== item.enabled && (
                        <Card key={item.id} style={{width:"340px", marginLeft:"27px" , marginBottom:"20px", backgroundColor: "#424242", display: "flex"}}>
                            <div style={{position: "relative", textAlign: "left", height: "140px", width: "240px"}}>
                                {item.admin ? (<div className="master">Admin</div>) : ""}
                                <CardContent style={{backgroundColor: "#515151", color: "#fff", height: "calc(100% - 46px)"}}>
                                    <div style={{fontSize: "18px"}}>ID: {item.id}</div>
                                    <Tooltip title={
                                        <React.Fragment>
                                            <div style={{fontSize: "16px"}}>{item.username}</div>
                                        </React.Fragment>
                                    } aria-label="add">
                                        <div style={{fontSize: "18px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>Name: {item.username}</div>
                                    </Tooltip>
                                </CardContent>
                                <CardActions style={{display: "flex", justifyContent: "space-between"}}>
                                    <Button size="small" variant="contained" color="secondary" onClick={() => this.props.openEdit(item.username)}>
                                        Edit
                                    </Button>
                                    {item.enabled ? (
                                        <Button onClick={() => this.props.disableUser(item.username)} size="small" variant="contained" color="secondary">
                                            Disable
                                        </Button>
                                    ) : (
                                        <Button onClick={() => this.props.enableUser(item.username)} size="small" variant="contained" color="secondary">
                                            Enable
                                        </Button>
                                    )}
                                </CardActions>
                            </div>
                            <Tooltip title={
                                <React.Fragment>
                                    <div style={{fontSize: "16px"}}>User Perms</div>
                                    {item.dev ? (<div style={{fontSize: "14px"}}>Developer</div>) : ""}
                                    {item.admin ? (<div style={{fontSize: "14px"}}>Admin</div>) : ""}
                                    {(item.c1 || item.c2 || item.c3) ? (<div style={{fontSize: "14px"}}>Content</div>) : ""}
                                    {item.import ? (<div style={{fontSize: "14px"}}>Importer</div>) : ""}
                                    {(item.p1 || item.p2 || item.p3) ? (<div style={{fontSize: "14px"}}>Persona</div>) : ""}
                                    {item.dupes ? (<div style={{fontSize: "14px"}}>Duplicate Manager</div>) : ""}
                                </React.Fragment>
                            } aria-label="add">
                                <CardMedia image={item.pic} title="pic" style={{width:"100px", height: "100%"}}/>
                            </Tooltip>
                        </Card>
                        )}
                    </>
                ))}
            </div>
        );
    }
}

export default users;