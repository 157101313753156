import React from "react";

export default class LanguageTranslationItemTable extends React.Component {
  constructor(props) {
    super();

    this.scrollableRef = React.createRef();
  }

  componentDidMount() {
    this.props.updateScrollRef(this.scrollableRef.current);
  }

  componentWillUnmount() {
    this.props.updateScrollRef(null);
  }

  handleTableUpdate = (langItemId, changed, updateType) => {
    var updatedLanguageTranslationItems = Array.from(
      this.props.updatedLanguageTranslationItems
    );
    var foundIndex = updatedLanguageTranslationItems.findIndex(
      (translationitem) => langItemId === translationitem.language_item
    );

    switch (updateType) {
      case "VALUE":
        if (foundIndex >= 0) {
          updatedLanguageTranslationItems[foundIndex].value = changed;
        } else {
          updatedLanguageTranslationItems.push({
            id: null,
            value: changed,
            last_updated: null,
            language_item: langItemId,
            language_code: parseInt(this.props.selectedLanguage),
          });
        }
        break;
      // case "KEY":
      //   updatedLanguageTranslationItems[i].key = changed;
      //     break;
      default:
        return;
    }

    this.props.setUpdatedLanguageTranslationItems(
      updatedLanguageTranslationItems
    );
  };

  renderTable = () => {
    if (!this.props.selectedApp) {
      return null;
    }
    
    let tableRender = this.props.updatedLanguageItems.map((langItem, index) => {
      const langTransItem = this.props.updatedLanguageTranslationItems.find(
        (translationitem) => langItem.id === translationitem.language_item
      );
        
        return (
          <div
            className="languageDisplay_grid"
            key={index + this.props.selectedApp + this.props.selectedLanguage}
          >
            <input
              className="languageDisplay_styles left"
              value={langItem.key}
              readOnly
              disabled
            ></input>
            <input
              className="languageDisplay_styles"
              type="text"
              value={langTransItem && langTransItem.value ? langTransItem.value : ""}
              onChange={(e) =>
                this.handleTableUpdate(langItem.id, e.target.value, "VALUE")
              }
            ></input>
            <input
              className="languageDisplay_styles"
              type="text"
              value={!!langItem.last_updated && !langItem.last_updated.startsWith("0000") ? new Date(langItem.last_updated).toLocaleDateString("en-GB") : ""}
              readOnly
              disabled
            ></input>
          </div>
        );
        });
    
        tableRender.push(
          <div className="languageDisplay_grid" key="additionalRow">
            <input
              className="languageDisplay_styles left"
              value=""
              readOnly
              disabled
            ></input>
            <input
              className="languageDisplay_styles"
              type="text"
              value=""
              readOnly
              disabled
            ></input>
            <input
              className="languageDisplay_styles"
              type="text"
              value=""
              readOnly
              disabled
            ></input>
          </div>
        );
       
    return tableRender;
  };

  render() {
    return (
      <div
        className="DropDown_Height"
        ref={this.scrollableRef}
        onScroll={this.props.tableOnScroll}
      >
        {this.renderTable()}
        {/* user can add english phrases and text here, add button to save details onto the languageItem table.  */}
      </div>
    );
  }
}
