import React from "react";
import { authenticationService } from "../scripts/authentication.service";
import Header from "../header/header";
import {withSnackbar} from "notistack";
import {cf} from "../scripts/custom-fetch";
import {Button, LinearProgress} from "@mui/material";
import {Link} from "react-router-dom";

class srcErrors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: authenticationService.currentUserValue,
            errored: [],
            error: null,
            loaded: false,
        };
    }

    findErrors() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/images/findErrored`, options).then(result => {
            this.setState({
                errored: result,
                loaded: true
            })
        }, (error) =>{
            this.setState({
                error
            });
        })
    }

    componentDidMount() {
        this.findErrors()
    }

    render() {
        const { errored, error, loaded } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!loaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header />
                    <div className="dupes">
                        <div className="dupe-title">Issues</div>
                        <div className="dupe-container">
                            {errored.length > 0 ? errored.map(item => (
                                <div className="dupe-card">
                                    {console.log(item)}
                                    <div>Title: {item.title ? item.title : item.name}</div>
                                    <div>ID: {item.id}</div>
                                    <div>Type: {item.type}</div>
                                    <div className="dupe-button">
                                        <Link to={`/srcErrors/manage/${item.id}/${item.type}`}><Button size="small" variant="contained">Manage</Button></Link>
                                    </div>
                                </div>
                            )) : (<div>No Src Disputes</div>)}
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(srcErrors);
