import React from 'react';
import {Link} from "react-router-dom";
import Header from "../../header/header";
import {authenticationService} from "../../scripts/authentication.service";
import {Card, CardActionArea, CardActions, CardContent, CardMedia, Fab, Tooltip, TextField, Button, Pagination, InputAdornment, LinearProgress} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {cf} from "../../scripts/custom-fetch";
import {withSnackbar} from "notistack";

class PostArticles extends React.Component {
    constructor(props) {
        super(props);
        this.searchContent = this.searchContent.bind(this)
        this.state = {
            error: null,
            isLoaded: false,
            articles: [],
            searchArticles: [],
            currentPage: 1,
            itemsPerPage: 10,
            currentUser: authenticationService.currentUserValue,
        };
    }

    callArticles() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/db/getPostArticles`, options)
            .then((result) => {
                this.setState({
                    isLoaded: true,
                    articles: result,
                    searchArticles: result
                });
            }, (error) =>{
                this.setState({
                    isLoaded: true,
                    error
                });
            })
    }

    componentDidMount() {
        this.callArticles();
    }

    setPage(event, value) {
        this.setState({
            currentPage: value
        })
    }

    searchContent(event) {
        let results = this.state.articles.filter(obj => {return obj.title.toUpperCase().includes(event.target.value.toUpperCase())})
        this.setState({
            searchArticles: results
        })
    }

    render() {
        const { error, isLoaded, currentPage, itemsPerPage, searchArticles } = this.state;
        const indexOfLastPost = currentPage * itemsPerPage;
        const indexOfFirstPost = indexOfLastPost - itemsPerPage;
        let currentPosts = searchArticles.slice(indexOfFirstPost, indexOfLastPost)

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header/>

                    <div style={{position:"absolute",top:"0",left:"0",marginLeft:"50px",padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", display:"flex", borderRadius: "0 0 5px"}}>
                        <TextField
                            label="Search"
                            focused
                            style={{marginRight:"10px"}}
                            onChange={(e) => this.searchContent(e, 1)}
                            className="foreInputWhite"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Tooltip title="Add" aria-label="add">
                            <Fab color="primary" href={`/content/postArticle/edit/new`}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </div>

                    <div className="profiles-container">
                        {currentPosts.map((item) => (
                            <Card key={item.id} style={{width:"340px", marginBottom:"20px", backgroundColor: "#424242", marginLeft: "27px"}}>
                                <CardActionArea>
                                    <div className="mediaCard">
                                        <CardMedia
                                            component={item.logo.split(".")[item.logo.split(".").length - 1] === "mp4" ? "video" : "img" }
                                            image={item.logo}
                                            title={item.title}
                                        />
                                    </div>
                                    <CardContent style={{backgroundColor: "#515151", height: "80px"}}>
                                        <div style={{color: "rgb(255,255,255)", fontSize: "20px", fontWeight: "600", textAlign: "left"}}>Title</div>
                                        <div style={{color: "rgb(255,255,255)", fontSize: "18px", textAlign: "left", wordBreak: "break-all"}}>{item.title}</div>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Link to={`/content/postArticle/edit/${item.id}`}>
                                        <Button variant="contained" size="small" color="secondary">
                                            Edit
                                        </Button>
                                    </Link>
                                </CardActions>
                            </Card>
                        ))}
                    </div>

                    <div className="pagination-container">
                        <Pagination color="secondary" count={Math.ceil(searchArticles.length / itemsPerPage)} page={currentPage} onChange={(event, page) => this.setPage(event, page)} />
                    </div>
                    <div style={{position: "absolute", top: 0, right: 0, padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", borderRadius: "0 0 0 5px"}}>
                        <Link to="/content"><Button style={{marginLeft: "10px"}} variant="contained" color="primary" className="spacer">Back</Button></Link>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(PostArticles);
