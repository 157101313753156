import React from "react";
import {Autocomplete, FormControlLabel, Switch, TextField} from "@mui/material";

class reelLeft extends React.Component {
    render() {
        const options = this.props.profiles.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                ...option,
            };
        });
        return (
            <div className="post-panel">
                <TextField error={!!this.props.errors.title} helperText={this.props.errors.title} style={{marginBottom: "10px"}} variant="outlined" label="Title" value={this.props.content.title} name="title" onChange={this.props.handleUpdate(1)} />
                <Autocomplete
                    defaultValue={options.filter(obj => {return obj.id === parseInt(this.props.content.profile_id)})[0]}
                    renderInput={(params) => <TextField style={{marginBottom: "10px"}} variant="outlined" {...params} label="Profile" />}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    onChange={this.props.handleUpdate(3)}
                />
                <TextField style={{marginBottom: "10px"}} variant="outlined" multiline label="Desc" value={this.props.content.desc} name="desc" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.cover_text} helperText={this.props.errors.cover_text} style={{marginBottom: "10px"}} variant="outlined" label="Cover Text" value={this.props.content.cover_text} name="cover_text" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.cover_desc} helperText={this.props.errors.cover_desc} style={{marginBottom: "10px"}} variant="outlined" label="Cover Desc" value={this.props.content.cover_desc} name="cover_desc" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" multiline label="Cover Src" value={this.props.content.cover_src} name="cover_src" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Content" value={this.props.content.src} name="src" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.song} helperText={this.props.errors.song} style={{marginBottom: "10px"}} variant="outlined" label="Song" value={this.props.content.song} name="song" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.comments} helperText={this.props.errors.comments} style={{marginBottom: "10px"}} variant="outlined" label="Comments" value={this.props.content.comments} name="comments" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.likes} helperText={this.props.errors.likes} style={{marginBottom: "10px"}} variant="outlined" label="Likes" value={this.props.content.likes} name="likes" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.shares} helperText={this.props.errors.shares} style={{marginBottom: "10px"}} variant="outlined" label="Shares" value={this.props.content.shares} name="shares" onChange={this.props.handleUpdate(1)} />
                <FormControlLabel
                    className="spacer"
                    control={
                        <Switch
                            checked={this.props.content.viewability_tracking}
                            onChange={this.props.handleUpdate(2)}
                            name="viewability_tracking"
                            color="primary"
                        />
                    }
                    label="Viewability Tracking"
                />
            </div>
        );
    }
}

export default reelLeft;
