import {authenticationService} from "../scripts/authentication.service";
import {cf} from "../scripts/custom-fetch";

/**
 * Get Singular Stim Item By ID
 * @param {int} id - Desired Stim ID
 * @param {string} option - 1 thru 12 to select social
 */
async function getStim(id, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'id':id, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/stim/getStim`, options)
        .then((result) => {
                return result[0]
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get All Stims
 * @param {string} option - 1 thru 12 to select social
 */
async function getAllStims(option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/stim/getAllStims`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get All Stims With Search Values
 * @param {json} option - json with values needed for search
 * search = string of search, tags = array of tag ids, spin = direction of search, type = viewability tracking, page
 */
async function getSearchStims(option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'options': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/stim/getSearchStims`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get All Stims For Persona UUID
 * @param {string} uuid - UUID for persona
 * @param {string} option - 1 thru 12 to select social
 */
async function getPersonaStims(uuid, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'uuid':uuid, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/stim/getPersonaStims`, options)
        .then((result) => {
                return result.sort(function(a, b){return a.order - b.order})
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Create New Stim
 * @param {object} stim - Object for stim
 * @param {string} option - 1 thru 12 to select social
 */
async function newStim(stim, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'stim':stim, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/stim/newStim`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Update Current Stim
 * @param {int} id - ID of stim
 * @param {object} stim - Object for stim
 * @param {string} option - 1 thru 12 to select social
 */
async function setStim(id, stim, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'id':id, 'stim':stim, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/stim/setStim`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Link Stim to Persona
 * @param {int} persona_id - ID of persona
 * @param {int} stim_id - ID of Stim
 * @param {int} order - Order of stim in feed
 * @param {string} option - 1 thru 12 to select social
 */
async function linkStim( persona_id, stim_id, order, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'persona_id':persona_id, 'stim_id':stim_id, 'order':order, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/stim/linkStim`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Unlink Stim from Persona
 * @param {int} persona_id - ID of persona
 * @param {int} stim_id - ID of Stim
 * @param {string} option - 1 thru 12 to select social
 */
async function unlinkStim( persona_id, stim_id, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'persona_id':persona_id, 'stim_id':stim_id, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/stim/unlinkStim`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}


let exports = {
    getStim,
    getAllStims,
    getSearchStims,
    getPersonaStims,
    newStim,
    setStim,
    linkStim,
    unlinkStim,
}

export default exports;