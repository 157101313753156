import React from "react";

class lang extends React.Component {
    render() {
        return (
            <div className="lang">
                <div id="facebook" className="infoBox">
                    <div className="head">Facebook</div>

                    <div className="items first">
                        <div className="title">English</div>
                        <ul>
                            <li>England</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">French</div>
                        <ul>
                            <li>France</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">German</div>
                        <ul>
                            <li>Germany</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Japanese</div>
                        <ul>
                            <li>Japan</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Spanish</div>
                        <ul>
                            <li>Spain</li>
                            <li>Chile</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Russian</div>
                        <ul>
                            <li>Russia</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Swedish</div>
                        <ul>
                            <li>Sweden</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Dutch</div>
                        <ul>
                            <li>Netherlands</li>
                            <li>Belgium</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Indonesian</div>
                        <ul>
                            <li>Indonesian</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Arabic</div>
                        <ul>
                            <li>Saudi Arabia</li>
                        </ul>

                    </div>

                    <div className="items">
                        <div className="title">Turkish</div>
                        <ul>
                            <li>Turkey</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Polish</div>
                        <ul>
                            <li>Poland</li>
                        </ul>
                    </div>
                </div>

                <div id="instagram" className="infoBox">
                    <div className="head">Instagram</div>

                    <div className="items first">
                        <div className="title">English</div>
                        <ul>
                            <li>England</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">French</div>
                        <ul>
                            <li>France</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">German</div>
                        <ul>
                            <li>Germany</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Japanese</div>
                        <ul>
                            <li>Japan</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Spanish</div>
                        <ul>
                            <li>Spain</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Russian</div>
                        <ul>
                            <li>Russia</li>
                        </ul>
                    </div>
                </div>

                <div id="pinterest" className="infoBox">
                    <div className="head">Pinterest</div>

                    <div className="items first">
                        <div className="title">English</div>
                        <ul>
                            <li>England</li>
                        </ul>
                    </div>
                </div>

                <div id="vk" className="infoBox">
                    <div className="head">VK</div>

                    <div className="items first">
                        <div className="title">English</div>
                        <ul>
                            <li>England</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Russian</div>
                        <ul>
                            <li>Russia</li>
                        </ul>
                    </div>
                </div>

                <div id="twitter" className="infoBox">
                    <div className="head">Twitter</div>

                    <div className="items first">
                        <div className="title">English</div>
                        <ul>
                            <li>England</li>
                        </ul>
                    </div>
                </div>

                <div id="snapchat" className="infoBox">
                    <div className="head">Snapchat</div>

                    <div className="items first">
                        <div className="title">English</div>
                        <ul>
                            <li>England</li>
                        </ul>
                    </div>
                </div>

                <div id="snapchat" className="infoBox">
                    <div className="head">TikTok</div>

                    <div className="items first">
                        <div className="title">English</div>
                        <ul>
                            <li>England</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Indonesian</div>
                        <ul>
                            <li>Indonesia</li>
                        </ul>
                    </div>

                    <div className="items">
                        <div className="title">Portuguese</div>
                        <ul>
                            <li>Brazil</li>
                        </ul>
                    </div>
                </div>

                <div id="gmail" className="infoBox">
                    <div className="head">Gmail</div>

                    <div className="items first">
                        <div className="title">English</div>
                        <ul>
                            <li>England</li>
                        </ul>
                    </div>
                </div>

                <div id="music" className="infoBox">
                    <div className="head">Music</div>

                    <div className="items first">
                        <div className="title">English</div>
                        <ul>
                            <li>England</li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default lang;