import { authenticationService } from './authentication.service';

/*
function runAgain (url, options, n, error) {
    console.log("Number of Tries:", n)
    if (n === 1 || n < 1) throw error;
    authenticationService.reToke(authenticationService.currentUserValue)
        .then(
            user => {
                options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`}
                get(url, options, n - 1).then((response) => {
                    console.log("Yes", response)
                    return response
                })
            },
            error => {
                authenticationService.logout();
                window.location.reload(true);
                console.log(error)
            }
        )
}

const auth = async () => {
    return await authenticationService.reToke(authenticationService.currentUserValue)
}
 */

/*
const get = async (url, options, n) => {
    console.log("Number of Tries:", n)
    try {
        let results = await fetch(url, options)
        if (results.ok) {
            console.log("returning", results)
            return results
        } else {
            if (n === 1 || n < 1) throw "Error: Ran out of tries";
            auth().then(
                user => {
                    options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`}
                    return get(url, options, n - 1);
                },
                error => {
                    authenticationService.logout();
                    window.location.reload(true);
                    console.log(error)
                }
            )
        }
    } catch(err) {
        if (n === 1 || n < 1) throw "Error: Ran out of tries";
        auth().then(
            user => {
                options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`}
                return get(url, options, n - 1);
            },
            error => {
                authenticationService.logout();
                window.location.reload(true);
                console.log(error)
            }
        )
    }
};
 */

function process(resolve, result) {
    result.text().then(text => {
        const data = text && JSON.parse(text);
        resolve(data)
    })
}

function logout() {
    authenticationService.logout(authenticationService.currentUserValue);
}

function get(url, options) {
    return new Promise(function(resolve, reject) {
        fetch(url, options)
            .then((result) => {
                if (result.ok) {
                    process(resolve, result)
                } else {
                    authenticationService.reToke(authenticationService.currentUserValue)
                        .then((newToke) => {
                            if (newToke) {
                                options.headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`}
                                fetch(url, options)
                                    .then((result => {
                                        if (result.ok) {
                                            process(resolve, result)
                                        } else {
                                            reject()
                                        }
                                    }))
                            } else {
                                logout()
                                reject()
                            }
                        })
                }
            })
            .catch( err => {
                reject()
            })
    });
}

/*
const get2 = (url, options, n) => fetch(url, options)
    .then((response) => {
        if (response.ok){
            return response
        } else runAgain(url, options, n, "Error: Token Failed") })
    .catch(function(error) {
        runAgain(url, options, n, error)
});
 */

export const cf = {get}