import React from "react";
import { Button } from "@mui/material";

export const ModalType = {
  DeleteRow: 0,
  SaveChanges: 1,
};

function SaveChangeModal({ onClose, onConfirmSave }) {
  const getSaveChangeModalTitle = () => {
    return "WOULD YOU LIKE TO SAVE CHANGES?"
  };
  const getSaveChangeModalMsg = () => {
    return "Changes will be saved to the database";
  };

  return (
    <div>
      <div className="warningModal">
        <h3 className="warning padding">
          {getSaveChangeModalTitle()}
          <br />
          <br />
          <span className="warningModal_fontColor">
            {getSaveChangeModalMsg()}
          </span>
        </h3>
        <div className="warningModal_btnContainer">
          <Button
            variant="contained"
            color="primary"
            className="warningModal-btn continue"
            onClick={() => {
              onConfirmSave();
              onClose();
            }}
          >
            CONTINUE
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="warningModal-btn cancel"
            onClick={() => onClose()}
          >
            CANCEL
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SaveChangeModal;
