import React from "react";
import "./translationEngine.css";
import SimpleEntryModal, { ModalType } from "./SimpleEntryModal";
import { Button } from "@mui/material";
import { authenticationService } from "../scripts/authentication.service";
import LanguageItemTable from "./LanguageItemTable";
import LanguageTranslationItemTable from "./LanguageTranslationItemTable";
import { deepEqual } from "../utils";
import SaveChangeModal from "./SaveChangeModal";
import { withSnackbar } from "notistack";
import Header from "../header/header";
import { ExportButton, ImportButton } from "./dataTransfer";
import {cf} from "../scripts/custom-fetch";

const updateInternalLTIArray = (
  languageTranslationItems,
  updatedLanguageItems,
  selectedLanguage
) => {
  return structuredClone(
    languageTranslationItems.filter(
      (item) =>
        item.language_code === selectedLanguage &&
        updatedLanguageItems.some((updI) => updI.id === item.language_item)
    )
  ).sort((a, b) => a.language_item - b.language_item);
};

class TranslationEngine extends React.Component {
  constructor(props) {
    // the starting point, like a container. The props are the features and instructions the contructor needs to work properly
    super(props); // constructor needs the super() to ensure the features and instructions within the props are set up correctly. The super is telling the parents class's contructor to set up its part before I add my specific setup

    this.languageItemTableRef = React.createRef();
    this.upload = React.createRef();
    this.languageTranslationItemTableRef = React.createRef();

    // this.state is filled with the data as a key and value pair, here its used as a placeholder for the upcoming data. This is know n as an "object literal" or "Object Initializer"
    this.state = {
      ready: false,
      saving: false,
      apps: [],
      languageCodes: [],
      languageItems: [],
      languageTranslationItems: [],

      updatedLanguageItems: [],
      updatedLanguageTranslationItems: [],

      showNewAppModal: false, // modal starts by not appearing on the screen
      showNewLanguageCodeModal: false,
      selectedApp: 1,
      selectedLanguage: 2,
      selectedLanguageLabel: "French",
      saveCb: () => {},
      showSaveModal: false,
      laguageItemScrollableRef: null,
      laguageTranslationItemScrollableRef: null,
    };

    window.TranslationEngine = this;

    // ******  MODAL  ******
    // to 'bind' is a way to ensure when these two functions are called the value of 'this' inside the functions refers to the current object
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = (modaltype) => {
    switch (modaltype) {
      case ModalType.NewApp:
        this.setState({ showNewAppModal: true });
        break;
      case ModalType.NewLanguageCode:
        this.setState({ showNewLanguageCodeModal: true });
        break;
      default:
        console.warn("modal type not set!");
        break;
    }
  };

  hideModal = () => {
    this.setState({ showNewAppModal: false, showNewLanguageCodeModal: false });
  };

  // Using a Promise object to wrap the fetch() calls to create a chain of asynchronous operations.
  componentDidMount() {
    // resolve fnction is called when data successfully fetched
    // reject function called if there is an error
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
    };
    const fetchAllApplications = new Promise((resolve, reject) => {
      cf.get(`${process.env.REACT_APP_API_GET}/language/applications`, options)
        .then((res) => {
          this.setState({
            ...this.state,
            apps: res,
          });
          resolve();
        })
        .catch(reject);
    });

    const fetchCodes = new Promise((resolve, reject) => {
      // fetch languages which will be display in a dropdown list
      cf.get(`${process.env.REACT_APP_API_GET}/language/codes`, options)
        .then((res) => {
          this.setState({
            ...this.state,
            languageCodes: res,
          });
          resolve();
        })
        .catch(reject);
    });

    Promise.all([
      fetchAllApplications,
      this.fetchLanguageItems(),
      fetchCodes,
      this.fetchLanguageTranslationItems(),
    ])
      .then(() => {
        const updatedLanguageItems = this.state.languageItems.filter(
          (item) => item.application === this.state.selectedApp
        );
        const updatedLanguageTranslationItems = updateInternalLTIArray(
          this.state.languageTranslationItems,
          updatedLanguageItems,
          this.state.selectedLanguage
        );
        this.setState({
          ready: true,
          updatedLanguageItems,
          updatedLanguageTranslationItems,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  // fetch english text or phrases to display on the left hand side using Map()
  fetchLanguageItems = () => {
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
    };
    return new Promise((resolve, reject) => {
      cf.get(`${process.env.REACT_APP_API_GET}/language/items`, options)
        .then((res) => {
          console.log(res);
          this.setState(
            {
              languageItems: res,
            },
            resolve
          );
        })
        .catch(reject);
    });
  };

  // fetch english text or phrases to display on the left hand side using Map()
  fetchLanguageTranslationItems = () => {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
      };
      cf.get(`${process.env.REACT_APP_API_GET}/language/translationitems`, options)
        .then((res) => {
          this.setState(
            {
              languageTranslationItems: res,
            },
            resolve
          );
        })
        .catch(reject);
    });
  };

  refreshTables = async () => {
    await this.fetchLanguageItems();
    await this.fetchLanguageTranslationItems();
    this.setState({
      updatedLanguageItems: this.state.languageItems.filter(
        (item) => item.application === this.state.selectedApp
      ),
      updatedLanguageTranslationItems: updateInternalLTIArray(
        this.state.languageTranslationItems,
        this.state.updatedLanguageItems,
        this.state.selectedLanguage
      ),
    });
  };

  saveLanguageItemChanges = async () => {
    this.setState({
      saving: true,
    });

    let deletedItems = [];

    const filteredOriginal = this.state.languageItems.filter(
      (item) => item.application === this.state.selectedApp
    );
    const allUpdated = this.state.updatedLanguageItems;
    console.log(allUpdated)
    const addedItems = allUpdated.filter(
      ({ id: id1 }) => !filteredOriginal.some(({ id: id2 }) => id2 === id1)
    );
    const changedItemsOrignal = filteredOriginal.filter((itemOrig) => {
      if (allUpdated.some((updated) => updated.id === itemOrig.id)) {
        return !deepEqual(
          itemOrig,
          allUpdated[
            allUpdated.findIndex((updated) => updated.id === itemOrig.id)
          ]
        );
      } else {
        return deletedItems.push(itemOrig);
      }
    });
    const changedItems = allUpdated.filter(({ id: id1 }) =>
      changedItemsOrignal.some(({ id: id2 }) => id2 === id1)
    );
    const fullUpdateArr = addedItems.concat(changedItems);

    if (fullUpdateArr.length === 0 && deletedItems.length === 0) {
      this.setState({
        saving: false,
      });
      return;
    }

    let executorArr = [];

    if (fullUpdateArr.length > 0) {
      executorArr.push(
          cf.get(`${process.env.REACT_APP_API_GET}/language/items`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
          },
          body: JSON.stringify(fullUpdateArr),
        })
      );
    }

    if (deletedItems.length > 0) {
      deletedItems.forEach((del) => {
        executorArr.push(
            cf.get(`${process.env.REACT_APP_API_GET}/language/items/${del.id}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
          })
        );
      });
    }

    const res = await Promise.all(executorArr);

    // some error occured -- Error fixed. Auth token timeout need to use cf.get
    // TODO: Move logic above to api and use update rather than put / delete. Send once receive once!
    if (!res) {
      console.warn("Failed to save!");
    } else {
      await this.refreshTables();
    }

    this.setState({
      saving: false,
    });
  };

  saveLanguageTranslationItemChanges = async () => {
    this.setState({
      saving: true,
    });

    const filteredOriginal = this.state.languageTranslationItems
      .filter(
        (item) =>
          item.language_code === this.state.selectedLanguage &&
          this.state.updatedLanguageItems.some(
            (updI) => updI.id === item.language_item
          )
      )
      .sort((a, b) => a.language_item - b.language_item);
    const allUpdated = this.state.updatedLanguageTranslationItems;
    const addedItems = allUpdated.filter(
      ({ id: id1 }) => !filteredOriginal.some(({ id: id2 }) => id2 === id1)
    );
    const changedItemsOrignal = filteredOriginal.filter(
      (itemOrig, i) => !deepEqual(itemOrig, allUpdated[i])
    );
    const changedItems = allUpdated.filter(({ id: id1 }) =>
      changedItemsOrignal.some(({ id: id2 }) => id2 === id1)
    );
    const removeEmpty = changedItems.filter((item) => item.value !== "");
    const fullUpdateArr = addedItems.concat(removeEmpty);

    if (fullUpdateArr.length === 0) {
      this.setState({
        saving: false,
      });
      return;
    }

    const response = await cf.get(
      `${process.env.REACT_APP_API_GET}/language/translationitems`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
        },
        body: JSON.stringify(fullUpdateArr),
      }
    );

    console.log(response)
    if (response) {
      await this.refreshTables();
    } else {
      // should be nicer! Done JC!
      console.warn("Failed to save!");
    }
    this.setState({
      saving: false,
    });
  };

  handleLanguageItemDelete = (index) => {
    const updatedLanguageItems = [...this.state.updatedLanguageItems];
    updatedLanguageItems.splice(index, 1);
    this.setState({ updatedLanguageItems });
  };

  addLanguageItem = (newItem) => {
    this.setState((prevState) => ({
      updatedLanguageItems: [...prevState.updatedLanguageItems, newItem],
    }));
  };

  updateLanguageItems = (updatedItems) => {
    this.setState({ updatedLanguageItems: updatedItems });
  };

  updateApps = (updatedApps) => {
    this.setState({ apps: updatedApps });
  };

  showSaveModal = (cb) => {
    this.setState({ saveCb: cb, showSaveModal: true });
  };

  handleLanguageItemScroll = (scroll) => {
    const ref = this.state.laguageTranslationItemScrollableRef;
    if (!!ref) {
      ref.scrollTop = scroll.target.scrollTop;
    }
  };

  handleLanguageTranslationItemScroll = (scroll) => {
    const ref = this.state.laguageItemScrollableRef;
    if (!!ref) {
      ref.scrollTop = scroll.target.scrollTop;
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="social">
          <div className="box">
            <div
              className="TranslationEngine_container"
              data-testid="TE_container"
            >
              {this.state.showSaveModal ? (
                <SaveChangeModal
                  onClose={() =>
                    this.setState({ showSaveModal: false, saveCb: () => {} })
                  }
                  onConfirmSave={this.state.saveCb}
                />
              ) : null}
              {/* App name to be displayed here from the language table */}
              {/* DropDownListDisplay */}
              <div className="DropDown_selectRow">
                <div className="DropDown_Container">
                  <h3>Application</h3>
                  <div className="DropDown_Button_Container">
                    <div>
                      {this.state.ready ? (
                        <select
                          className="DropDown_List"
                          value={this.state.selectedApp} // set the selected value
                          onChange={(e) => {
                            let newSelectedApp = parseInt(e.target.value);
                            let newULI = !!newSelectedApp
                              ? structuredClone(
                                  this.state.languageItems.filter(
                                    (item) =>
                                      item.application === newSelectedApp
                                  )
                                ).sort((a, b) => a.id - b.id)
                              : [];
                            let newULTI = !!newSelectedApp
                              ? updateInternalLTIArray(
                                  this.state.languageTranslationItems,
                                  newULI,
                                  this.state.selectedLanguage
                                )
                              : [];
                            console.log("Selected app:", newSelectedApp);
                            this.setState({
                              selectedApp: newSelectedApp,
                              updatedLanguageItems: newULI,
                              updatedLanguageTranslationItems: newULTI,
                            });
                          }}
                        >
                          {/* <option key={-1} value={""}>
                        Select Application
                      </option> */}
                          {this.state.apps.map((showApp, index) => {
                            return (
                              <option key={index} value={showApp.id}>
                                {showApp.application_name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <p>loading...</p>
                      )}
                    </div>

                    <Button
                      variant="contained"
                      color="primary"
                      className="button"
                      onClick={() =>
                        this.showModal(
                          ModalType.NewApp && ModalType.NewLanguageCode
                        )
                      }
                    >
                      CREATE NEW APPLICATION
                    </Button>
                  </div>
                </div>
                {/* newAppModal */}
                {this.state.showNewAppModal && (
                  <SimpleEntryModal
                    hideModal={this.hideModal}
                    updateParentStateFn={this.updateApps} // pass the updateApps function as a prop
                    apps={this.state.apps}
                    modaltype={ModalType.NewApp}
                  />
                )}

                {/*  drop down box needed to display list of available languages from language translation table */}
                {/* dropdown */}
                <div className="DropDown_Container right">
                  <h3>Language</h3>
                  <div className="DropDown_Button_Container">
                    <div>
                      {this.state.ready ? (
                        <select
                          className="DropDown_List"
                          onChange={(e) => {
                            let newSelectedLanguage = parseInt(e.target.value);
                            let newSelectedLanguageLabel =
                              this.state.languageCodes.find(
                                (lang) => lang.id === newSelectedLanguage
                              )?.label || "";
                            let newULTI = !!newSelectedLanguage
                              ? updateInternalLTIArray(
                                  this.state.languageTranslationItems,
                                  this.state.updatedLanguageItems,
                                  newSelectedLanguage
                                )
                              : [];
                            console.log(
                              "selected language id:",
                              newSelectedLanguage
                            );
                            console.log(
                              "Selected Language Label:",
                              newSelectedLanguageLabel
                            );
                            this.setState({
                              selectedLanguage: newSelectedLanguage,
                              selectedLanguageLabel: newSelectedLanguageLabel,
                              updatedLanguageTranslationItems: newULTI,
                            });
                          }}
                        >
                          {this.state.languageCodes.map((lang, index) => {
                            return (
                              <option key={index} value={lang.id}>
                                {lang.label || lang.code}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <p>loading...</p>
                      )}
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      className="button "
                      onClick={() => this.showModal(ModalType.NewLanguageCode)}
                    >
                      CREATE NEW LANGUAGE
                    </Button>
                  </div>

                  {/* newLangueCodeModal */}
                  {this.state.showNewLanguageCodeModal && (
                    <SimpleEntryModal
                      hideModal={this.hideModal}
                      updateParentStateFn={() => {}}
                      apps={[]}
                      existingCodes={this.state.languageCodes}
                      modaltype={ModalType.NewLanguageCode}
                    />
                  )}
                </div>
              </div>

              <div className="languageDisplay">
                {/*  leftside - English language side. Displays English text. Option to update with new English words/phrases */}
                {/* LanguageTextDisplay */}
                <div className="languageDisplay_Display">
                  <h4>Default Language (English)</h4>
                  <div className="languageDisplay_grid header">
                    <div className="languageDisplay_Title">Key</div>
                    <div className="languageDisplay_Title">Value</div>
                    <div className="languageDisplay_Title">Last Updated</div>
                  </div>
                  {/* identity number is the foreign key from the language table */}
                  {/* use map() *maybe* to display the content */}
                  {!!this.state.selectedApp ? (
                    <LanguageItemTable
                      ref={this.languageItemTableRef}
                      updatedLanguageItems={this.state.updatedLanguageItems}
                      handleLanguageItemDelete={this.handleLanguageItemDelete}
                      setUpdatedLanguageItems={(ULI) =>
                        this.setState({ updatedLanguageItems: ULI })
                      }
                      selectedApp={this.state.selectedApp}
                      selectedLanguage={this.state.selectedLanguage}
                      onAddLanguageItem={this.addLanguageItem}
                      updateLanguageItems={this.updateLanguageItems}
                      tableOnScroll={this.handleLanguageItemScroll}
                      updateScrollRef={(ref) => {
                        this.setState({
                          laguageItemScrollableRef: ref,
                        });
                      }}
                    ></LanguageItemTable>
                  ) : (
                    <></>
                  )}
                  {/* save button to save updates made to the languages to the database */}
                  <div style={{ display: "flex" }}>
                    {this.state.languageItems && this.state.selectedApp ? (
                      <ExportButton
                        items={this.state.languageItems.filter(
                          (item) => item.application == this.state.selectedApp
                        )}
                        applicationName={
                          this.state.apps.find(
                            (app) => app.id == this.state.selectedApp
                          )?.application_name
                        }
                      />
                    ) : null}
                    <Button
                      variant="contained"
                      color="primary"
                      className="button languageDisplay_saveBtns"
                      onClick={() =>
                        this.showSaveModal(this.saveLanguageItemChanges)
                      }
                      disabled={this.state.saving || !this.state.selectedApp}
                    >
                      Save Default Language
                    </Button>
                  </div>
                </div>

                {/* rightside - displays list of the english text on the left hand side translated in the language chosen from the above dropdown box  */}
                {/* LanguageTextDisplay */}
                <div className="languageDisplay_Display">
                  <h4>
                    Selected Language (
                    {this.state.selectedLanguageLabel || "None"}){" "}
                  </h4>
                  <div className="languageDisplay_grid header">
                    <div className="languageDisplay_Title">Key</div>
                    <div className="languageDisplay_Title">Value</div>
                    <div className="languageDisplay_Title">Last Updated</div>
                  </div>
                  {/* identity number is the foreign key from the language table */}
                  {/* use map() *maybe* to display the content */}
                  {!!this.state.selectedLanguage ? (
                    <LanguageTranslationItemTable
                      updatedLanguageTranslationItems={
                        this.state.updatedLanguageTranslationItems
                      }
                      setUpdatedLanguageTranslationItems={(ULTI) =>
                        this.setState({ updatedLanguageTranslationItems: ULTI })
                      }
                      updatedLanguageItems={this.state.updatedLanguageItems}
                      selectedApp={this.state.selectedApp}
                      selectedLanguage={this.state.selectedLanguage}
                      tableOnScroll={this.handleLanguageTranslationItemScroll}
                      updateScrollRef={(ref) => {
                        this.setState({
                          laguageTranslationItemScrollableRef: ref,
                        });
                      }}
                      ref={this.languageTranslationItemTableRef}
                    ></LanguageTranslationItemTable>
                  ) : (
                    <></>
                  )}
                  <div style={{ display: "flex" }}>
                    {this.state.languageItems &&
                    this.state.selectedApp &&
                    this.state.selectedLanguage ? (
                      <div>
                        <input id="uploadjson" type="file" accept=".json" ref={this.upload} />
                        <ImportButton
                          items={this.state.languageItems.filter(
                            (item) => item.application == this.state.selectedApp
                          )}
                          languageCode={this.state.selectedLanguage}
                          uploadEl={this.upload.current}
                          ULTIEl={this.languageTranslationItemTableRef.current}
                          ULTI={this.state.updatedLanguageTranslationItems}
                        />
                      </div>
                    ) : null}
                    {/* save button to save updates made to the languages to the database */}
                    <Button
                      variant="contained"
                      color="primary"
                      className="button languageDisplay_saveBtns"
                      disabled={
                        this.state.saving ||
                        !(
                          !!this.state.selectedApp &&
                          !!this.state.selectedLanguage
                        )
                      }
                      onClick={() =>
                        this.showSaveModal(
                          this.saveLanguageTranslationItemChanges
                        )
                      }
                    >
                      Save selected language
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withSnackbar(TranslationEngine);
