import React from "react";
import {Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";

const fbEmotes = [
    { value: "like", label: "Like's" },
    { value: "love", label: "Love's" },
    { value: "haha", label: "Haha's" },
    { value: "wow", label: "Wow's" },
    { value: "sad", label: "Sad's" },
    { value: "angry", label: "Angry's" },
];

const lookalikes = [
    { value: "fb", label: "Facebook" },
    { value: "ig", label: "Instagram" },
    { value: "pn", label: "Pinterest" },
    // { value: "vk", label: "VK" },
    { value: "tw", label: "Twitter" },
];

class stimRight extends React.Component {
    getEmotes(data) {
        let emotes = []
        if (data.like) {
            emotes.push(fbEmotes[0])
        }
        if (data.love) {
            emotes.push(fbEmotes[1])
        }
        if (data.haha) {
            emotes.push(fbEmotes[2])
        }
        if (data.wow) {
            emotes.push(fbEmotes[3])
        }
        if (data.sad) {
            emotes.push(fbEmotes[4])
        }
        if (data.angry) {
            emotes.push(fbEmotes[5])
        }
        return emotes
    }

    render() {
        const options = this.props.articles.map((option) => {
            const firstLetter = option.title[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                ...option,
            };
        });
        const tagOptions = this.props.tags.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            if (option.important) {
                return {
                    firstLetter: " IMPORTANT",
                    ...option
                }
            } else {
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                    ...option,
                };
            }
        });
        const defaultTags = this.props.linkTag.map((option) => {
            return tagOptions.filter((obj) => {
                return obj.id === parseInt(option.tag);
            })[0];
        });
        return (
            <>
                <div className="unique-panel">
                    <Autocomplete
                        defaultValue={
                            options.filter((obj) => {
                                return obj.id === parseInt(this.props.content.article_id);
                            })[0]
                        }
                        renderInput={(params) => (
                            <TextField
                                style={{ marginBottom: "10px" }}
                                variant="outlined"
                                {...params}
                                label="Post Article"
                            />
                        )}
                        options={options.sort(
                            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                        )}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.title}
                        id="article_id"
                        onChange={this.props.handleUpdate(6)}
                    />
                    <Autocomplete
                        multiple
                        defaultValue={this.getEmotes(this.props.content)}
                        renderInput={(params) => <TextField style={{marginBottom: "10px"}} variant="outlined" {...params} label="Emotes" />}
                        options={fbEmotes}
                        getOptionLabel={(option) => option.label}
                        onChange={this.props.handleUpdate(4)}
                    />
                    <Autocomplete
                        multiple
                        defaultValue={defaultTags}
                        renderInput={(params) => (
                            <TextField
                                style={{ marginBottom: "10px" }}
                                variant="outlined"
                                {...params}
                                label="Tags"
                            />
                        )}
                        options={tagOptions.sort(
                            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                        )}
                        groupBy={(option) => option.firstLetter}
                        getOptionLabel={(option) => option.name}
                        onChange={this.props.handleUpdate(5, "linkTag")}
                    />
                    <Autocomplete
                        defaultValue={lookalikes[0]}
                        renderInput={(params) => <TextField style={{marginBottom: "10px"}} variant="outlined" {...params} label="Feed Type" />}
                        options={lookalikes}
                        getOptionLabel={(option) => option.label}
                        onChange={this.props.handleUpdate(5, "lookalike")}
                    />
                    <TextField
                        style={{ marginBottom: "10px", width: "100%" }}
                        variant="outlined"
                        label="Promo Desc"
                        value={this.props.content.promo_desc}
                        name="promo_desc"
                        onChange={this.props.handleUpdate(1)}
                    />
                    <TextField
                        style={{ marginBottom: "10px", width: "100%" }}
                        variant="outlined"
                        label="Promo Desc 2"
                        value={this.props.content.promo_desc_2}
                        name="promo_desc_2"
                        onChange={this.props.handleUpdate(1)}
                    />
                    <TextField
                        style={{ marginBottom: "10px", width: "100%" }}
                        variant="outlined"
                        label="Promo Link"
                        value={this.props.content.promo_link}
                        name="promo_link"
                        onChange={this.props.handleUpdate(1)}
                    />
                    <TextField
                        error={!!this.props.errors.promo_button}
                        helperText={this.props.errors.promo_button}
                        style={{ marginBottom: "10px", width: "100%" }}
                        variant="outlined"
                        label="Promo Button"
                        value={this.props.content.promo_button}
                        name="promo_button"
                        onChange={this.props.handleUpdate(1)}
                    />
                    <FormControl sx={{width:"100%"}} className="spacer" variant="outlined">
                        <InputLabel>Lang</InputLabel>
                        <Select
                            value={this.props.content.lang_id}
                            onChange={this.props.handleUpdate(1)}
                            label="Lang"
                            name="lang_id"
                        >
                            {this.props.lang.map((item) => (
                                <MenuItem value={item.id} key={item.id}>{item.language}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {this.props.isModal ? (
                        <>
                            <Button
                                disabled={this.props.posting}
                                variant="contained"
                                color="primary"
                                className="spacer"
                                onClick={() => this.props.close()}
                            >
                                Close
                            </Button>
                            <Button
                                disabled={this.props.posting}
                                variant="contained"
                                color="primary"
                                style={{marginLeft: "10px"}}
                                onClick={() => this.props.updateContent()}
                            >
                                Save
                            </Button>
                            <div>Warning Content in modal maybe closer than they appear</div>
                        </>
                    ) : ""}
                </div>
            </>
        );
    }
}

export default stimRight;
