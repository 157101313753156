import React from "react";
import {
    Autocomplete,
    Button,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from "@mui/material";

const fbEmotes = [
    { value: "like", label: "Like's" },
    { value: "love", label: "Love's" },
    { value: "haha", label: "Haha's" },
    { value: "wow", label: "Wow's" },
    { value: "sad", label: "Sad's" },
    { value: "angry", label: "Angry's" },
];

const lookalikes = [
    { value: "fb", label: "Facebook" },
    { value: "ig", label: "Instagram" },
    { value: "pn", label: "Pinterest" },
    // { value: "vk", label: "VK" },
];


class watchRight extends React.Component {
    getEmotes(data) {
        let emotes = []
        if (data.like) {
            emotes.push(fbEmotes[0])
        }
        if (data.love) {
            emotes.push(fbEmotes[1])
        }
        if (data.haha) {
            emotes.push(fbEmotes[2])
        }
        if (data.wow) {
            emotes.push(fbEmotes[3])
        }
        if (data.sad) {
            emotes.push(fbEmotes[4])
        }
        if (data.angry) {
            emotes.push(fbEmotes[5])
        }
        return emotes
    }

    render() {
        const tagOptions = this.props.tags.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            if (option.important) {
                return {
                    firstLetter: " IMPORTANT",
                    ...option
                }
            } else {
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                    ...option,
                };
            }
        });
        const defaultTags = this.props.linkTag.map((option) => {
            return tagOptions.filter((obj) => {
                return obj.id === parseInt(option.tag);
            })[0];
        });
        return (
            <div className="unique-panel">
                <Autocomplete
                    multiple
                    defaultValue={this.getEmotes(this.props.content)}
                    renderInput={(params) => <TextField style={{marginBottom: "10px"}} variant="outlined" {...params} label="Emotes" />}
                    options={fbEmotes}
                    getOptionLabel={(option) => option.label}
                    onChange={this.props.handleUpdate(4)}
                />
                <Autocomplete
                    defaultValue={lookalikes[0]}
                    renderInput={(params) => <TextField style={{marginBottom: "10px"}} variant="outlined" {...params} label="Feed Type" />}
                    options={lookalikes}
                    getOptionLabel={(option) => option.label}
                    onChange={this.props.handleUpdate(5,"lookalike")}
                />
                <Autocomplete
                    multiple
                    defaultValue={defaultTags}
                    renderInput={(params) => <TextField style={{marginBottom: "10px"}} variant="outlined" {...params} label="Tags" />}
                    options={tagOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    onChange={this.props.handleUpdate(5,"linkTag")}
                />
                <FormControlLabel
                    className="spacer"
                    control={
                        <Switch
                            checked={this.props.content.promoted}
                            onChange={this.props.handleUpdate(2)}
                            name="promoted"
                            color="primary"
                        />
                    }
                    label="Promoted"
                />
                <FormControl sx={{width:"100%"}} className="spacer" variant="outlined">
                    <InputLabel>Lang</InputLabel>
                    <Select
                        value={this.props.content.lang_id}
                        onChange={this.props.handleUpdate(1)}
                        label="Lang"
                        name="lang_id"
                    >
                        {this.props.lang.map((item) => (
                            <MenuItem value={item.id} key={item.id}>{item.language}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {this.props.isModal ? (
                    <>
                        <Button
                            disabled={this.props.posting}
                            variant="contained"
                            color="primary"
                            className="spacer"
                            onClick={() => this.props.close()}
                        >
                            Close
                        </Button>
                        <Button
                            disabled={this.props.posting}
                            variant="contained"
                            color="primary"
                            style={{marginLeft: "10px"}}
                            onClick={() => this.props.updateContent()}
                        >
                            Save
                        </Button>
                        <div>Warning Content in modal maybe closer than they appear</div>
                    </>
                ) : ""}
            </div>
        );
    }
}

export default watchRight;