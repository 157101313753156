import React from "react";
import { authenticationService } from "../scripts/authentication.service";
import Header from "../header/header";
import {withSnackbar} from "notistack";
import {cf} from "../scripts/custom-fetch";
import {Button, LinearProgress} from "@mui/material";
import Manage from "./components/manage";

class Dupes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: authenticationService.currentUserValue,
            dupes: [],
            error: null,
            loaded: false,
            title: "",
            second: "",
            type: "",
            open: false,
            currentDupes: [],
            links: [],
        };
    }

    findDupes() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/dupes/findDupes`, options).then(result => {
            this.setState({
                dupes: result,
                loaded: true
            })
        }, (error) =>{
            this.setState({
                error
            });
        })
    }

    componentDidMount() {
        this.findDupes()
    }

    getDupes() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'title':this.state.title, 'type': this.state.type, 'second': this.state.second})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/dupes/getDupes`, options).then(result => {
            this.setState({
                currentDupes: result,
            }, () => {this.getConnections()})
        }, (error) =>{
            this.setState({
                error
            });
        })
    }

    cleanUp = (index) => {
        const options2 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.links[index][0].id, 'content_id':this.state.currentDupes[0].id, 'type': this.state.type})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/dupes/cleanUpLinks`, options2).then(result => {
            console.log(result)
            this.getDupes()
            this.closeMange()
        })
    }

    remove = (id) => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':id, 'type': this.state.type})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/dupes/removeContent`, options).then(result => {
            console.log(result)
            this.getDupes()
        })
    }

    getConnections(index) {
        let dupes = this.state.currentDupes
        let links = this.state.links
        let currentIndex = index || 0
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':dupes[currentIndex].id, 'type': this.state.type})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/dupes/getLinks`, options).then(result => {
            links.push(result)
            this.setState({
                links: links,
            }, () => {
                if (currentIndex+1 === dupes.length) {
                    this.setState({
                        open: true,
                    })
                } else {
                    this.getConnections(currentIndex+1)
                }
            })
        })
    }

    openManage = (title, type, second) => {
        this.setState({
            title: title,
            second: second,
            type: type,
        }, () => {
            this.getDupes()
        })
    }

    closeMange = () => {
        this.findDupes()
        this.setState({
            links: [],
            open: false,
        })
    }

    render() {
        const { dupes, error, loaded, open, currentDupes, links } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!loaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header />
                    <div className={(open ? 'user-box' : 'user-box hide')}>
                        <div className="link-container">
                            <Manage dupes={currentDupes} links={links} open={open} cleanUp={this.cleanUp} remove={this.remove}  />
                            <Button variant="contained" color="primary" onClick={this.closeMange}>Close</Button>
                        </div>
                    </div>
                    <div className="dupes">
                        <div className="dupe-title">Content</div>
                        <div className="dupe-container">
                            {dupes.post.length > 0 ? dupes.post.map((item, key) => (
                                    <div className="dupe-card" key={"post-"+key}>
                                        <div>Title: {item.title}</div>
                                        <div>Count: {item.c}</div>
                                        <div className="dupe-button">
                                            <Button size="small" variant="contained" onClick={() => this.openManage(item.title,1,item.desc)}>Manage</Button>
                                            <Button disabled={true} size="small" variant="contained">Auto Fix</Button>
                                        </div>
                                    </div>
                                )) : (<div>No Content Dupes</div>)}
                        </div>
                        <div className="dupe-title">Stims</div>
                        <div className="dupe-container">
                            {dupes.ad.length > 0 ? dupes.ad.map((item, key)  => (
                                    <div className="dupe-card" key={"ad-"+key}>
                                        <div>Title: {item.title}</div>
                                        <div>Count: {item.c}</div>
                                        <div className="dupe-button">
                                            <Button size="small" variant="contained" onClick={() => this.openManage(item.title,2,item.desc)}>Manage</Button>
                                            <Button disabled={true} size="small" variant="contained">Auto Fix</Button>
                                        </div>
                                    </div>
                                )) : (<div>No Stim Dupes</div>)}
                        </div>
                        <div className="dupe-title">Story</div>
                        <div className="dupe-container">
                            {dupes.story.length > 0 ? dupes.story.map((item, key)  => (
                                    <div className="dupe-card" key={"story-"+key}>
                                        <div>Title: {item.title}</div>
                                        <div>Count: {item.c}</div>
                                        <div className="dupe-button">
                                            <Button size="small" variant="contained" onClick={() => this.openManage(item.title,3,item.cta)}>Manage</Button>
                                            <Button disabled={true} size="small" variant="contained">Auto Fix</Button>
                                        </div>
                                    </div>
                                )) : (<div>No Story Dupes</div>)}
                        </div>
                        <div className="dupe-title">Watch Content</div>
                        <div className="dupe-container">
                            {dupes.watch.length > 0 ? dupes.watch.map((item, key)  => (
                                    <div className="dupe-card" key={"watch-"+key}>
                                        <div>Title: {item.title}</div>
                                        <div>Count: {item.c}</div>
                                        <div className="dupe-button">
                                            <Button size="small" variant="contained" onClick={() => this.openManage(item.title,4,item.desc)}>Manage</Button>
                                            <Button disabled={true} size="small" variant="contained">Auto Fix</Button>
                                        </div>
                                    </div>
                                )) : (<div>No Watch Content Dupes</div>)}
                        </div>
                        <div className="dupe-title">Watch Stims</div>
                        <div className="dupe-container">
                            {dupes.watch_ad.length > 0 ? dupes.watch_ad.map((item, key)  => (
                                    <div className="dupe-card" key={"watchAd-"+key}>
                                        <div>Title: {item.title}</div>
                                        <div>Count: {item.c}</div>
                                        <div className="dupe-button">
                                            <Button size="small" variant="contained" onClick={() => this.openManage(item.title,5,"extra")}>Manage</Button>
                                            <Button disabled={true} size="small" variant="contained">Auto Fix</Button>
                                        </div>
                                    </div>
                                )) : (<div>No Watch Stim Dupes</div>)}
                        </div>
                        <div className="dupe-title">TikTok Content</div>
                        <div className="dupe-container">
                            {dupes.tiktok.length > 0 ? dupes.tiktok.map((item, key)  => (
                                    <div className="dupe-card" key={"tiktok-"+key}>
                                        <div>Title: {item.title}</div>
                                        <div>Count: {item.c}</div>
                                        <div className="dupe-button">
                                            <Button size="small" variant="contained" onClick={() => this.openManage(item.title,6,item.desc)}>Manage</Button>
                                            <Button disabled={true} size="small" variant="contained">Auto Fix</Button>
                                        </div>
                                    </div>
                                )) : (<div>No TikTok Content Dupes</div>)}
                        </div>
                        <div className="dupe-title">TikTok Stims</div>
                        <div className="dupe-container">
                            {dupes.tiktok_ad.length > 0 ? dupes.tiktok_ad.map((item, key)  => (
                                    <div className="dupe-card" key={"tiktokAd-"+key}>
                                        <div>Title: {item.title}</div>
                                        <div>Count: {item.c}</div>
                                        <div className="dupe-button">
                                            <Button size="small" variant="contained" onClick={() => this.openManage(item.title,7,item.desc)}>Manage</Button>
                                            <Button disabled={true} size="small" variant="contained">Auto Fix</Button>
                                        </div>
                                    </div>
                                )) : (<div>No TikTok Watch Dupes</div>)}
                        </div>
                        <div className="dupe-title">Snapchat Content</div>
                        <div className="dupe-container">
                            {dupes.snapchat.length > 0 ? dupes.snapchat.map((item, key)  => (
                                    <div className="dupe-card" key={"sc-"+key}>
                                        <div>Title: {item.title}</div>
                                        <div>Count: {item.c}</div>
                                        <div className="dupe-button">
                                            <Button size="small" variant="contained" onClick={() => this.openManage(item.title,8,item.desc)}>Manage</Button>
                                            <Button disabled={true} size="small" variant="contained">Auto Fix</Button>
                                        </div>
                                    </div>
                                )) : (<div>No Snapchat Content Dupes</div>)}
                        </div>
                        <div className="dupe-title">Snapchat Stims</div>
                        <div className="dupe-container">
                            {dupes.snapchat_ad.length > 0 ? dupes.snapchat_ad.map((item, key)  => (
                                    <div className="dupe-card" key={"scAd-"+key}>
                                        <div>Title: {item.title}</div>
                                        <div>Count: {item.c}</div>
                                        <div className="dupe-button">
                                            <Button size="small" variant="contained" onClick={() => this.openManage(item.title,9,item.desc)}>Manage</Button>
                                            <Button disabled={true} size="small" variant="contained">Auto Fix</Button>
                                        </div>
                                    </div>
                                )) : (<div>No Snapchat Stim Dupes</div>)}
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(Dupes);
