import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import PrintIcon from '@mui/icons-material/Print';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReplyIcon from '@mui/icons-material/Reply';
import {Avatar} from "@mui/material";

class DMail extends React.Component {
    render() {
        return (
            <div className="gmail dmail">
                <div className="mailItem">
                    <div className="topper">
                        <div className="left">
                            {this.props.mail.desc}
                        </div>
                        <div className="right">
                            <PrintIcon />
                            <OpenInNewIcon />
                        </div>
                    </div>
                    <div className="actions">
                        <div className="left">
                            <Avatar className="avatar" src={this.props.mail.pic} />
                            <div className="info">
                                <div className="title">{this.props.mail.name}</div>
                                <div>to {this.props.mail.name}</div>
                            </div>
                        </div>
                        <div className="right">
                            <div style={{marginRight: "20px"}}>{this.props.mail.posted_on}</div>
                            <StarBorderRoundedIcon />
                            <ReplyIcon />
                            <MoreVertIcon />
                        </div>
                    </div>
                    <div className="mailContent" style={{background: this.props.mail.background_color}}>
                        <img src={this.props.mail.src} style={{width: `${this.props.mail.content_width}%`}} alt="maincontent" />
                    </div>
                </div>
            </div>
        )
    }
}

export default DMail