import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import {NavLink} from "react-router-dom";

const routes = [
    { path: '/personas/edit', breadcrumb: 'doNotDisplay'},
    { path: '/personas/edit/:id', breadcrumb: 'Edit' },
    { path: '/content/edit/:type/:id', breadcrumb: 'Edit' },
    { path: '/content/edit', breadcrumb: 'doNotDisplay' },
    { path: '/article/edit', breadcrumb: 'doNotDisplay' },
    { path: '/article/edit/:id', breadcrumb: 'Edit' },
    { path: '/playlist/edit', breadcrumb: 'doNotDisplay' },
    { path: '/playlist/edit/:id', breadcrumb: 'Edit' },
    { path: '/content', breadcrumb: 'doNotDisplay' },
];

const Breadcrumbs = ({ breadcrumbs }) => (
    <>
        {breadcrumbs.map(({match, breadcrumb}) => (
            <>
            {breadcrumb.props.children === 'doNotDisplay' ? "" : (
                <span key={match.url} id={match.url}>
                    <NavLink to={match.url}>{breadcrumb}</NavLink>
                </span>
            ) }
            </>
        ))}
    </>
)

export default withBreadcrumbs(routes)(Breadcrumbs);