import React from "react";
import {FacebookPost, InstagramPost, PinterestPost, VKPost, FacebookStory, InstagramStory, VKStory, TikTokPost, SnapchatPost, TwitterPost} from "@lumen-developer/lumen-lookalikes/dist/Previews";

class contentPreview extends React.Component {
    render() {
        return (
            <>
                {(this.props.type === "content" || this.props.type === "stim" || this.props.type.includes("watch")) && (
                    <div
                        className={
                            this.props.lookalike.value === "pn"
                                ? this.props.content.promo_desc === "m"
                                    ? "cont-post-edit-pnmax"
                                    : "cont-post-edit-pnstd"
                                : "cont-post-edit"
                        }
                    >
                        {this.props.lookalike.value === "fb" ? (
                            <FacebookPost
                                post={this.props.content}
                                lang_code={"EN"}
                                country_code={"GB"}
                            ></FacebookPost>
                        ) : this.props.lookalike.value === "ig" ? (
                            <InstagramPost
                                post={this.props.content}
                                lang_code={"EN"}
                                country_code={"GB"}
                            ></InstagramPost>
                        ) : this.props.lookalike.value === "pn" ? (
                            <>
                                <PinterestPost
                                    isStim={this.props.content.promoted}
                                    post={this.props.content}
                                ></PinterestPost>
                                <div
                                    style={
                                        this.props.content.promo_desc === "m"
                                            ? {}
                                            : {
                                                position: "absolute",
                                                backgroundColor: "gainsboro",
                                                width: "calc(50% - 16px)",
                                                margin: "0px 8px",
                                                borderRadius: "8px",
                                                right: "0px",
                                                top: "8px",
                                                height: "calc(100% - 16px)",
                                            }
                                    }
                                ></div>
                            </>
                        ) : this.props.lookalike.value === "vk" ? (
                            <VKPost
                                post={this.props.content}
                                lang_code={"EN"}
                            ></VKPost>
                        ) : (
                            <TwitterPost
                                post={this.props.content}
                                lang_code={"EN"}
                                country_code={"GB"}
                            />
                            )}
                    </div>
                )}
                {(this.props.type.includes("reel") || this.props.type.includes("story")) && (
                    <div className="phone">
                        <div className="box">
                            <div className="tok-frame" style={{ width: "375px", height: "667px" }}>
                                {this.props.type.includes("reel") && (
                                    <TikTokPost
                                        isStim={this.props.content.promoted}
                                        post={this.props.content}
                                        lang_code={"EN"}
                                        country_code={"GB"}
                                    ></TikTokPost>
                                )}
                                {this.props.type.includes("story") && (
                                    <>
                                        {this.props.lookalike.value === "fb" ? (
                                            <FacebookStory
                                                post={this.props.content}
                                            ></FacebookStory>
                                        ) : this.props.lookalike.value === "ig" ? (
                                            <InstagramStory
                                                post={this.props.content}
                                            ></InstagramStory>
                                        ) : (
                                            <VKStory
                                                stim_url={this.props.content.src}
                                                vk_profile_src={this.props.content.pic}
                                                vk_channel_name={this.props.content.name}
                                            ></VKStory>
                                        )}
                                    </>
                                )}
                                {this.props.type.includes("snapchat") && (
                                    <SnapchatPost
                                        post={this.props.content.promoted}
                                        isStim={true}                                        lang_code={"EN"}
                                        country_code={"GB"}
                                    ></SnapchatPost>
                                )}
                            </div>
                            <div className="earSpeaker"></div>
                            <div className="toggleSwitch"></div>
                            <div className="volumeRocker"></div>
                            <div className="powerBtn"></div>
                            <div className="homeBtn"></div>
                        </div>
                    </div>
                )}
                {(this.props.type.includes("video")) && (
                    <div className="phone">
                        Preview coming soon
                    </div>
                )}
            </>
        );
    }
}

export default contentPreview;
