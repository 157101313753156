import React from "react";
import {Autocomplete, Button, FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";

const lookalikes = [
    { value: "fb", label: "Facebook" },
    { value: "ig", label: "Instagram" },
    { value: "pn", label: "Pinterest" },
    // { value: "vk", label: "VK" },
];

class watchStimRight extends React.Component {
    render() {
        const tagOptions = this.props.tags.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            if (option.important) {
                return {
                    firstLetter: " IMPORTANT",
                    ...option
                }
            } else {
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                    ...option,
                };
            }
        });
        const defaultTags = this.props.linkTag.map((option) => {
            return tagOptions.filter((obj) => {
                return obj.id === parseInt(option.tag);
            })[0];
        });
        return (
            <div className="unique-panel">
                <Autocomplete
                    defaultValue={lookalikes[0]}
                    renderInput={(params) => <TextField style={{marginBottom: "10px"}} variant="outlined" {...params} label="Feed Type" />}
                    options={lookalikes}
                    getOptionLabel={(option) => option.label}
                    onChange={this.props.handleUpdate(5,"lookalike")}
                />
                <Autocomplete
                    multiple
                    defaultValue={defaultTags}
                    renderInput={(params) => <TextField style={{marginBottom: "10px"}} variant="outlined" {...params} label="Tags" />}
                    options={tagOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    onChange={this.props.handleUpdate(5,"linkTag")}
                />
                <FormControl sx={{width:"100%"}} className="spacer" variant="outlined">
                    <InputLabel>Lang</InputLabel>
                    <Select
                        value={this.props.content.lang_id}
                        onChange={this.props.handleUpdate(1)}
                        label="Lang"
                        name="lang_id"
                    >
                        {this.props.lang.map((item) => (
                            <MenuItem value={item.id} key={item.id}>{item.language}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {this.props.isModal ? (
                    <>
                        <Button
                            disabled={this.props.posting}
                            variant="contained"
                            color="primary"
                            className="spacer"
                            onClick={() => this.props.close()}
                        >
                            Close
                        </Button>
                        <Button
                            disabled={this.props.posting}
                            variant="contained"
                            color="primary"
                            style={{marginLeft: "10px"}}
                            onClick={() => this.props.updateContent()}
                        >
                            Save
                        </Button>
                        <div>Warning Content in modal maybe closer than they appear</div>
                    </>
                ) : ""}
            </div>
        );
    }
}

export default watchStimRight;