import React from 'react';
import {Link} from "react-router-dom";
import Header from "../header/header";
import {authenticationService} from "../scripts/authentication.service";
import {searchService} from "../scripts/search.service";
import Items from "../api/content";
import Stims from "../api/stims";
import {cf} from "../scripts/custom-fetch";
import {
    CardMedia,
    Tooltip,
    Button,
    InputAdornment,
    Pagination,
    TextField,
    Autocomplete,
    LinearProgress,
    ToggleButton, ToggleButtonGroup
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Tags from "./components/tags";
import {withSnackbar} from "notistack";
import F0F from "../errors/404";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.updateSearchTags = this.updateSearchTags.bind(this)
        this.updateSearchText = this.updateSearchText.bind(this)

        this.state = {
            type: 1,
            should404: false,
            isStim: false,
            error: null,
            currentTags: searchService.currentSearchValue.tags,
            currentSearch: searchService.currentSearchValue.search,
            deletedItems: [],
            isLoaded: false,
            openDelete: false,
            restore: false,
            deleteID: 0,
            content: [],
            maxContent: 0,
            tags: [],
            linkTags: [],
            currentSpin: searchService.currentSearchValue.spin,
            currentType: searchService.currentSearchValue.type,
            loadCount: 0,
            currentPage: searchService.currentSearchValue.page,
            itemsPerPage: 15,
            currentUser: authenticationService.currentUserValue,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.getType()
        }
    }

    getType = () => {
        this.setState({
            currentTags: searchService.currentSearchValue.tags,
            currentSearch: searchService.currentSearchValue.search,
            currentSpin: searchService.currentSearchValue.spin,
            currentType: searchService.currentSearchValue.type,
            currentPage: searchService.currentSearchValue.page
        })
        switch (this.props.match.params.type) {
            case "content":
                this.setState({
                    type:1,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "stim":
                this.setState({
                    type:2,
                    isStim: true
                }, () => {this.getContent()})
                break;
            case "story":
                this.setState({
                    type:3,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "watch":
                this.setState({
                    type:4,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "watch_stim":
                this.setState({
                    type:5,
                    isStim: true
                }, () => {this.getContent()})
                break;
            case "reel":
                this.setState({
                    type:6,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "reel_stim":
                this.setState({
                    type:7,
                    isStim: true
                }, () => {this.getContent()})
                break;
            case "gmail":
                this.setState({
                    type:12,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "video":
                this.setState({
                    type:16,
                    isStim: false
                }, () => {this.getContent()})
                break;
            case "video_stim":
                this.setState({
                    type:17,
                    isStim: true
                }, () => {this.getContent()})
                break;
            default:
                this.setState({should404: true})
                break;
        }
    }

    getContent(){
        let option = {
            search: this.state.currentSearch,
            option: this.state.type,
            tags: this.state.currentTags,
            spin: this.state.currentSpin,
            type: this.state.currentType,
            page: this.state.currentPage,
        }
        if (this.state.isStim) {
            Stims.getSearchStims(option)
                .then(result => {
                    this.setState({
                        content: result.content,
                        maxContent: result.maxContent,
                    }, () => {
                        this.getTags();
                    });
                })
        } else {
            Items.getSearchContent(option)
                .then(result => {
                    this.setState({
                        content: result.content,
                        maxContent: result.maxContent,
                    }, () => {
                        this.getTags();
                    });
                })
        }
    }

    getTags() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/tag/getTags`, options)
            .then((result) => {
                    this.setState({
                        tags: result,
                    }, () => {
                        this.getLinks()
                    });
                },
                (error) =>{this.setState({error})}
            )
    }

    getLinks() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({
                'option': this.state.type
            })
        };

        cf.get(`${process.env.REACT_APP_API_GET}/tag/getLinks`, options)
            .then((result) => {
                    this.setState({
                        linkTags: result,
                        isLoaded: true,
                    });
                },
                (error) =>{this.setState({error})}
            )
    }

    openDelete = (item) => {
        this.setState({
            openDelete: true,
            deleteID: item
        })
    }

    closeDelete = () => {
        this.setState({
            openDelete: false
        })
    }

    delete = () => {
        let state
        let msg1
        let msg2
        if (this.state.restore) {
            msg1 = "Restoring"
            msg2 = "Restored"
            state = 1
        } else {
            msg1 = "Deleting"
            msg2 = "Deleted"
            state = 3
        }
        this.props.enqueueSnackbar(msg1, {variant:"info"});
        this.setState({
            openDelete: false,
        })
        Items.disable(this.state.type,state,this.state.deleteID).then(result => {
            this.getContent()
            this.setState({
                restore: false
            })
            console.log(result)
            this.props.enqueueSnackbar(msg2, {variant:"success"});
        })
    }

    openRestore = () => {
        Items.getDeletedItems(this.state.type).then(result => {
            if (result) {
                this.setState({
                    deletedItems: result,
                    restore: true
                })
            }
        })
    }

    closeRestore = () => {
        this.setState({
            restore: false
        })
    }

    componentDidMount() {
        this.getType();
    }

    setPage(event, value) {
        let data = searchService.currentSearchValue
        data.page = value
        searchService.setSearch(data)
        this.setState({
            currentPage: value
        }, () => {
            this.getContent()
        })
    }

    updateSearchTags(event, newValue) {
        let data = searchService.currentSearchValue
        data.tags = newValue
        searchService.setSearch(data)
        this.setState({
            currentTags: newValue
        })
    }

    updateSearchText(event) {
        let data = searchService.currentSearchValue
        data.search = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentSearch: event.target.value
        })
    }

    updateSearchSpin = (event) => {
        let data = searchService.currentSearchValue
        data.spin = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentSpin: event.target.value
        }, () => {
            this.getContent()
        })
    }

    updateSearchType = (event) => {
        let data = searchService.currentSearchValue
        data.type = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentType: event.target.value
        }, () => {
            this.getContent()
        })
    }

    render() {
        const { error, isLoaded, currentPage, itemsPerPage, content, maxContent, currentSpin, currentSearch, currentType, linkTags, tags, openDelete, restore, deletedItems, should404, currentUser } = this.state;
        const perms = currentUser.perms

        if (should404) {
            return <F0F />
        } else {
            let currentItems
            if (restore) {
                currentItems = deletedItems
            } else {
                currentItems = content
            }

            if (error) {
                return <div>Error: {error.message}</div>;
            } else if (!isLoaded) {
                return (
                    <>
                        <Header/>
                        <div style={{width:"50%"}}>
                            <LinearProgress />
                        </div>
                    </>
                )
            } else {
                const tagOptions = tags.map((option) => {
                    const firstLetter = option.name[0].toUpperCase();
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                        ...option,
                    };
                });
                return (
                    <>
                        <Header/>

                        <div className="content-header">
                            <div className="left" style={{display: "flex"}}>
                                <TextField
                                    label="Search"
                                    style={{marginRight:"10px"}}
                                    onChange={this.updateSearchText}
                                    focused
                                    size="small"
                                    value={currentSearch}
                                    className="foreInputWhite"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon color="primary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Autocomplete
                                    multiple
                                    size="small"
                                    className="foreInputWhite"
                                    renderInput={(params) => <TextField focused style={{width: "250px"}} variant="outlined" {...params} label="Tags" />}
                                    options={tagOptions.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.updateSearchTags}
                                />
                                <ToggleButtonGroup
                                    color="primary"
                                    value={currentSpin}
                                    exclusive
                                    onChange={this.updateSearchSpin}
                                    style={{marginLeft: "10px", border: "#1565c0 2px solid"}}
                                >
                                    <ToggleButton className="testing" value="0">New</ToggleButton>
                                    <ToggleButton className="testing" value="1">Old</ToggleButton>
                                </ToggleButtonGroup>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={currentType}
                                    exclusive
                                    onChange={this.updateSearchType}
                                    style={{marginLeft: "10px", border: "#1565c0 2px solid"}}
                                >
                                    <ToggleButton className="testing" value="0">All</ToggleButton>
                                    <ToggleButton className="testing" value="1">Stims</ToggleButton>
                                    <ToggleButton className="testing" value="2">Non-Stims</ToggleButton>
                                </ToggleButtonGroup>
                                <Button variant="contained" color="primary" style={{marginLeft: "10px"}} onClick={() => this.getContent()}>Search</Button>

                            </div>
                            <div className="right" style={{display: "flex"}}>
                                <Link to={`edit/${this.props.match.params.type}/new`}><Button style={{marginRight: "10px"}} variant="contained" color="primary" className="spacer">New</Button></Link>
                                {restore ? <Button variant="contained" color="primary" onClick={() => this.closeRestore()} className="spacer">Normal</Button> : <Button disabled={!perms.c3} variant="contained" color="primary" onClick={() => this.openRestore()} className="spacer">Restore</Button>}
                                <Link to="/"><Button style={{marginLeft: "10px"}} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            </div>
                        </div>

                        <div className="profiles-container">
                            {currentItems.map((item) => (
                                <div className="card" key={item.id}>
                                    <div className="items-wrapper">
                                        <div className="mediaCard">
                                            {item.src ? (
                                                <CardMedia
                                                    component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "img" }
                                                    image={item.src}
                                                    title={item.title}
                                                />
                                            ) : (
                                                <div style={{color: "#ffffff85", fontWeight: 700, fontSize: "30px"}}>
                                                    WHERE'S THE LAMB SAUCE
                                                </div>
                                            ) }
                                        </div>
                                        <div className="text">
                                            <div className="title">Title: {item.title}</div>
                                            <div className="date">Date: {new Date(item.date_created).getDay()}/{new Date(item.date_created).getMonth()}/{new Date(item.date_created).getFullYear()}</div>
                                        </div>
                                        <div className="actions">
                                            <Link to={perms.c2 ? `/content/edit/${this.props.match.params.type}/${item.id}` : `/content/${this.props.match.params.type}`}>
                                                <Button disabled={!perms.c2} variant="contained" size="small" color="secondary">
                                                    Edit
                                                </Button>
                                            </Link>
                                            <Link>
                                                <Button disabled={!perms.c3} variant="contained" size="small" color="secondary" onClick={() => this.openDelete(item.id)}>
                                                    {restore ? "Restore" : "Delete"}
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                    {item.status === 4 && (
                                        <div className="src-warning">
                                            <Tooltip title={"Source Error"}><GppMaybeIcon color="error" /></Tooltip>
                                        </div>
                                    )}
                                    <div className="tags">
                                        <Tags tags={tags} lTags={linkTags} item={item.id} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={(openDelete ? 'user-box' : 'user-box hide')}>
                            <div className="user-container">
                                <p>Are you sure ?</p>
                                <div style={{display: "flex", marginTop: "10px"}}>
                                    <Button variant="contained" color="primary" style={{flex: 1, marginRight: "10px"}} onClick={this.delete}>Yes</Button>
                                    <Button variant="contained" color="primary" style={{flex: 1}} onClick={this.closeDelete}>Nope</Button>
                                </div>
                            </div>
                        </div>
                        <div className="pagination-container">
                            <Pagination color="secondary" count={Math.ceil(maxContent[0].records / itemsPerPage)} page={currentPage} onChange={(event, page) => this.setPage(event, page)} />
                        </div>
                    </>
                );
            }
        }
    }
}

export default withSnackbar(Content);
