import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import Facebook from "@lumen-developer/lumen-lookalikes/dist/Facebook";
import Instagram from "@lumen-developer/lumen-lookalikes/dist/Instagram";
import Pinterest from "@lumen-developer/lumen-lookalikes/dist/Pinterest";
import TikTok from "@lumen-developer/lumen-lookalikes/dist/TikTok";
import Douyin from "@lumen-developer/lumen-lookalikes/dist/Douyin";
import Xiao from "@lumen-developer/lumen-lookalikes/dist/Xiao";
import Abema from "@lumen-developer/lumen-lookalikes/dist/Abema";
import Video from "@lumen-developer/lumen-lookalikes/dist/Video";
import Videoland from "@lumen-developer/lumen-lookalikes/dist/Videoland";
import ITVX from "@lumen-developer/lumen-lookalikes/dist/ITVX";
import Tencent from "@lumen-developer/lumen-lookalikes/dist/Tencent";
import TVer from "@lumen-developer/lumen-lookalikes/dist/TVer";
import Snapchat from "@lumen-developer/lumen-lookalikes/dist/Snapchat";
import Gmail from "@lumen-developer/lumen-lookalikes/dist/Gmail"
import Twitter from "@lumen-developer/lumen-lookalikes/dist/Twitter"
import Music from "@lumen-developer/lumen-lookalikes/dist/Music"
import Article from "@lumen-developer/lumen-lookalikes/dist/Article"
import F0F from "../errors/404";

const Socials = () => {
    let {socialsitename, id} = useParams();

    useEffect(() => {

        let urlParams = new URLSearchParams(window.location.search);

        let testPrompts = urlParams.get("test_prompts");

        if (!!testPrompts) {
            window.LRIsReady = true
            window.LRhandleStepEnd = () => {
                console.log('Moving to next step!')
            }
        }
    }, [socialsitename]);

    switch (socialsitename) {
        case "pinterest":
            return (
                <Pinterest
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Pinterest>
            );
        case "instagram":
            return (
                <Instagram
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Instagram>
            );
        case "facebook":
            return (
                <Facebook
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Facebook>
            );
        case "twitter":
            return (
                <Twitter
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Twitter>
            );
        case "music":
            return (
                <Music
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Music>
            );
        case "article":
            return (
                <Article
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Article>
            );
        case "snapchat":
            return (
                <Snapchat
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Snapchat>
            );
        case "tiktok":
            return (
                <TikTok
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></TikTok>
            );
        case "douyin":
            return (
                <Douyin
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Douyin>
            );
        case "xiao":
            return (
                <Xiao
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Xiao>
            );
        case "video":
            return (
                <Video
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Video>
            );
        case "videoland":
            return (
                <Videoland
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Videoland>
            );
        case "itvx":
            return (
                <ITVX
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></ITVX>
            );
        case "tencent":
            return (
                <Tencent
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Tencent>
            );
        case "abema":
            return (
                <Abema
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></Abema>
            );
        case "tver":
            return (
                <TVer
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                ></TVer>
            );
        case "gmail":
            return (
                <Gmail
                    env={process.env.REACT_APP_ENVIRONMENT}
                    baseURL={process.env.REACT_APP_API_GET}
                    vendorId={"lumen"}
                    personaId={id}
                    screenshotMode={false}
                    handleStepEnd={() => {
                        window.LRhandleStepEnd();
                    }}
                    fullScreen={true}
                >
                </Gmail>
            )
        default:
            return <F0F/>;
    }
};

export default Socials;
