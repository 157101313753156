import {authenticationService} from "../scripts/authentication.service";
import {cf} from "../scripts/custom-fetch";

/*
Disable type
1/content - post
2/stim - ad
3/story - story
4/watch - watch
5/watch_stim - watch_ad
6/tiktok - tiktok
7/tiktok_stim - tiktok_ad
8/snapchat - snapchat
9/snapchat_stim - snapchat_ad
10/persona - persona
11/profile - profile
12/article - article
Status
1 - enabled
2 - pending - still viewable
3 - "deleted"
 */
/**
 * Disable Content / Personas
 * @param {int} id - Desired Content ID
 * @param {string} type - 1 thru 12 to select type
 * @param {int} status - 1 thru 3 to select status
 * @returns {Promise} Promise I get the data UwU
 */
async function disable(type, status, id) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({
            'type':type,
            'status':status,
            'id':id,
        })
    };

    cf.get(`${process.env.REACT_APP_API_POST}/status/set`, options)
        .then((result) => {
                return result[0]
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get Deleted Content / Personas
 * @param {string} type - 1 thru 12 to select type
 * @returns {Promise} Promise I get the data UwU
 */
async function getDeletedItems(type) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({
            'type':type,
            'status':3,
        })
    };

    return cf.get(`${process.env.REACT_APP_API_POST}/status/getStatusContent`, options)
}

/**
 * Get Singular Content Item By ID
 * @param {int} id - Desired Content ID
 * @param {string} option - 1 thru 12 to select social
 * @returns {Promise} Promise I get the data UwU
 */
async function getContent(id, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'id':id, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/content/getContent`, options)
        .then((result) => {
                return result[0]
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get All Content Items With Search Values
 * @param {json} option - json with values needed for search
 * search = string of search, tags = array of tag ids, spin = direction of search, type = viewability tracking, page
 */
async function getSearchContent(option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'options': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/content/getSearchContent`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get All Content Items
 * @param {string} option - 1 thru 12 to select social
 */
async function getAllContent(option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/content/getAllContent`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get All Content Items For Persona UUID
 * @param {string} uuid - UUID for persona
 * @param {string} option - 1 thru 12 to select social
 */
async function getPersonaContent(uuid, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'uuid':uuid, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/content/getPersonaContent`, options)
        .then((result) => {
                return result.sort(function(a, b){return a.order - b.order})
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get Feed Items For Persona UUID
 * @param {string} uuid - UUID for persona
 * @param {boolean} content_ordered - Determines random Content order
 * @param {boolean} stim_ordered - Determines random Stim order
 * @param {string} option - 1 thru 12 to select social
 */
async function getFeed(uuid, content_ordered, stim_ordered, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({'uuid':uuid, 'content_ordered':content_ordered, 'stim_ordered':stim_ordered, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_GET}/content/getFeed`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Create New Content Item
 * @param {object} content - Object for content
 * @param {string} option - 1 thru 12 to select social
 */
async function newContent(content, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'content':content, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/content/newContent`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Update Current Content Item
 * @param {int} id - ID of content item
 * @param {object} content - Object for content
 * @param {string} option - 1 thru 15 to select social
 */
async function setContent(id, content, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'id':id, 'content':content, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/content/setContent`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Link Content to Persona
 * @param {int} persona_id - ID of persona
 * @param {int} content_id - ID of content item
 * @param {int} order - Order of content item in feed
 * @param {int} option - 1 thru 15 to select social
 */
async function linkContent( persona_id, content_id, order, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'persona_id':persona_id, 'content_id':content_id, 'order':order, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/content/linkContent`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Unlink Content from Persona
 * @param {int} persona_id - ID of persona
 * @param {int} content_id - ID of content item
 * @param {int} option - 1 thru 15 to select social
 */
async function unlinkContent( persona_id, content_id, option) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'persona_id':persona_id, 'content_id':content_id, 'option': option})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/content/unlinkContent`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get Music Content
 * @param {int} id - ID of content
 */
async function getMusic( id) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'id':id})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/music/getMusic`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get All Music Content
 */
async function getAllMusic() {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/music/getAllMusic`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get Playlist
 * @param {int} id - ID of content
 */
async function getPlaylist( id) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'id':id})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/music/getPlaylist`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get All Playlist
 */
async function getAllPlaylists() {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/music/getAllPlaylists`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * New Playlist
 * @param {object} content - New Content
 */
async function newPlaylist(content) {
    const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        body: JSON.stringify({'content':content})
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/music/newPlaylist`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}

/**
 * Get All Music Content
 */
async function getAllArticles() {
    const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
    };

    return await cf.get(`${process.env.REACT_APP_API_POST}/article/getAllAritcles`, options)
        .then((result) => {
                return result
            },
            (error) =>{
                return error
            }
        )
}


let exports = {
    disable,
    getContent,
    getAllContent,
    getSearchContent,
    getPersonaContent,
    getFeed,
    newContent,
    setContent,
    linkContent,
    unlinkContent,
    getDeletedItems,
    getMusic,
    getAllMusic,
    getPlaylist,
    getAllPlaylists,
    newPlaylist,
    getAllArticles
}

export default exports;