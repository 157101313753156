import React from "react";
import {Autocomplete, FormControlLabel, Switch, TextField} from "@mui/material";

class videoLeft extends React.Component {
    render() {
        const options = this.props.profiles.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                ...option,
            };
        });
        return (
            <div className="post-panel">
                <TextField error={!!this.props.errors.title} helperText={this.props.errors.title} style={{marginBottom: "10px"}} variant="outlined" label="Title" value={this.props.content.title} name="title" onChange={this.props.handleUpdate(1)} />
                <Autocomplete
                    defaultValue={options.filter(obj => {return obj.id === parseInt(this.props.content.profile_id)})[0]}
                    renderInput={(params) => <TextField style={{marginBottom: "10px"}} variant="outlined" {...params} label="Profile" />}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    onChange={this.props.handleUpdate(3)}
                />
                <TextField style={{marginBottom: "10px"}} variant="outlined" multiline label="Desc" value={this.props.content.desc} name="desc" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Content" value={this.props.content.src} name="src" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Cover Src" value={this.props.content.cover_src} name="cover_src" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Text 1" value={this.props.content.text_1} name="text_1" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Text 2" value={this.props.content.text_2} name="text_2" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Text 3" value={this.props.content.text_3} name="text_3" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Text 4" value={this.props.content.text_4} name="text_4" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.comments} helperText={this.props.errors.comments} style={{marginBottom: "10px"}} variant="outlined" label="Comments" value={this.props.content.comments} name="comments" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.likes} helperText={this.props.errors.likes} style={{marginBottom: "10px"}} variant="outlined" label="Likes" value={this.props.content.likes} name="likes" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.views} helperText={this.props.errors.views} style={{marginBottom: "10px"}} variant="outlined" label="Views" value={this.props.content.views} name="views" onChange={this.props.handleUpdate(1)} />
                <TextField error={!!this.props.errors.row} helperText={this.props.errors.row} style={{marginBottom: "10px"}} variant="outlined" label="Row" value={this.props.content.row} name="row" onChange={this.props.handleUpdate(1)} />
                <TextField
                    error={!!this.props.errors.posted_on}
                    helperText={this.props.errors.posted_on}
                    style={{ marginBottom: "10px" }}
                    variant="outlined"
                    label="Post Date"
                    value={this.props.content.posted_on}
                    name="posted_on"
                    onChange={this.props.handleUpdate(1)}
                />
                <FormControlLabel
                    className="spacer"
                    control={
                        <Switch
                            checked={this.props.content.muted}
                            onChange={this.props.handleUpdate(2)}
                            name="muted"
                            color="primary"
                        />
                    }
                    label="Muted"
                />
                <FormControlLabel
                    className="spacer"
                    control={
                        <Switch
                            checked={this.props.content.viewability_tracking}
                            onChange={this.props.handleUpdate(2)}
                            name="viewability_tracking"
                            color="primary"
                        />
                    }
                    label="Viewability Tracking"
                />
            </div>
        );
    }
}

export default videoLeft;
