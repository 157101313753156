import React from "react";
import { Button } from "@mui/material";

function WarningModal({ onClose }) {
  return (
    <div>
      <div className="warningModal">
        <h3 className="warning padding">
          APPLICATION ALREADY EXISTS <br />
          <br />
          <span className="warningModal_fontColor">
            Please add an alternative application name.
          </span>
        </h3>
        <div className="warningModal_btnContainer">
           <Button
          variant="contained"
          color="primary"
          className="warningModal-btn"
          onClick={onClose}
        >
          OK
        </Button>
        </div>
       
      </div>
    </div>
  );
}

export default WarningModal;
