import React from "react";
import Header from "../header/header";
import {withSnackbar} from "notistack";

class F0F extends React.Component {
    render() {
        return (
            <>
                <Header />
                <p className="F0F">404</p>
            </>
        );
    }
}

export default withSnackbar(F0F);
