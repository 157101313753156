import React from 'react';
import {authenticationService} from "../../scripts/authentication.service";
import Header from "../../header/header";
import {Link} from "react-router-dom";
import {TextField, Switch, FormControlLabel, Button, LinearProgress, Avatar} from '@mui/material'
import {withSnackbar} from "notistack";
import {cf} from "../../scripts/custom-fetch";

class ProfileEdit extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        this.state = {
            id: this.props.match.params.id,
            profile: null,
            isLoaded: false,
            error: null,
            errors: {},
            posting: false,
        };
    }

    getProfile() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.id})
        };

        if (this.state.id === 'new') {
            console.log("New")
            this.setState({
                isLoaded: true,
                profile: {
                    id: 'new',
                    name: '',
                    channel_name: '',
                    pic: '',
                    verified: 0,
                    company: 0
                }
            });
        } else {
            cf.get(`${process.env.REACT_APP_API_GET}/db/getProfile`, options)
                .then((result) => {
                        this.setState({
                            isLoaded: true,
                            profile: result[0]
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }

    validate = () => {
        let profile = this.state.profile
        let errors = {}
        let valid = true

        if (!profile.name || profile.name.length < 1) {
            valid = false
            errors.name = "Name must exist"
        }
        if (escape(profile.name).length >= 1024) {
            valid = false
            errors.name = "Name too long"
        }

        if (!profile.channel_name || profile.channel_name.length < 1) {
            valid = false
            errors.channel_name = "Channel Name must exist"
        }
        if (escape(profile.channel_name).length >= 1024) {
            valid = false
            errors.channel_name = "Channel Name too long"
        }

        if (!profile.pic || profile.pic.length < 1) {
            valid = false
            errors.pic = "Image must exist"
        }

        this.setState({errors:errors})
        return valid
    }

    updateProfiles() {
        this.props.enqueueSnackbar('Validating', {variant:"info"});
        if(this.validate()) {
            this.props.enqueueSnackbar('Saving', {variant:"info"});
            let temp = this.state.profile
            this.setState({
                posting: true,
            }, () => {
                const options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                    body: JSON.stringify({
                        'id':this.state.id,
                        'profile':temp
                    })
                };

                if (this.state.id === 'new') {
                    cf.get(`${process.env.REACT_APP_API_POST}/db/newProfile`, options)
                        .then((result) => {
                                console.log(result)
                                this.setState({
                                    posting: false
                                }, () => {
                                    if (!!result) {
                                        this.props.enqueueSnackbar('Saved', {variant:"success"});
                                    } else {
                                        this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                                    }
                                });
                            },
                            (error) => {
                                console.log(error)
                                this.setState({
                                    posting: false
                                }, () => {
                                    this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                                });
                            }
                        )
                } else {
                    cf.get(`${process.env.REACT_APP_API_POST}/db/setProfile`, options)
                        .then((result) => {
                                console.log(result)
                                this.setState({
                                    posting: false
                                }, () => {
                                    if (!!result) {
                                        this.props.enqueueSnackbar('Saved', {variant:"success"});
                                    } else {
                                        this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                                    }
                                });
                            },
                            (error) => {
                                console.log(error)
                                this.setState({
                                    posting: false
                                }, () => {
                                    this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                                });
                            }
                        )
                }
            })
        } else {
            this.props.enqueueSnackbar('Validation Failed Please Check Your Inputs', {variant:"error"});
        }
    }

    componentDidMount() {
        this.getProfile()
    }

    handleChange(event) {
        let temp = this.state.profile;
        temp[event.target.name] = event.target.value;
        this.setState({profile: temp})
    }

    handleCheck(event) {
        let temp = this.state.profile;
        if (temp[event.target.name] === 1) {
            temp[event.target.name] = 0
        } else {
            temp[event.target.name] = 1
        }
        this.setState({profile: temp})
    }

    render() {
        const { error, errors, isLoaded, profile, posting } = this.state
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header />
                    <div className="social">
                        <div className="box post-edit">
                            <div className="post-panel">
                                <TextField error={!!errors.name} helperText={errors.name} style={{marginBottom: "10px"}} variant="outlined" label="Name" value={profile.name} name="name" onChange={this.handleChange} />
                                <TextField error={!!errors.channel_name} helperText={errors.channel_name} style={{marginBottom: "10px"}} variant="outlined" label="Channel Name" value={profile.channel_name} name="channel_name" onChange={this.handleChange} />
                                <TextField error={!!errors.pic} helperText={errors.pic} style={{marginBottom: "10px"}} variant="outlined" label="Image" value={profile.pic} name="pic" onChange={this.handleChange} />
                                <FormControlLabel
                                    className="spacer"
                                    control={
                                        <Switch
                                            checked={profile.verified}
                                            onChange={this.handleCheck}
                                            name="verified"
                                            color="primary"
                                        />
                                    }
                                    label="Verified"
                                />
                                <FormControlLabel
                                    className="spacer"
                                    control={
                                        <Switch
                                            checked={profile.company}
                                            onChange={this.handleCheck}
                                            name="company"
                                            color="primary"
                                        />
                                    }
                                    label="Company"
                                />
                            </div>
                            <div className="profile-container">
                                <div className="image">
                                    <Avatar src={profile.pic} sx={{ width: 200, height: 200 }} />
                                </div>
                                <div>
                                    <h2>Name: {profile.name}</h2>
                                </div>
                                <div>
                                    <h2>Channel Name: {profile.channel_name}</h2>
                                </div>
                                <div className="second">
                                    <div className={profile.verified ? '' : 'lightsOut'}>Verified</div>
                                    <div className={profile.company ? '' : 'lightsOut'}>Company</div>
                                </div>
                            </div>
                        <div style={{width: "350px"}} />
                        </div>
                        <div className="links">
                            <Link to="/content/profile"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button disabled={posting} variant="contained" color="primary" onClick={() => this.updateProfiles()} >Save</Button>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default withSnackbar(ProfileEdit)
