import React from "react";
import {
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField, Checkbox, Autocomplete, Button, FormControlLabel, Switch
} from "@mui/material";
import {cf} from "../../scripts/custom-fetch";
import {authenticationService} from "../../scripts/authentication.service";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const tableFilter = [
    { value: 'stim', label: "Stim" },
    { value: 'article', label: "Article" },
    { value: 'content', label: "Content" },
    { value: 'users', label: "Users" },
    { value: 'persona', label: "Persona" },
    { value: 'profile', label: "Profile" },
    { value: 'story', label: "Story" },
    { value: 'playlists', label: "Playlists" },
    { value: 'music', label: "Music" },
    { value: 'musicAd', label: "Music Stim" },
    { value: 'reel', label: "Reel" },
    { value: 'reel_stim', label: "Reel Stim" },
]

const actionFilter = [
    { value: 'token', label: "Token" },
    { value: 'update', label: "Update" },
    { value: 'login', label: "Login" },
    { value: 'logout', label: "Logout" },
    { value: 'new', label: "New" },
]

const limit = [
    { value: '0', label: "All" },
    { value: '10', label: "10" },
    { value: '100', label: "100" },
    { value: '1000', label: "1000" },
    { value: '5000', label: "5000" },
]

class audit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            audit: [],
            loaded: false,
            tableFilter: [],
            actionFilter: [],
            shouldDate: false,
            date1: new Date(),
            date2: new Date(),
            limit: 10,
            user: "",
        }
    }

    handleAFilter = (event, newValue) => {this.setState({actionFilter: newValue})}
    handleLimit = (event, newValue) => {this.setState({limit: newValue.value})}
    handleTFilter = (event, newValue) => {this.setState({tableFilter: newValue})}
    handleDate = () => {
        let shouldDate = !this.state.shouldDate
        this.setState({shouldDate})
    }
    setDate1 = (newValue) => {this.setState({date1:newValue})}
    setDate2 = (newValue) => {this.setState({date2:newValue})}
    handleUser = (e) => {this.setState({user: e.target.value})}

    getFilteredAudit = () => {
        this.setState({
            loaded: false,
        }, () => {
            if (this.state.tableFilter.length < 1 && this.state.actionFilter.length < 1 && this.state.user.length < 1 && !this.state.shouldDate && this.state.limit === 10) {
                this.getAudit()
            } else {
                const options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                    body: JSON.stringify({
                        'tableFilter':this.state.tableFilter,
                        'actionFilter':this.state.actionFilter,
                        'user':this.state.user,
                        'limit':this.state.limit,
                        'date1':this.state.date1,
                        'date2':this.state.date2,
                        'shouldDate':this.state.shouldDate,
                    })
                };

                cf.get(`${process.env.REACT_APP_API_POST}/audit/getFilteredAudits`, options)
                    .then((result) => {
                            result.forEach(item => {
                                try {
                                    item.data = JSON.stringify(JSON.parse(item.data),null, 1)
                                } catch {
                                    //pass
                                }
                            })
                            this.setState({
                                audit: result,
                                loaded: true,
                            })
                        }
                    );
            }
        })
    }

    getAudit = () => {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_POST}/audit/getAudits`, options)
            .then((result) => {
                result.forEach(item => {
                    try {
                        item.data = JSON.stringify(JSON.parse(item.data),null, 1)
                    } catch {
                        //pass
                    }
                })
                    this.setState({
                        audit: result,
                        loaded: true,
                    })
                }
            );
    }

    componentDidMount() {
        this.getAudit()
    }

    render() {
        const {loaded, audit, user, date1, date2} = this.state
        return (
            <div className="site-settings">
                <TableContainer sx={{height: "calc(100vh - 80px)",  marginRight: "15px"}} component={Paper}>
                    <div style={{display: "flex", padding: "5px"}}>
                        <Autocomplete
                            size="small"
                            disableCloseOnSelect
                            renderInput={(params) => <TextField style={{width:"100px", marginRight: "10px"}} variant="outlined" {...params} label="Limit" />}
                            options={limit}
                            defaultValue={limit[1]}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </li>
                            )}
                            getOptionLabel={(option) => option.label}
                            onChange={this.handleLimit}
                        />
                        <Autocomplete
                            multiple
                            size="small"
                            disableCloseOnSelect
                            renderInput={(params) => <TextField style={{width:"300px", marginRight: "10px"}} variant="outlined" {...params} label="Filter Table" />}
                            options={tableFilter}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </li>
                            )}
                            getOptionLabel={(option) => option.label}
                            onChange={this.handleTFilter}
                        />
                        <Autocomplete
                            multiple
                            size="small"
                            disableCloseOnSelect
                            renderInput={(params) => <TextField style={{width:"300px", marginRight: "10px"}} variant="outlined" {...params} label="Filter Actions" />}
                            options={actionFilter}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.label}
                                </li>
                            )}
                            getOptionLabel={(option) => option.label}
                            onChange={this.handleAFilter}
                        />
                        <TextField size="small" variant="outlined" label="User" style={{marginRight: "10px"}} value={user} onChange={this.handleUser}></TextField>
                        <FormControlLabel
                            className="spacer"
                            control={
                                <Switch
                                    onChange={this.handleDate}
                                    color="primary"
                                />
                            }
                            label="Date"
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="After"
                                inputFormat="DD/MM/YYYY"
                                onChange={this.setDate1}
                                value={date1}
                                renderInput={(params) => <TextField style={{marginRight: "10px"}} size="small" {...params} />}
                            />
                            <DatePicker
                                label="Before"
                                inputFormat="DD/MM/YYYY"
                                onChange={this.setDate2}
                                value={date2}
                                renderInput={(params) => <TextField style={{marginRight: "10px"}} size="small" {...params} />}
                            />
                        </LocalizationProvider>
                        <Button size="small" variant="contained" onClick={this.getFilteredAudit}>Filter</Button>
                    </div>
                    <Table size="small" stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Table</TableCell>
                                <TableCell>ItemID</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Date Created</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loaded ? (
                                audit.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row.id}</TableCell>
                                        <TableCell>{row.table}</TableCell>
                                        <TableCell>{row.itemId}</TableCell>
                                        <TableCell sx={{maxWidth: "300px", overflowWrap:"break-word"}}><pre style={{whiteSpace: "pre-wrap"}}>{row.data}</pre></TableCell>
                                        <TableCell>{row.action}</TableCell>
                                        <TableCell>{row.user}</TableCell>
                                        <TableCell>{new Date(row.date_created).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <div style={{width:"100%"}}>
                                    <LinearProgress />
                                </div>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

export default audit;
