import React from "react";
import { authenticationService } from "../../scripts/authentication.service";
import Header from "../../header/header";
import {withSnackbar} from "notistack";
import {cf} from "../../scripts/custom-fetch";
import {Avatar, Button, LinearProgress, TextField} from "@mui/material";
import {Link} from "react-router-dom";

function getTable(type) {
    switch (type) {
        case "post":
            return 1
        case "ad":
            return 2
        case "story_cover":
        case "story":
            return 3
        case "watch":
            return 4
        case "watch_ad":
            return 5
        case "tiktok":
            return 6
        case "tiktok_ad":
            return 7
        case "snapchat_cover":
        case "snapchat":
            return 8
        case "snapchat_ad":
            return 9
        case "profiles":
            console.log("Yeeyt")
            return 11
        case 'gmail':
            return 12
        case 'music':
            return 13
        case 'music_stim':
            return 14
        case 'article':
            return 15
        case 'article_media':
            return 16
        default:
            return false;
    }
}

class srcManage extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            id: this.props.match.params.id,
            item: [],
            loaded: false,
            posting: false,
            type: this.props.match.params.type,
            currentUser: authenticationService.currentUserValue,
            error: null
        };
    }

    getItem() {
        let action = getTable(this.state.type)

        if (action > 0 && action < 17) {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                body: JSON.stringify({
                    'id': this.state.id,
                    'type': action
                })
            }

            cf.get(`${process.env.REACT_APP_API_POST}/images/getImage`, options)
                .then((result) => {
                        this.setState({
                            item: result[0],
                            loaded: true,
                        })
                    },
                    (error) => {
                        console.log(error)
                    }
                )
        }
    }

    componentDidMount() {
        this.getItem()
    }

    handleChange(event) {
        let temp = this.state.item;
        temp[event.target.name] = event.target.value;
        this.setState({item: temp})
    }

    updateItem() {
        let action = getTable(this.state.type)
        this.props.enqueueSnackbar('Saving', {variant:"info"});

        this.setState({
            posting: true,
        }, () => {
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authenticationService.currentUserValue.token}`
                },
                body: JSON.stringify({
                    'item': this.state.item,
                    'option': action,
                    'type': this.state.type,
                })
            }

            cf.get(`${process.env.REACT_APP_API_POST}/images/setImage`, options)
                .then((result) => {
                        console.log(result)
                        this.setState({
                            posting: false
                        }, () => {
                            if (!!result) {
                                this.props.enqueueSnackbar('Saved', {variant:"success"});
                            } else {
                                this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                            }
                        });
                    },
                    (error) => {
                        console.log(error)
                        this.setState({
                            posting: false
                        }, () => {
                            this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                        })
                    }
                )
        })
    }

    render() {
        const { error, item, loaded, type, posting } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!loaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else if (type === "profiles") {
            console.log(item)
            return (
                <>
                    <Header />
                    <div className="social">
                        <div className="box post-edit">
                            <div className="post-panel">
                                <div>ID: {item.id}</div>
                                <div>Name: {item.name}</div>
                                <TextField multiline style={{marginBottom: "10px"}} variant="outlined" label="Picture" value={item.pic} name="pic" onChange={this.handleChange} />
                            </div>
                            <div >
                                <Avatar variant="square" style={{width: "400px", height: "400px"}} src={item.pic} />
                            </div>
                            <div className="unique-panel" style={{opacity: 0}}>
                            </div>
                        </div>
                        <div className="links">
                            <Link to="/srcErrors"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button disabled={posting} variant="contained" color="primary" onClick={() => this.updateItem()} >Save</Button>
                        </div>
                    </div>
                </>
            );
        } else if (type === "snapchat") {
            return (
                <>
                    <Header />
                    <div className="social">
                        <div className="box post-edit">
                            <div className="post-panel">
                                <div>ID: {item.id}</div>
                                <div>Title: {item.title}</div>
                                <TextField multiline style={{marginBottom: "10px"}} variant="outlined" label="Content" value={item.src} name="src" onChange={this.handleChange} />
                                <TextField multiline style={{marginBottom: "10px"}} variant="outlined" label="Cover" value={item.cover_src} name="cover_src" onChange={this.handleChange} />
                            </div>
                            <div style={{display: "flex"}}>
                                <div>
                                    Content
                                    <Avatar variant="square" style={{width: "400px", height: "400px", marginRight: "20px"}} src={item.src} />
                                </div>
                                <div>
                                    Cover
                                    <Avatar variant="square" style={{width: "400px", height: "400px"}} src={item.cover_src} />
                                </div>
                            </div>
                            <div className="unique-panel" style={{opacity: 0}}>
                            </div>
                        </div>
                        <div className="links">
                            <Link to="/srcErrors"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button disabled={posting} variant="contained" color="primary" onClick={() => this.updateItem()} >Save</Button>
                        </div>
                    </div>
                </>
            );
        } else if (type === "story") {
            return (
                <>
                    <Header />
                    <div className="social">
                        <div className="box post-edit">
                            <div className="post-panel">
                                <div>ID: {item.id}</div>
                                <div>Title: {item.title}</div>
                                <TextField multiline style={{marginBottom: "10px"}} variant="outlined" label="Content" value={item.src} name="src" onChange={this.handleChange} />
                                <TextField multiline style={{marginBottom: "10px"}} variant="outlined" label="Cover" value={item.cover} name="cover" onChange={this.handleChange} />
                            </div>
                            <div style={{display: "flex"}}>
                                <div>
                                    Content
                                    <Avatar variant="square" style={{width: "400px", height: "400px", marginRight: "20px"}} src={item.src} />
                                </div>
                                <div>
                                    Cover
                                    <Avatar variant="square" style={{width: "400px", height: "400px"}} src={item.cover} />
                                </div>
                            </div>
                            <div className="unique-panel" style={{opacity: 0}}>
                            </div>
                        </div>
                        <div className="links">
                            <Link to="/srcErrors"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button disabled={posting} variant="contained" color="primary" onClick={() => this.updateItem()} >Save</Button>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Header />
                    <div className="social">
                        <div className="box post-edit">
                            <div className="post-panel">
                                <div>ID: {item.id}</div>
                                <div>Title: {item.title}</div>
                                <TextField multiline style={{marginBottom: "10px"}} variant="outlined" label="Content" value={item.src} name="src" onChange={this.handleChange} />
                            </div>
                            <div className="phone">
                                <Avatar variant="square" style={{width: "400px", height: "400px"}} src={item.src} />
                            </div>
                            <div className="unique-panel" style={{opacity: 0}}>
                            </div>
                        </div>
                        <div className="links">
                            <Link to="/srcErrors"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button disabled={posting} variant="contained" color="primary" onClick={() => this.updateItem()} >Save</Button>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(srcManage);
