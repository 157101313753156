import React from 'react';
import {Link} from "react-router-dom";
import Header from "../header/header";
import {authenticationService} from "../scripts/authentication.service";
import Items from "../api/content";
import {Card, CardActionArea, CardActions, CardContent, CardMedia, Fab, Tooltip, Button, InputAdornment, Pagination, TextField, LinearProgress, ToggleButton, ToggleButtonGroup} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {withSnackbar} from "notistack";
import F0F from "../errors/404";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import {searchService} from "../scripts/search.service";

class Article extends React.Component {
    constructor(props) {
        super(props);

        this.searchContent = this.searchContent.bind(this)
        this.updateSearchText = this.updateSearchText.bind(this)

        this.state = {
            type: 1,
            should404: false,
            isStim: false,
            error: null,
            currentSearch: searchService.currentSearchValue.search,
            isLoaded: false,
            content: [],
            currentType: searchService.currentSearchValue.type,
            currentType3: searchService.currentSearchValue.type3,
            searchContent: [],
            loadCount: 0,
            currentPage: searchService.currentSearchValue.page,
            itemsPerPage: 10,
            currentUser: authenticationService.currentUserValue,
        };
    }

    getContent(){
        Items.getAllArticles()
            .then(result => {
                this.setState({
                    content: result,
                    searchContent: result,
                    isLoaded: true
                }, () => {
                    this.searchContent()
                });
            })
    }

    componentDidMount() {
        this.getContent();
    }

    setPage(event, value) {
        let data = searchService.currentSearchValue
        data.page = value
        searchService.setSearch(data)
        this.setState({
            currentPage: value
        })
    }

    updateSearchText(event) {
        let data = searchService.currentSearchValue
        data.search = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentSearch: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType = (event) => {
        let data = searchService.currentSearchValue
        data.type = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentType: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType3 = (event) => {
        let data = searchService.currentSearchValue
        data.type3 = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentType3: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    searchContent() {
        let results = this.state.content

        if (this.state.currentSearch.length !== 0) {
            results = results.filter(obj => {return obj.title.toUpperCase().includes(this.state.currentSearch.toUpperCase())})
        }

        if (this.state.currentType === "new") {
            results = results.sort(function (a,b){
                return (b.id - a.id)
            })
        }

        if (this.state.currentType === "old") {
            results = results.sort(function (a,b){
                return (a.id - b.id)
            })
        }

        if (this.state.currentType3 === "sti") {
            results = results.filter(obj => {return obj.viewability_tracking})
        }

        if (this.state.currentType3 === "nsti") {
            results = results.filter(obj => {return !obj.viewability_tracking})
        }

        this.setState({searchContent:results})
    }

    render() {
        const { error, isLoaded, currentPage, currentSearch, itemsPerPage, searchContent, currentType, currentType3, should404, currentUser } = this.state;
        const indexOfLastPost = currentPage * itemsPerPage;
        const indexOfFirstPost = indexOfLastPost - itemsPerPage;
        const perms = currentUser.perms

        if (should404) {
            return <F0F />
        } else {
            let currentItems = searchContent.slice(indexOfFirstPost, indexOfLastPost)

            if (error) {
                return <div>Error: {error.message}</div>;
            } else if (!isLoaded) {
                return (
                    <>
                        <Header/>
                        <div style={{width:"50%"}}>
                            <LinearProgress />
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <Header/>

                        <div style={{position:"absolute",top:"0",left:"0",marginLeft:"50px",padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", display:"flex", borderRadius: "0 0 5px"}}>
                            <TextField
                                label="Search"
                                style={{marginRight:"10px"}}
                                onChange={this.updateSearchText}
                                focused
                                value={currentSearch}
                                className="foreInputWhite"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ToggleButtonGroup
                                color="primary"
                                value={currentType}
                                exclusive
                                onChange={this.updateSearchType}
                                style={{marginLeft: "10px", border: "#1565c0 2px solid"}}
                            >
                                <ToggleButton className="testing" value="new">New</ToggleButton>
                                <ToggleButton className="testing" value="old">Old</ToggleButton>
                            </ToggleButtonGroup>
                            <ToggleButtonGroup
                                color="primary"
                                value={currentType3}
                                exclusive
                                onChange={this.updateSearchType3}
                                style={{marginLeft: "10px", border: "#1565c0 2px solid"}}
                            >
                                <ToggleButton className="testing" value="all">All</ToggleButton>
                                <ToggleButton className="testing" value="sti">Stims</ToggleButton>
                                <ToggleButton className="testing" value="nsti">Non-Stims</ToggleButton>
                            </ToggleButtonGroup>
                            <Tooltip title="Add" aria-label="add">
                                <Fab style={{marginLeft:"10px"}} color="primary" href={`/article/edit/new`}>
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                        </div>

                        <div className="profiles-container">
                            {currentItems.map((item) => (
                                <Card key={item.id} style={{width:"340px", marginBottom:"20px", backgroundColor: "#424242", marginLeft: "27px", position: "relative"}}>
                                    {item.status === 4 && (
                                        <div className="src-warning">
                                            <Tooltip title={"Source Error"}><GppMaybeIcon color="error" /></Tooltip>
                                        </div>
                                    )}
                                    <CardActionArea>
                                        <div className="mediaCard">
                                            {item.src ? (
                                                <CardMedia
                                                    component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "img" }
                                                    image={item.src}
                                                    title={item.title}
                                                />
                                            ) : (
                                                <div style={{color: "#ffffff85", fontWeight: 700, fontSize: "30px"}}>
                                                    WHERE'S THE LAMB SAUCE
                                                </div>
                                            ) }
                                        </div>
                                        <CardContent style={{backgroundColor: "#515151", height: "80px"}}>
                                            <div style={{color: "rgb(255,255,255)", fontSize: "20px", fontWeight: "600", textAlign: "left"}}>Title</div>
                                            <div style={{color: "rgb(255,255,255)", fontSize: "18px", textAlign: "left", wordBreak: "break-all"}}>{item.title}</div>
                                            <div style={{color: "rgb(255,255,255)", fontSize: "18px", textAlign: "left", wordBreak: "break-all"}}>{new Date(item.date_created).getDay()}/{new Date(item.date_created).getMonth()}/{new Date(item.date_created).getFullYear()}</div>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Link to={perms.c2 ? `/article/edit/${item.id}` : `/article`}>
                                            <Button disabled={!perms.c2} variant="contained" size="small" color="secondary">
                                                Edit
                                            </Button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            ))}
                        </div>
                        <div className="pagination-container">
                            <Pagination color="secondary" count={Math.ceil(searchContent.length / itemsPerPage)} page={currentPage} onChange={(event, page) => this.setPage(event, page)} />
                        </div>
                        <div style={{position: "absolute", top: 0, right: 0, padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", borderRadius: "0 0 0 5px"}}>
                            <Link to="/"><Button style={{marginLeft: "10px"}} variant="contained" color="primary" className="spacer">Back</Button></Link>
                        </div>
                    </>
                );
            }
        }
    }
}

export default withSnackbar(Article);