import React from 'react';
import {authenticationService} from "../../scripts/authentication.service";
import Header from "../../header/header";
import {Link} from "react-router-dom";
import {TextField, Button, LinearProgress, Avatar} from '@mui/material'
import {withSnackbar} from "notistack";
import {cf} from "../../scripts/custom-fetch";

class PostArticleEdit extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            id: this.props.match.params.id,
            article: null,
            isLoaded: false,
            error: null,
            errors: {},
            posting: false,
        };
    }

    getArticle() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.id})
        };

        if (this.state.id === 'new') {
            console.log("New")
            this.setState({
                isLoaded: true,
                article: {
                    id: 'new',
                    title: '',
                    header: '',
                    logo: '',
                    img: '',
                    author: '',
                    body: ''
                }
            });
        } else {
            cf.get(`${process.env.REACT_APP_API_GET}/db/getPostArticle`, options)
                .then((result) => {
                        this.setState({
                            isLoaded: true,
                            article: result[0]
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }

    validate = () => {
        let article = this.state.article
        let errors = {}
        let valid = true

        if (!article.title || article.title.length < 1) {
            valid = false
            errors.title = "Title must exist"
        }

        this.setState({errors:errors})
        return valid
    }

    updateArticle() {
        this.props.enqueueSnackbar('Validating', {variant:"info"});
        if(this.validate()) {
            this.props.enqueueSnackbar('Saving', {variant:"info"});
            let temp = this.state.article
            this.setState({
                posting: true,
            }, () => {
                const options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                    body: JSON.stringify({
                        'id':this.state.id,
                        'article':temp
                    })
                };

                if (this.state.id === 'new') {
                    cf.get(`${process.env.REACT_APP_API_POST}/db/newPostArticle`, options)
                        .then((result) => {
                                console.log(result)
                                this.setState({
                                    posting: false
                                }, () => {
                                    if (!!result) {
                                        this.props.enqueueSnackbar('Saved', {variant:"success"});
                                    } else {
                                        this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                                    }
                                });
                            },
                            (error) => {
                                console.log(error)
                                this.setState({
                                    posting: false
                                }, () => {
                                    this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                                });
                            }
                        )
                } else {
                    cf.get(`${process.env.REACT_APP_API_POST}/db/setPostArticle`, options)
                        .then((result) => {
                                console.log(result)
                                this.setState({
                                    posting: false
                                }, () => {
                                    if (!!result) {
                                        this.props.enqueueSnackbar('Saved', {variant:"success"});
                                    } else {
                                        this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                                    }
                                });
                            },
                            (error) => {
                                console.log(error)
                                this.setState({
                                    posting: false
                                }, () => {
                                    this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                                });
                            }
                        )
                }
            })
        } else {
            this.props.enqueueSnackbar('Validation Failed Please Check Your Inputs', {variant:"error"});
        }
    }

    componentDidMount() {
        this.getArticle()
    }

    handleChange(event) {
        let temp = this.state.article;
        temp[event.target.name] = event.target.value;
        this.setState({article: temp})
    }

    render() {
        const { error, errors, isLoaded, article, posting } = this.state
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header />
                    <div className="social">
                        <div className="box post-edit">
                            <div className="post-panel">
                                <TextField error={!!errors.title} helperText={errors.title} style={{marginBottom: "10px"}} variant="outlined" label="Title" value={article.title} name="title" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Header" value={article.header} name="header" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Logo" value={article.logo} name="logo" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Img" value={article.img} name="img" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Author" value={article.author} name="author" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" multiline label="Body" value={article.body} name="body" onChange={this.handleChange} />
                            </div>
                            <div className="profile-container">
                                <div className="image">
                                    <Avatar src={article.logo} sx={{ width: 200, height: 200 }} />
                                </div>
                                <div className="image">
                                    <Avatar src={article.img} sx={{ width: 200, height: 200 }} />
                                </div>
                                <div>
                                    <h2>Name: {article.title}</h2>
                                </div>
                                <div>
                                    <h2>Channel Name: {article.header}</h2>
                                </div>
                                <div>
                                    <h2>Channel Name: {article.author}</h2>
                                </div>
                                <div>
                                    <h2>Channel Name: {article.body}</h2>
                                </div>
                            </div>
                            <div style={{width: "350px"}} />
                        </div>
                        <div className="links">
                            <Link to="/content/postArticle"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button disabled={posting} variant="contained" color="primary" onClick={() => this.updateArticle()} >Save</Button>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default withSnackbar(PostArticleEdit)
