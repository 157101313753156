import React from "react";
import {FormControlLabel, Switch, TextField} from "@mui/material";

class watchStimLeft extends React.Component {
    render() {
        return (
            <div className="post-panel">
                <TextField error={!!this.props.errors.title} helperText={this.props.errors.title} style={{marginBottom: "10px"}} variant="outlined" label="Title" value={this.props.content.title} name="title" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" multiline label="Description" value={this.props.content.desc} name="desc" onChange={this.props.handleUpdate(1)} />
                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Content" value={this.props.content.src} name="src" onChange={this.props.handleUpdate(1)} />
                <FormControlLabel
                    className="spacer"
                    control={
                        <Switch
                            checked={this.props.content.viewability_tracking}
                            onChange={this.props.handleUpdate(2)}
                            name="viewability_tracking"
                            color="primary"
                        />
                    }
                    label="Viewability Tracking"
                />
            </div>
        );
    }
}

export default watchStimLeft;