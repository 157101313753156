import React, { useState } from "react";
import { Button } from "@mui/material";
import { authenticationService } from "../scripts/authentication.service";
import WarningModal from "./warningModal";
import validLanguageCodes from "./allCodes.json"

export const ModalType = {
  NewApp: 0,
  NewLanguageCode: 1
}

const SimpleEntryModal = ({ hideModal, updateParentStateFn, apps, modaltype, existingCodes }) => {
  const [newInput, setNewInput] = useState(""); // State to track the new application input
  const [showWarningModal, setShowWarningModal] = useState(false);
  

  const handleWarningModal = () => {
   setShowWarningModal(true);
  };
  const handleCloseWarningModal = () => {
    setShowWarningModal(false);
  }

  const addNewApp = () => {
      //  Make an API POST request to save the new application
      fetch(`${process.env.REACT_APP_API_POST}/language/applications`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
        },
        body: JSON.stringify({ applicationName: newInput }), // Sending the application name to the backend
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((res) => {
          console.log(res);
          updateParentStateFn([
            ...apps,
            { id: res.id, application_name: newInput },
          ]);
          hideModal(); // close the modal
        })
        .catch((error) => {
          console.error("Error adding new application:", error);
        });
  };

  // check list, if new app does not exist, add app and close modal
  // or
  // check list, if new app is already on list message box modal will appear to let user know they cannot add a duplicate app
  const checkForDuplicateApp = () => {
    let isDuplicate = false;
    apps.forEach(app => {
      if (app.application_name === newInput) {
        isDuplicate = true;
      }
    })
    console.log("isDuplicate:", isDuplicate, apps, newInput);
    if (isDuplicate) {
      handleWarningModal();
      // alert("Application already Exists. Please add an alternative application name.")
      
    } else {
      addNewApp();
      hideModal();
    }
  };

  const handleNewLanguageCode = () => {
    var newLabel = validLanguageCodes.find(c => c.code === (newInput || existingCodes[0].code)).label;
    //  Make an API POST request to save the new language code
    fetch(`${process.env.REACT_APP_API_POST}/language/codes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
      },
      body: JSON.stringify({ codeId: newInput || existingCodes[0].code, codeLabel: newLabel }), // Sending the language code to the backend
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((res) => {
        console.log(res);
        updateParentStateFn([
          ...apps,
          { id: res.id, code: newInput, label: newLabel },
        ]);
        hideModal(); // close the modal
      })
      .catch((error) => {
        console.error("Error adding new language code:", error);
      });
      hideModal();
  }

  const handleSubmit = () => {
    switch (modaltype) {
      case ModalType.NewApp:
        checkForDuplicateApp();
        break;
      case ModalType.NewLanguageCode:
        handleNewLanguageCode();
        break;
      default:
        console.warn("modal type not set!");
        break;
    }
  }

  const getModalTitle = () => {
    switch (modaltype) {
      case ModalType.NewApp:
        return "App"
      case ModalType.NewLanguageCode:
        return "Language Code"
      default:
        console.warn("modal type not set!");
        return ""
    }
  }

  const getModalInput = () => {
    switch (modaltype) {
      case ModalType.NewApp:
        return (<input
          placeholder="Enter Application Name"
          className="simpleModal-input"
          type="text"
          name="simpleModal"
          value={newInput}
          onChange={(e) => {
            setNewInput(
              () => {
                try {
                  return e.target.value.trim().charAt(0).toUpperCase() + e.target.value.trim().slice(1)
                } catch {
                  return e.target.value
                }
              }
            )
          }}
        />)
      case ModalType.NewLanguageCode:
        return (
          <select className="simpleModal-input" name="new-language-code"
          onChange={(e) => {
            setNewInput(e.target.value)
          }}
          >
            {validLanguageCodes.filter(valcode => !existingCodes.map(excode => excode.code).includes(valcode.code)).map((code, i) => {
              return <option value={code.code} key={i} >{code.label}</option>
            })}
          </select>
        )
      default:
        console.warn("modal type not set!");
        return ""
    }
  }

  return (
    <>
      <div className="simpleModal">
        <div >
          <h2>New {getModalTitle()}</h2>
        </div>
        {/* if app not on list user can add the app to the list by entering the name here - saves to .... table  */}
        {/* Update the newInput state on input change */}
        {/* rather than text input, NewLanguageCode modal should select from dropdown of these language codes: https://datahub.io/core/language-codes/r/3.html */}

        { getModalInput() }

        <div className="buttonStyles" >
          {/* ADD Button */}
          <Button
            variant="contained"
            color="primary"
            className="button "
            onClick={() => {
              handleSubmit();
            }}
          >
            Add
          </Button>
          {/* SAVE Button */}
          <Button
            variant="contained"
            color="primary"
            className="button "
            onClick={hideModal}
          >
            Cancel
          </Button>
        </div>
      </div>
      {showWarningModal && (<WarningModal onClose={handleCloseWarningModal}/>)}
    </>
  );
};
export default SimpleEntryModal;
