import {BehaviorSubject} from "rxjs";

const currentSearchSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentSearch')));

export const searchService = {
    setSearch,
    currentSearch: currentSearchSubject.asObservable(),
    get currentSearchValue () { return currentSearchSubject.value },
};

function setSearch(data) {
    localStorage.setItem('currentSearch', JSON.stringify(data));
    currentSearchSubject.next(data);
}