import React from "react";
import {Autocomplete, FormControlLabel, Switch, TextField} from "@mui/material";

class storyLeft extends React.Component {
    render() {
        const options = this.props.profiles.map((option) => {
            const firstLetter = option.name[0].toUpperCase();
            return {
                firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                ...option,
            };
        });
        return (
            <div className="post-panel">
                <TextField
                    error={!!this.props.errors.title}
                    helperText={this.props.errors.title}
                    style={{ marginBottom: "10px" }}
                    variant="outlined"
                    label="Title"
                    value={this.props.content.title}
                    name="title"
                    onChange={this.props.handleUpdate(1)}
                />
                <Autocomplete
                    defaultValue={
                        options.filter((obj) => {
                            return obj.id === parseInt(this.props.content.profile_id);
                        })[0]
                    }
                    renderInput={(params) => (
                        <TextField
                            style={{ marginBottom: "10px" }}
                            variant="outlined"
                            {...params}
                            label="Profile"
                        />
                    )}
                    options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    onChange={this.props.handleUpdate(3)}
                />
                <TextField
                    multiline
                    style={{ marginBottom: "10px" }}
                    variant="outlined"
                    label="Content"
                    value={this.props.content.src}
                    name="src"
                    onChange={this.props.handleUpdate(1)}
                />
                <TextField
                    style={{ marginBottom: "10px" }}
                    variant="outlined"
                    label="Cover"
                    value={this.props.content.cover}
                    name="cover"
                    onChange={this.props.handleUpdate(1)}
                />
                <TextField
                    error={!!this.props.errors.link}
                    helperText={this.props.errors.link}
                    style={{ marginBottom: "10px" }}
                    variant="outlined"
                    label="Link"
                    value={this.props.content.link}
                    name="link"
                    onChange={this.props.handleUpdate(1)}
                />
                <TextField
                    error={!!this.props.errors.cta}
                    helperText={this.props.errors.cta}
                    style={{ marginBottom: "10px" }}
                    variant="outlined"
                    label="CTA"
                    value={this.props.content.cta}
                    name="cta"
                    onChange={this.props.handleUpdate(1)}
                />
                <TextField
                    error={!!this.props.errors.posted_on}
                    helperText={this.props.errors.posted_on}
                    style={{ marginBottom: "10px" }}
                    variant="outlined"
                    label="Posted On"
                    value={this.props.content.posted_on}
                    name="posted_on"
                    onChange={this.props.handleUpdate(1)}
                />
                <FormControlLabel
                    className="spacer"
                    control={
                        <Switch
                            checked={this.props.content.promoted}
                            onChange={this.props.handleUpdate(2)}
                            name="promoted"
                            color="primary"
                        />
                    }
                    label="Promoted"
                />
                <FormControlLabel
                    className="spacer"
                    control={
                        <Switch
                            checked={this.props.content.viewability_tracking}
                            onChange={this.props.handleUpdate(2)}
                            name="viewability_tracking"
                            color="primary"
                        />
                    }
                    label="Viewability Tracking"
                />
            </div>
        );
    }
}

export default storyLeft;
