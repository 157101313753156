import React from "react";
import { Button } from "@mui/material";
import DeleteButton from "./DeleteButton";
import DeleteRowModal, { ModalType } from "./DeleteRowModal";

export default class LanguageItemTable extends React.Component {
  constructor(props) {
    super();

    this.addKeyRef = React.createRef();
    this.addValueRef = React.createRef();
    this.scrollableRef = React.createRef();
    this.state = {
      showModal: false,
      modalType: ModalType.DeleteRow,
      deleteIndex: null,
    };
  }

  componentDidMount() {
    this.props.updateScrollRef(this.scrollableRef.current);
  }

  componentWillUnmount() {
    this.props.updateScrollRef(null);
  }

  addNewItem = () => {
    if (!this.addKeyRef.current.value || !this.addValueRef.current.value)
      return;

    // should popup warning about key
    if (
      this.props.updatedLanguageItems.some(
        (uli) =>
          uli.key === this.addKeyRef.current.value &&
          uli.application === this.props.selectedApp
      )
    ) {
      alert("Can't have duplicate key!");
      return;
    }

    const addedLanguageItem = {
      application: this.props.selectedApp,
      id: null,
      key: this.addKeyRef.current.value,
      value: this.addValueRef.current.value,
      last_updated: null,
    };

  this.props.onAddLanguageItem(addedLanguageItem);

    this.addKeyRef.current.value = "";
    this.addValueRef.current.value = "";
  };

  handleTableUpdate = (i, changed, updateType) => {
    const updatedLanguageItems = [...this.props.updatedLanguageItems];

    switch (updateType) {
      case "VALUE":
        updatedLanguageItems[i].value = changed;
        break;
      case "KEY":
        updatedLanguageItems[i].key = changed;
        break;
      default:
        return;
    }

    this.props.setUpdatedLanguageItems(updatedLanguageItems);
  };

  handleLanguageItemDelete = (i) => {
    // opens the modal and sets the delete index
    this.setState({ showModal: true, deleteIndex: i });

  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleConfirmDelete = () => {
    console.log(
      "handleLanguageItemDelete:",
      this.props.handleLanguageItemDelete
    );

    const {deleteIndex} = this.state;
    // perform deletion
    this.props.handleLanguageItemDelete(deleteIndex);
    //and close modal
    this.setState({ showModal: false });
  };

  handleApplicationSelect = (selectedApp) => {
    this.props.onApplicationSelect(selectedApp);
  }
 

  renderTable = () => {
    return this.props.updatedLanguageItems.map((langItem, index) => {
      return (
        <div
          className="languageDisplay_grid"
          key={index + this.props.selectedApp + this.props.selectedLanguage}
        >
          <input
            className="languageDisplay_styles "
            value={langItem.key}
            readOnly
            disabled
          ></input>
          <input
            className="languageDisplay_styles"
            type="text"
            value={langItem.value}
            onChange={(e) =>
              this.handleTableUpdate(index, e.target.value, "VALUE")
            }
          ></input>
          <input
            className="languageDisplay_styles"
            type="text"
            value={!!langItem.last_updated && !langItem.last_updated.startsWith("0000") ? new Date(langItem.last_updated).toLocaleDateString("en-GB") : ""}
            readOnly
            disabled
          ></input>
          <DeleteButton onClick={() => this.handleLanguageItemDelete(index)} />
        </div>
      );
    });
  };

  render() {
    return (
      <div className="DropDown_Height" ref={this.scrollableRef} onScroll={this.props.tableOnScroll}>
        {this.renderTable()}
        {this.state.showModal && (
          <DeleteRowModal
            modaltype={this.state.modalType}
            onClose={this.handleCloseModal}
            onConfirmDelete={this.handleConfirmDelete}
          />
        )}
        {/* user can add english phrases and text here, add button to save details onto the languageItem table.  */}
        <div className="languageDisplay_grid">
          <input
            ref={this.addKeyRef}
            className="languageDisplay_styles display_height"
          ></input>
          <input
            ref={this.addValueRef}
            className="languageDisplay_styles display_height"
          ></input>
          <Button
            variant="contained"
            color="primary"
            type="text"
            className="languageDisplay_addBtn"
            onClick={this.addNewItem}
          >
            Add
          </Button>
        </div>
      </div>
    );
  }
}
