import React from 'react';
import {authenticationService} from "../scripts/authentication.service";
import Header from "../header/header";
import {Link} from "react-router-dom";
import {TextField, Switch, FormControlLabel, Button, LinearProgress, Avatar, Autocomplete} from '@mui/material'
import {withSnackbar} from "notistack";
import {cf} from "../scripts/custom-fetch";

class ArticleEdit extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        this.state = {
            id: this.props.match.params.id,
            profile: null,
            isLoaded: false,
            article: [],
            articleMedia: [],
            removedMedia: [],
            error: null,
            errors: {},
            posting: false,
            locked: false,
            bodyOpen: false,
            mediaOpen: false,
        };
    }

    getArticle() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.id})
        };

        if (this.state.id === 'new') {
            console.log("New")
            this.setState({
                article: {
                    id: 'new',
                    title: '',
                    pic: '',
                    profile_id: 1,
                    templates: [],
                    author: '',
                    posted_on: '',
                    desc: '',
                    body: '',
                    viewability_tracking: 0,
                    lang_id: 1,
                    template_name:'',
                }
            }, () => {this.getArticleMedia()});
        } else {
            cf.get(`${process.env.REACT_APP_API_GET}/article/getArticle`, options)
                .then((result) => {
                        this.setState({
                            article: result[0]
                        }, () => this.getArticleMedia());
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
        }
    }

    getArticleMedia() {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({'id':this.state.id})
        };

        cf.get(`${process.env.REACT_APP_API_GET}/article/getArticleMedia`, options)
            .then((result) => {
                    this.setState({
                        articleMedia: result,
                    }, () => this.getArticleTemplates());
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    getArticleTemplates() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        }

        cf.get(`${process.env.REACT_APP_API_GET}/article/getTemplates`, options)
            .then((result) => {
                    this.setState({
                        templates: result,
                        isLoaded: true,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    validate = () => {
        let article = this.state.article
        let errors = {}
        let valid = true
        if (!article.title || article.title.length < 1) {
            valid = false
            errors.title = "Title must exist"
        }
        if (escape(article.title).length >= 1024) {
            valid = false
            errors.title = "Title too long"
        }

        if (escape(article.author).length >= 1024) {
            valid = false
            errors.author = "Author too long"
        }

        if (escape(article.posted_on).length >= 1024) {
            valid = false
            errors.posted_on = "Posted On too long"
        }

        this.setState({errors: errors})
        return valid
    }

    updateArticle() {
        this.props.enqueueSnackbar('Validating', {variant:"info"});
        if(!this.validate()) {
            this.props.enqueueSnackbar('Validation Failed Please Check Your Inputs', {variant:"error"});
            return
        }
        this.props.enqueueSnackbar('Saving', {variant:"info"});
        this.setState({
            posting: true,
        }, () => {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
                body: JSON.stringify({
                    'id':this.state.id,
                    'content':this.state.article,
                    'media':this.state.articleMedia,
                    'removedMedia':this.state.removedMedia,
                })
            };

            if (this.state.id === 'new') {
                cf.get(`${process.env.REACT_APP_API_POST}/article/newArticle`, options)
                    .then((result) => {
                            console.log(result)
                            this.setState({
                                posting: false,
                                locked: true,
                            }, () => {
                                if (!!result) {
                                    this.props.enqueueSnackbar('Saved', {variant:"success"});
                                    this.props.enqueueSnackbar("To edit further find item in content list", { variant: "info" });
                                } else {
                                    this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                                }
                            });
                        },
                        (error) => {
                            console.log(error)
                            this.setState({
                                posting: false
                            }, () => {
                                this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                            });
                        }
                    )
            } else {
                cf.get(`${process.env.REACT_APP_API_POST}/article/setArticle`, options)
                    .then((result) => {
                            console.log(result)
                            this.setState({
                                posting: false
                            }, () => {
                                if (!!result) {
                                    this.props.enqueueSnackbar('Saved', {variant:"success"});
                                } else {
                                    this.props.enqueueSnackbar('Save Failed - Please Try Again', {variant:"warning"});
                                }
                            });
                        },
                        (error) => {
                            console.log(error)
                            this.setState({
                                posting: false
                            }, () => {
                                this.props.enqueueSnackbar('Save Failed - Please Contact an Admin', {variant:"error"});
                            });
                        }
                    )
            }
        })
    }

    componentDidMount() {
        this.getArticle()
    }

    handleChange(event) {
        console.log(event)
        let article = this.state.article;
        article[event.target.name] = event.target.value;
        this.setState({article: article})
    }

    handleMediaChange(event, value) {
        let articleMedia = this.state.articleMedia;
        articleMedia[value][event.target.name] = event.target.value;
        this.setState({articleMedia: articleMedia})
    }

    handleCheck(event) {
        let article = this.state.article;
        if (article[event.target.name] === 1) {
            article[event.target.name] = 0
        } else {
            article[event.target.name] = 1
        }
        this.setState({article: article})
    }

    addItem() {
        let articleMedia = this.state.articleMedia;
        articleMedia.push({
            article_id:this.state.id,
            id:"new",
            src:"",
            title:""
        })
        this.setState({articleMedia: articleMedia})
    }

    removeItem(id) {
        let articleMedia = this.state.articleMedia;
        let removedMedia = this.state.removedMedia;
        removedMedia.push(articleMedia[id])
        articleMedia.splice(id, 1);
        this.setState({articleMedia: articleMedia, removedMedia:removedMedia})
    }

    updateTemplate = (e, newValue) => {
        if (newValue) {
            let temp = this.state.article;
            temp["template_id"] = newValue.id;
            this.setState({ article: temp });
        }
    }

    toggleBody = () => {
        this.setState({bodyOpen:!this.state.bodyOpen})
    }

    toggleMedia = () => {
        this.setState({mediaOpen:!this.state.mediaOpen})
    }

    render() {
        const { error, errors, isLoaded, article, posting, bodyOpen, mediaOpen, articleMedia, locked, templates } = this.state
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            const templateOptions = templates.map((option) => {
                const firstLetter = option.title[0].toUpperCase();
                if (option.important) {
                    return {
                        firstLetter: " IMPORTANT",
                        ...option
                    }
                } else {
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                        ...option,
                    };
                }
            });
            return (
                <>
                    <Header />
                    <div className="social">
                        <div className="box post-edit" style={{justifyContent: "center"}}>
                            <div className={bodyOpen ? "body-editor" : "body-editor hide"}>
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Body" value={article.body} multiline maxRows={30} name="body" onChange={this.handleChange} />
                                <Button variant="contained" onClick={this.toggleBody}>Close</Button>
                            </div>
                            <div className={mediaOpen ? "body-editor" : "body-editor hide"} style={{justifyContent: "space-between"}}>
                                <div className="media-container">
                                    {articleMedia.map((item ,key) => (
                                            <div className="media-item" key={key}>
                                                <div>Media - {key + 1}</div>
                                                <TextField style={{marginBottom: "10px", width: "100%"}} variant="outlined" size="small" label="Title" value={item.title} name="title" onChange={(e) => this.handleMediaChange(e, key)} />
                                                <TextField style={{marginBottom: "10px", width: "100%"}} variant="outlined" size="small" label="Src" value={item.src} name="src" onChange={(e) => this.handleMediaChange(e, key)} />
                                                <div>
                                                    <Button variant="contained" onClick={() => this.removeItem(key)}>Remove</Button>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className="media-buttons">
                                    <Button variant="contained" style={{marginRight: "10px"}} onClick={() => this.addItem()}>Add</Button>
                                    <Button variant="contained" onClick={this.toggleMedia}>Close</Button>
                                </div>
                            </div>
                            <div className="post-panel" style={{position: "fixed", left: "50px", top: "50%", transform: "translate(0, -50%)"}}>
                                <TextField error={!!errors.title} helperText={errors.title} style={{marginBottom: "10px"}} variant="outlined" label="Title" value={article.title} name="title" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Desc" value={article.desc} name="desc" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Pic" value={article.src} name="src" onChange={this.handleChange} />
                                <TextField error={!!errors.author} helperText={errors.author} style={{marginBottom: "10px"}} variant="outlined" label="Author" value={article.author} name="author" onChange={this.handleChange} />
                                <TextField error={!!errors.posted_on} helperText={errors.posted_on} style={{marginBottom: "10px"}} variant="outlined" label="Posted On" value={article.posted_on} name="posted_on" onChange={this.handleChange} />
                                <TextField style={{marginBottom: "10px"}} variant="outlined" label="Body" value={article.body} name="body"onFocus={this.toggleBody} />
                                <FormControlLabel
                                    className="spacer"
                                    control={
                                        <Switch
                                            checked={article.viewability_tracking}
                                            onChange={this.handleCheck}
                                            name="viewability_tracking"
                                            color="primary"
                                        />
                                    }
                                    label="Viewability Tracking"
                                />
                            </div>
                            <div className="profile-container" style={{overflow: "hidden", width:"50%"}}>
                                <div className="image">
                                    <Avatar src={article.src} sx={{ width: 200, height: 200 }} variant="rounded" />
                                </div>
                                <div>
                                    <h2>Title: {article.title}</h2>
                                </div>
                                <div>
                                    <h2>Profile: {article.profile_id}</h2>
                                </div>
                                <div>
                                    <h2>Author: {article.author}</h2>
                                </div>
                                <div>
                                    <h2>Posted On: {article.posted_on}</h2>
                                </div>
                                <div>
                                    <h2>Desc: {article.desc}</h2>
                                </div>
                                <div>
                                    <h2>Body: {article.body}</h2>
                                </div>
                            </div>
                            <div className="unique-panel" style={{position: "fixed", right: "0", top: "50%", transform: "translate(0, -50%)", left: "unset", maxHeight: "80%"}}>
                                <Autocomplete
                                    defaultValue={templates[article.template_id]}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{ marginBottom: "10px" }}
                                            variant="outlined"
                                            {...params}
                                            label="Template"
                                        />
                                    )}
                                    options={templateOptions.sort(
                                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                                    )}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.title}
                                    onChange={this.updateTemplate}
                                />
                                <Button variant="contained" color="primary" onClick={() => this.toggleMedia()}>Open Media Manager</Button>
                            </div>
                        </div>
                        <div className="links">
                            <Link to="/article"><Button disabled={posting} variant="contained" color="primary" className="spacer">Back</Button></Link>
                            <Button disabled={posting || locked} variant="contained" color="primary" onClick={() => this.updateArticle()} >Save</Button>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default withSnackbar(ArticleEdit)
