import React from 'react';
import {Link} from "react-router-dom";
import Header from "../../header/header";
import {authenticationService} from "../../scripts/authentication.service";
import {Card, CardActionArea, CardActions, CardContent, CardMedia, Fab, Tooltip, TextField, Button, Pagination, InputAdornment, LinearProgress} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {cf} from "../../scripts/custom-fetch";
import {withSnackbar} from "notistack";
import Content from "../../api/content"

class Profiles extends React.Component {
    constructor(props) {
        super(props);
        this.searchContent = this.searchContent.bind(this)
        this.state = {
            error: null,
            isLoaded: false,
            profiles: [],
            searchProfiles: [],
            restore: false,
            deletedItems: [],
            openDelete: false,
            deleteID: 0,
            currentPage: 1,
            itemsPerPage: 10,
            currentUser: authenticationService.currentUserValue,
        };
    }

    callProfiles() {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/db/getProfiles`, options)
            .then((result) => {
                this.setState({
                    isLoaded: true,
                    profiles: result,
                    searchProfiles: result
                });
            }, (error) =>{
                this.setState({
                    isLoaded: true,
                    error
                });
            })
    }

    openDelete = (item) => {
        this.setState({
            openDelete: true,
            deleteID: item
        })
    }

    closeDelete = () => {
        this.setState({
            openDelete: false
        })
    }

    delete = () => {
        let state
        let msg1
        let msg2
        if (this.state.restore) {
            msg1 = "Restoring"
            msg2 = "Restored"
            state = 1
        } else {
            msg1 = "Deleting"
            msg2 = "Deleted"
            state = 3
        }
        this.props.enqueueSnackbar(msg1, {variant:"info"});
        this.setState({
            openDelete: false,
        })
        Content.disable(11,state,this.state.deleteID).then(result => {
            this.callProfiles()
            this.setState({
                restore: false
            })
            console.log(result)
            this.props.enqueueSnackbar(msg2, {variant:"success"});
        })
    }

    openRestore = () => {
        Content.getDeletedItems(11).then(result => {
            if (result) {
                this.setState({
                    deletedItems: result,
                    restore: true
                })
            }
        })
    }

    closeRestore = () => {
        this.setState({
            restore: false
        })
    }


    componentDidMount() {
        this.callProfiles();
    }

    setPage(event, value) {
        this.setState({
            currentPage: value
        })
    }

    searchContent(event) {
        let results = this.state.profiles.filter(obj => {return obj.name.toUpperCase().includes(event.target.value.toUpperCase())})
        this.setState({
            searchProfiles: results
        })
    }

    render() {
        const { error, isLoaded, currentPage, itemsPerPage, searchProfiles, openDelete, restore, deletedItems } = this.state;
        const indexOfLastPost = currentPage * itemsPerPage;
        const indexOfFirstPost = indexOfLastPost - itemsPerPage;
        let currentPosts
        if (restore) {
            currentPosts = deletedItems.slice(indexOfFirstPost, indexOfLastPost)
        } else {
            currentPosts = searchProfiles.slice(indexOfFirstPost, indexOfLastPost)
        }

        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header/>

                    <div style={{position:"absolute",top:"0",left:"0",marginLeft:"50px",padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", display:"flex", borderRadius: "0 0 5px"}}>
                        <TextField
                            label="Search"
                            focused
                            style={{marginRight:"10px"}}
                            onChange={(e) => this.searchContent(e, 1)}
                            className="foreInputWhite"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Tooltip title="Add" aria-label="add">
                            <Fab color="primary" href={`/content/profile/edit/new`}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    </div>

                    <div className="profiles-container">
                        {currentPosts.map((item) => (
                            <Card key={item.id} style={{width:"340px", marginBottom:"20px", backgroundColor: "#424242", marginLeft: "27px"}}>
                                <CardActionArea>
                                    <div className="mediaCard">
                                        <CardMedia
                                            component={item.pic.split(".")[item.pic.split(".").length - 1] === "mp4" ? "video" : "img" }
                                            image={item.pic}
                                            title={item.title}
                                        />
                                    </div>
                                    <CardContent style={{backgroundColor: "#515151", height: "80px"}}>
                                        <div style={{color: "rgb(255,255,255)", fontSize: "20px", fontWeight: "600", textAlign: "left"}}>Name</div>
                                        <div style={{color: "rgb(255,255,255)", fontSize: "18px", textAlign: "left", wordBreak: "break-all"}}>{item.name}</div>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Link to={`/content/profile/edit/${item.id}`}>
                                        <Button variant="contained" size="small" color="secondary">
                                            Edit
                                        </Button>
                                    </Link>
                                    <Link>
                                        <Button variant="contained" size="small" color="secondary" onClick={() => this.openDelete(item.id)}>
                                            {restore ? "Restore" : "Delete"}
                                        </Button>
                                    </Link>
                                </CardActions>
                            </Card>
                        ))}
                    </div>

                    <div className={(openDelete ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <p>Are you sure ?</p>
                            <div style={{display: "flex", marginTop: "10px"}}>
                                <Button variant="contained" color="primary" style={{flex: 1, marginRight: "10px"}} onClick={this.delete}>Yes</Button>
                                <Button variant="contained" color="primary" style={{flex: 1}} onClick={this.closeDelete}>Nope</Button>
                            </div>
                        </div>
                    </div>
                    <div className="pagination-container">
                        <Pagination color="secondary" count={Math.ceil(searchProfiles.length / itemsPerPage)} page={currentPage} onChange={(event, page) => this.setPage(event, page)} />
                    </div>
                    <div style={{position: "absolute", top: 0, right: 0, padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", borderRadius: "0 0 0 5px"}}>
                        {restore ? <Button variant="contained" color="primary" onClick={() => this.closeRestore()} className="spacer">Normal</Button> : <Button variant="contained" color="primary" onClick={() => this.openRestore()} className="spacer">Restore</Button>}
                        <Link to="/content"><Button style={{marginLeft: "10px"}} variant="contained" color="primary" className="spacer">Back</Button></Link>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(Profiles);