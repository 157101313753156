import React from "react";
import Header from "../header/header";
import {withSnackbar} from "notistack";
import {authenticationService} from "../scripts/authentication.service";
import {cf} from "../scripts/custom-fetch";
import {Avatar, Button, TextField} from "@mui/material";

class profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            user: {},
            currentUser: authenticationService.currentUserValue,
        }
    }

    getUser = () => {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({
                'user': this.state.currentUser.username
            })
        };

        cf.get(`${process.env.REACT_APP_API_GET}/profile/getProfile`, options)
            .then(result => {
                let user = result[0]
                user.pass1 = "negative"
                user.pass2 = "negative"
                this.setState({user:user, loaded: true})
            })
    }

    updateUser = () => {
        this.props.enqueueSnackbar('Saving', {variant:"info"});
        let user = this.state.user
        if (user.pass1 === "") {
            user.pass1 = "negative"
        }
        console.log(user)
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({
                'user': user.username,
                'pass': user.pass1,
                'pic': user.pic,
                'perms': JSON.parse(user.permissions)
            })
        };

        cf.get(`${process.env.REACT_APP_API_GET}/profile/updateProfile`, options)
            .then(() => {
                this.props.enqueueSnackbar('Saved', {variant:"success"});
                this.getUser()
            })
    }

    handleEdit = (e) => {
        let user = this.state.user
        user[e.target.name] = e.target.value
        this.setState({user:user})
    }

    componentDidMount() {
        this.getUser()
    }

    render() {
        const { loaded, user } = this.state;
        let created = new Date(user.date_created)
        let last = new Date(user.last_login)
        if (loaded) {
            return (
                <>
                    <Header />
                    <div className="profile">
                        <div className="infoBox">
                            <Avatar sx={{ width: 100, height: 100 }} src={user.pic} />
                            <div className="id">{user.id}</div>
                            <div className="title">Username</div>
                            <div>{user.username}</div>
                            <div className="title">Created</div>
                            <div>{created.getDate()} / {created.getMonth()+1} / {created.getFullYear()}</div>
                            <div className="title">Last Login</div>
                            <div>{last.getDate()} / {last.getMonth()+1} / {last.getFullYear()}</div>
                        </div>
                        <div className="editBox">
                            <TextField variant="outlined" label="Username" name="username" onChange={this.handleEdit} disabled value={user.username} focused />
                            <TextField variant="outlined" label="Password" type="password" onChange={this.handleEdit} name="pass1" error={user.pass1 !== user.pass2} helperText={user.pass1 !== user.pass2 && "Passwords Dont Match"} value={user.pass1} focused />
                            <TextField variant="outlined" label="Confirm" type="password" onChange={this.handleEdit} name="pass2" error={user.pass1 !== user.pass2} helperText={user.pass1 !== user.pass2 && "Passwords Dont Match"} value={user.pass2} focused />
                            <TextField variant="outlined" label="Pic" name="pic" onChange={this.handleEdit}  value={user.pic} focused />
                            <Button variant="contained" disabled={user.pass1 !== user.pass2} onClick={this.updateUser}>Submit</Button>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Header />
                    <div>Loading</div>
                </>
            );
        }

    }
}

export default withSnackbar(profile);
