import React from "react";
import {withSnackbar} from "notistack";
import {Avatar, Button, LinearProgress} from "@mui/material";

let noRender = ["like", "love", "haha", "wow", "sad", "angry", "social_settings", "date_created", "processed", "lang_id", "viewability_tracking", "status"]

class Manage extends React.Component {
    render() {
        const { dupes, links, open } = this.props;
        if (!open) {
            return (
                <>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="dupe-manage">
                        {dupes.map((item, index) => (
                            <div className="dupe-card" key={index}>
                                {item.id === dupes[0].id ? "Original" : (
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <div className={item.title === dupes[0].title && item.src === dupes[0].src && item.desc === dupes[0].desc ? "on" : "off"} />
                                        <div>Detected Duplicate</div>
                                    </div>
                                )}
                                {links[index] && (
                                    <div>Links: {links[index].length}</div>
                                )}
                                {Object.entries(item).map((item, index) => (
                                    <>
                                        {!noRender.includes(item[0]) && (
                                            <div key={index}>{item[0]}: {item[1]}</div>
                                        )}
                                    </>
                                ))}
                                <Avatar variant="square" style={{width: "100px", height: "100px"}} src={item.src} />
                                <div className="dupe-button">
                                    <Button size="small" style={{marginRight:"10px"}} disabled={links[index].length === 0} variant="contained" onClick={() => {this.props.cleanUp(index)}}>Cleanup</Button>
                                    <Button size="small" variant="contained" disabled={links[index].length > 0} onClick={() => {this.props.remove(item.id)}}>Remove</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(Manage);
