import React from "react";
import { authenticationService } from "../../scripts/authentication.service";
import Header from "../../header/header";
import { Link } from "react-router-dom";
import {
    TextField,
    Button,
    Switch,
    FormControlLabel,
    Autocomplete,
    LinearProgress, Slider,
} from "@mui/material";
import { withSnackbar } from "notistack";
import { cf } from "../../scripts/custom-fetch";
import Content from "../../api/content";
import {ChromePicker} from "react-color";
import DMail from "../../socials/article/components/displayEmail";

const lookalikes = [
    { value: "fb", label: "Facebook" },
    { value: "ig", label: "Instagram" },
    // { value: "vk", label: "VK" },
];

class gmailEdit extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleProfile = this.handleProfile.bind(this);
        this.handleTag = this.handleTag.bind(this);
        this.handleSelectLookalike = this.handleSelectLookalike.bind(this);

        this.state = {
            id: !!this.props.match ? this.props.match.params.id : this.props.id,
            isModal: !!this.props.id ? true : false,
            content: [],
            profiles: [],
            isLoaded: false,
            error: null,
            posting: false,
            tags: [],
            linkTag: [],
            linkTags: [],
            lookalike: lookalikes[2],
        };
    }

    getProfiles() {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
        };

        cf.get(`${process.env.REACT_APP_API_GET}/db/getProfiles`, options).then(
            (result) => {
                this.setState(
                    {
                        profiles: result,
                    },
                    () => {
                        this.getTags();
                        if (this.state.id === "new") {
                            let temp = this.state.content;
                            temp.profile_id = result[0].id;
                            this.setState({
                                content: temp,
                            });
                        }
                    }
                );
            },
            (error) => {
                this.setState({error});
            }
        );
    }

    getContent() {
        if (this.state.id === "new") {
            console.log("New");
            this.setState(
                {
                    content: {
                        id: "new",
                        profile_id: "1",
                        title: "",
                        src: "",
                        promoted: 0,
                        viewability_tracking: 0,
                        desc: "",
                        posted_on: "",
                    },
                }
            );
        } else {
            Content.getContent(this.state.id, 12).then((result) => {
                this.setState({content: result}, () => {
                    this.getProfiles();
                });
            });
        }
    }

    updateContent() {
        let temp = this.state.content;
        this.props.enqueueSnackbar("Saving", { variant: "info" });
        this.setState(
            {
                posting: true,
            },
            () => {
                if (this.state.id === "new") {
                    Content.newContent(temp, 12).then(
                        (result) => {
                            console.log(result);
                            this.linkTag();
                        },
                        (error) => {
                            console.log(error);
                            this.setState(
                                {
                                    posting: false,
                                },
                                () => {
                                    this.props.enqueueSnackbar(
                                        "Save Failed - Please Contact an Admin",
                                        { variant: "error" }
                                    );
                                }
                            );
                        }
                    );
                } else {
                    Content.setContent(this.state.id, temp, 12).then(
                        (result) => {
                            console.log(result);
                            this.linkTag();
                        },
                        (error) => {
                            console.log(error);
                            this.setState(
                                {
                                    posting: false,
                                },
                                () => {
                                    this.props.enqueueSnackbar(
                                        "Save Failed - Please Contact an Admin",
                                        { variant: "error" }
                                    );
                                }
                            );
                        }
                    );
                }
            }
        );
    }

    getTags() {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
        };

        cf.get(`${process.env.REACT_APP_API_GET}/tag/getTags`, options).then(
            (result) => {
                this.setState(
                    {
                        tags: result,
                    },
                    () => {
                        this.getLink();
                    }
                );
            },
            (error) => {this.setState({error});
            }
        );
    }

    getLink() {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
            body: JSON.stringify({
                content_id: this.state.id,
                option: 12,
            }),
        };

        cf.get(`${process.env.REACT_APP_API_GET}/tag/getLink`, options).then(
            (result) => {
                this.setState(
                    {
                        linkTag: result,
                        isLoaded: true,
                    }
                );
            },
            (error) => {
                this.setState({error});
            }
        );
    }

    linkTag() {
        console.log("called");
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
            body: JSON.stringify({
                content_id: this.state.id,
                tag_ids: this.state.linkTags,
                option: 12,
            }),
        };

        cf.get(`${process.env.REACT_APP_API_POST}/tag/linkTag`, options).then(
            (result) => {
                console.log(result);
                this.setState(
                    {
                        posting: false,
                    },
                    () => {
                        if (!!result) {
                            this.props.enqueueSnackbar("Saved", { variant: "success" });
                        } else {
                            this.props.enqueueSnackbar("Save Failed - Please Try Again", {
                                variant: "warning",
                            });
                        }
                    }
                );
            },
            (error) => {
                console.log(error);
                this.setState(
                    {
                        posting: false,
                    },
                    () => {
                        this.props.enqueueSnackbar(
                            "Save Failed - Please Contact an Admin",
                            { variant: "error" }
                        );
                    }
                );
            }
        );
    }

    componentDidMount() {
        this.getContent();
    }

    handleTag(event, newValue) {
        this.setState({ linkTags: newValue });
    }

    handleChange(event) {
        let temp = this.state.content;
        temp[event.target.name] = event.target.value;
        this.setState({ content: temp });
    }

    handleChangeComplete = (color) => {
        let temp = this.state.content;
        temp.background_color = color.hex;
        this.setState({ content: temp });
    }

    handleSlider = (event) => {
        let temp = this.state.content;
        temp.content_width = event.target.value;
        this.setState({ content: temp });
    }

    handleCheck(event) {
        let temp = this.state.content;
        if (temp[event.target.name] === 1) {
            temp[event.target.name] = 0;
        } else {
            temp[event.target.name] = 1;
        }
        this.setState({ content: temp });
    }

    handleProfile(event, newValue) {
        if (newValue) {
            let temp = this.state.content;
            temp["profile_id"] = newValue.id;
            this.setState({ content: temp });
        }
    }

    handleSelectLookalike(newValue) {
        this.setState({ lookalike: newValue });
    }

    render() {
        const { error, isLoaded, content, profiles, posting, tags, linkTag, isModal } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    {isModal ? "" : (
                        <Header />
                    ) }
                    <div style={{ width: "50%" }}>
                        <LinearProgress />
                    </div>
                </>
            );
        } else {
            const options = profiles.map((option) => {
                const firstLetter = option.name[0].toUpperCase();
                return {
                    firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                    ...option,
                };
            });
            let profile = profiles.filter((obj) => {
                return obj.id === parseInt(content.profile_id);
            });

            const tagOptions = tags.map((option) => {
                const firstLetter = option.name[0].toUpperCase();
                if (option.important) {
                    return {
                        firstLetter: " IMPORTANT",
                        ...option
                    }
                } else {
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
                        ...option,
                    };
                }
            });
            const defaultTags = linkTag.map((option) => {
                return tagOptions.filter((obj) => {
                    return obj.id === parseInt(option.tag);
                })[0];
            });
            console.log(profile);
            return (
                <>
                    {isModal ? "" : (
                        <Header />
                    ) }
                    <div className="social">
                        <div className="box post-edit" style={isModal ? {margin: 0} : {}}>
                            <div className="post-panel">
                                <TextField
                                    style={{ marginBottom: "10px" }}
                                    variant="outlined"
                                    label="Title"
                                    value={content.title}
                                    name="title"
                                    onChange={this.handleChange}
                                />
                                <Autocomplete
                                    defaultValue={
                                        options.filter((obj) => {
                                            return obj.id === parseInt(content.profile_id);
                                        })[0]
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            style={{ marginBottom: "10px" }}
                                            variant="outlined"
                                            {...params}
                                            label="Profile"
                                        />
                                    )}
                                    options={options.sort(
                                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                                    )}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.handleProfile}
                                />
                                <TextField
                                    style={{ marginBottom: "10px" }}
                                    variant="outlined"
                                    label="Desc"
                                    value={content.desc}
                                    name="desc"
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    multiline
                                    style={{ marginBottom: "10px" }}
                                    variant="outlined"
                                    label="Content"
                                    value={content.src}
                                    name="src"
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    style={{ marginBottom: "10px" }}
                                    variant="outlined"
                                    label="Posted On"
                                    value={content.posted_on}
                                    name="posted_on"
                                    onChange={this.handleChange}
                                />
                                <div style={{alignSelf: "center", marginTop: "10px"}} >
                                    <ChromePicker color={content.background_color} onChangeComplete={ this.handleChangeComplete }/>
                                </div>
                                <Slider
                                    aria-label="Temperature"
                                    defaultValue={content.content_width}
                                    onChange={this.handleSlider}
                                    valueLabelDisplay="auto"
                                    step={5}
                                    marks
                                    min={40}
                                    max={100}
                                />
                                <FormControlLabel
                                    className="spacer"
                                    control={
                                        <Switch
                                            checked={content.promoted}
                                            onChange={this.handleCheck}
                                            name="promoted"
                                            color="primary"
                                        />
                                    }
                                    label="Promoted"
                                />
                                <FormControlLabel
                                    className="spacer"
                                    control={
                                        <Switch
                                            checked={content.viewability_tracking}
                                            onChange={this.handleCheck}
                                            name="viewability_tracking"
                                            color="primary"
                                        />
                                    }
                                    label="Viewability Tracking"
                                />
                            </div>
                            <div>
                                <DMail mail={content} />
                            </div>
                            <div className="unique-panel" style={{ color: "black" }}>
                                <Autocomplete
                                    multiple
                                    defaultValue={defaultTags}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{ marginBottom: "10px" }}
                                            variant="outlined"
                                            {...params}
                                            label="Tags"
                                        />
                                    )}
                                    options={tagOptions.sort(
                                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                                    )}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.name}
                                    onChange={this.handleTag}
                                />
                                {isModal ? (
                                    <>
                                        <Button
                                            disabled={posting}
                                            variant="contained"
                                            color="primary"
                                            className="spacer"
                                            onClick={() => this.props.close()}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            disabled={posting}
                                            variant="contained"
                                            color="primary"
                                            style={{marginLeft: "10px"}}
                                            onClick={() => this.updateContent()}
                                        >
                                            Save
                                        </Button>
                                        <div>Warning Content in modal maybe closer than they appear</div>
                                    </>
                                ) : ""}
                            </div>
                        </div>
                        {isModal ? "" : (
                            <div className="links">
                                <Link to="/content/gmail">
                                    <Button
                                        disabled={posting}
                                        variant="contained"
                                        color="primary"
                                        className="spacer"
                                    >
                                        Back
                                    </Button>
                                </Link>
                                <Button
                                    disabled={posting}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.updateContent()}
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(gmailEdit);
