import React from "react";
import {Button, CardMedia, TextField} from "@mui/material";

class Story extends React.Component {
    render() {
        return (
            <div>
                {this.props.stories.map((item, index) => (
                    <div style={{display: "flex", justifyContent: "space-between"}} className="edit-container drag-container" key={item.id} >
                        <div className="drag-panel">
                            <div style={{width: "69px", padding: "5px", backgroundColor: "#00b9db"}} className="drag-order">
                                <TextField className="spacer" style={{color: "white"}} variant="outlined" label="Order" name={index} value={item.order} onChange={this.props.handleStoryOrder} />
                            </div>
                            <div className="square">
                                {item.src ? (
                                    <CardMedia
                                        component={item.src.split(".")[item.src.split(".").length - 1] === "mp4" ? "video" : "img" }
                                        image={item.src}
                                        title={item.title}
                                    />
                                ) : (
                                    <div className="noSrc">
                                        No Src
                                    </div>
                                ) }
                            </div>
                            <div>
                                <h1>{item.title}</h1>
                            </div>
                        </div>
                        <div className="unlink">
                            <Button variant="contained" color="primary" onClick={() => this.props.unlink(item.id)}>Unlink</Button>
                            <Button variant="contained" color="primary" style={{marginLeft:"10px"}} onClick={() => this.props.edit(item.id)}>Edit</Button>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default Story;
