import React from 'react';

class Tags extends React.Component {

    render() {
        let ltags = this.props.lTags.filter(obj => {return obj.content === this.props.item})
        let tags = []
        ltags.forEach(item => {
            tags.push((this.props.tags.filter(obj => {return obj.id === item.tag}))[0])
        })

        return (
            <>
                {tags.map(item => (
                    <div key={item.id} style={{backgroundColor:item.color,border:"2px solid rgba(0,0,0,0.5)", borderRadius: "100px", fontSize: "15px", padding: "5px", lineHeight: "15px", marginBottom: "5px"  }}>
                        {item.name}
                    </div>
                ))}
            </>
        )
    }
}

export default Tags