import Rollbar from "rollbar";

const rollbar = new Rollbar({
    accessToken: "f979630ece724a69b6c4e6ee3d50eb09",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        environment: process.env.REACT_APP_ENVIRONMENT
    }
});

export default rollbar;