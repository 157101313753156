import React from "react";
import {Button} from "@mui/material";

class maintenance extends React.Component {
    render() {
        return (
            <div className={this.props.online ? "maintenance" : "maintenance m-Open"}>
                Sorry we are updating things :)
                <Button color="primary" variant="contained" onClick={() => this.props.reload()}>Reload</Button>
            </div>
        )
    }
}

export default maintenance;