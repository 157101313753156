import React from "react";
import { authenticationService } from "../scripts/authentication.service";
import { cf } from "../scripts/custom-fetch";
import Header from "../header/header";
import { Link } from "react-router-dom";
import Content from "../api/content";
import Stims from "../api/stims";
import {Button, LinearProgress} from "@mui/material";
import { withSnackbar } from "notistack";
import PostLeft from "./components/postLeft";
import PostRight from "./components/postRight";
import Socials from "./socials.json"
import StimLeft from "./components/stimLeft";
import StimRight from "./components/stimRight";
import ContentPreview from "./components/contentPreview";
import StoryLeft from "./components/storyLeft";
import StoryRight from "./components/storyRight";
import WatchLeft from "./components/watchLeft";
import WatchRight from "./components/watchRight";
import WatchStimRight from "./components/watchStimRight";
import WatchStimLeft from "./components/watchStimLeft";
import ReelLeft from "./components/reelLeft";
import ReelStimLeft from "./components/reelStimLeft";
import TagRight from "./components/tagRight";
import Data from "../api/data";
import VideoLeft from "./components/videoLeft";
import VideoStimLeft from "./components/videoStimLeft";

const lookalikes = [
    { value: "fb", label: "Facebook" },
    { value: "ig", label: "Instagram" },
    { value: "pn", label: "Pinterest" },
    // { value: "vk", label: "VK" },
];

class PostEdit extends React.Component {
    constructor(props) {
        super(props);

        this.updateContent = this.updateContent.bind(this);

        this.state = {
            id: !!this.props.match ? this.props.match.params.id : this.props.id,
            type: !!this.props.match ? this.props.match.params.type : this.props.type,
            isModal: !!this.props.id ? true : false,
            content: null,
            profiles: null,
            linkTag: [],
            tags: [],
            lang: [],
            articles: [],
            loaded: false,
            posting: false,
            locked: false,
            errors: {},
            error: null,
            lookalike: lookalikes[0],
        };
    }

    //Get Lang in system
    getLang() {
        Data.getLangs().then((result) => {
            this.setState({lang: result} , () => {
                this.getLink();
            });
        }, (error) => {
            this.setState({error}, () => {
                this.getLink();
            });
        })
    }

    //Get post content
    getContent() {
        if (this.state.id === "new") {
            //Get base content from json
            this.setState(
                {
                    content: Socials[0][this.state.type],
                },
                () => {
                    this.getProfiles();
                }
            );
        } else {
            //Get existing content from db
            if (this.state.type.includes("stim")){
                Stims.getStim(this.state.id, this.state.type).then((result) => {
                    this.setState(
                        {
                            content: result,
                        },
                        () => {
                            this.getProfiles();
                        }
                    );
                });
            } else {
                Content.getContent(this.state.id, this.state.type).then((result) => {
                    this.setState(
                        {
                            content: result,
                        },
                        () => {
                            this.getProfiles();
                        }
                    );
                });
            }
        }
    }

    //Get the profiles
    getProfiles() {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
        };

        cf.get(`${process.env.REACT_APP_API_GET}/db/getProfiles`, options).then(
            (result) => {
                this.setState(
                    {
                        profiles: result,
                    },
                    () => {
                        if (this.state.type === "stim" || this.state.type === "content" ) {
                            this.getPostArticles()
                        } else {
                            this.getTags();
                        }
                        console.log(result[0].id)
                        //If new profile must be set
                        if (this.state.id === "new") {
                            let content = this.state.content;
                            console.log(result[0].id)
                            console.log(content)
                            content.profile_id = result[0].id;
                            this.setState({
                                content: content,
                            });
                        }
                    }
                );
            }
        );
    }

    //Get the profiles
    getPostArticles() {
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authenticationService.currentUserValue.token}`,
            },
        };

        cf.get(`${process.env.REACT_APP_API_GET}/db/getPostArticles`, options).then(
            (result) => {
                this.setState(
                    {
                        articles: result,
                    }, () => {
                        this.getTags()
                    }
                );
            }
        );
    }

    //Get all the tags linked to post
    getLink() {
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json", Authorization: `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({
                content_id: this.state.id,
                option: this.state.type,
            }),
        };

        cf.get(`${process.env.REACT_APP_API_GET}/tag/getLink`, options).then(
            (result) => {
                this.setState(
                    {
                        linkTag: result,
                        loaded: true,
                    }
                );
            }
        );
    }

    //Get all the tags
    getTags() {
        const options = {
            method: "GET",
            headers: {"Content-Type": "application/json", Authorization: `Bearer ${authenticationService.currentUserValue.token}`},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/tag/getTags`, options).then(
            (result) => {
                this.setState(
                    {
                        tags: result,
                    },
                    () => {
                        this.getLang()
                    }
                );
            }
        );
    }

    //Link a tag to a post
    linkTag() {
        const options = {
            method: "POST",
            headers: {"Content-Type": "application/json", Authorization: `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({
                content_id: this.state.id,
                tag_ids: this.state.linkTag,
                option: this.state.type,
            }),
        };

        cf.get(`${process.env.REACT_APP_API_POST}/tag/linkTag`, options).then(
            (result) => {
                console.log(result);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    validate = () => {
        let content = this.state.content
        let errors = {}
        let valid = true
        let toValidate = {
            'title': [1, 3],
            'comments': [2],
            'likes': [2],
            'shares': [2],
            'views': [2],
            'location': [3],
            'posted_on': [3],
            'promo_button': [3],
            'link': [3],
            'cta': [3],
            'cover_text': [3],
            'cover_desc': [3],
            'song': [3],
            'promo_a': [3],
            'promo_b': [3],
            'promo_c': [3],
            'promo_d': [3],
            'promo_e': [3],
        }
        let validateLengths = {
            'title': 1024,
            'location': 1024,
            'posted_on': 1024,
            'promo_button': 1024,
            'link': 1024,
            'cta': 1024,
            'cover_text': 1024,
            'cover_desc': 1024,
            'song': 1024,
            'promo_a': 1024,
            'promo_b': 1024,
            'promo_c': 1024,
            'promo_d': 1024,
            'promo_e': 1024,
        }

        let temp = Object.entries(content)
        temp.forEach(item => {
            if (toValidate.hasOwnProperty(item[0])) {
                console.log(item)
                toValidate[item[0]].forEach(validator => {
                    switch (validator) {
                        case 1:
                            if (!content[item[0]] || content[item[0]].length < 1) {
                                valid = false
                                errors[item[0]] = "Must exist"
                            }
                            break;
                        case 2:
                            if (!content[item[0]] && content[item[0]] !== 0) {
                                valid = false
                                errors[item[0]] = "Must Exist"
                            } else if (isNaN(Number(content[item[0]]))) {
                                valid = false
                                errors[item[0]] = "Must be a Number"
                            }
                            break;
                        case 3:
                            if (escape(content[item[0]]).length >= validateLengths[item[0]]) {
                                valid = false
                                errors[item[0]] = "Too long"
                            }
                            break;
                        default:
                            console.log("ruh roh")
                            break;
                    }
                })
            }
        })

        this.setState({errors:errors})
        return valid
    }

    //Update the content item in the db
    updateContent() {
        this.props.enqueueSnackbar('Validating', {variant:"info"});
        if(this.validate()) {
            this.props.enqueueSnackbar("Saving", { variant: "info" });
            this.setState(
                {
                    posting: true,
                },
                () => {
                    if (this.state.id === "new") {
                        if (this.state.type.includes("stim")){
                            Stims.newStim(this.state.content, this.state.type).then((result) => {
                                this.linkTag();
                                if (!!result) {
                                    this.setState({
                                        posting: false,
                                        locked: true,
                                    })
                                    this.props.enqueueSnackbar("Saved", { variant: "success" });
                                    this.props.enqueueSnackbar("To edit further find item in content list", { variant: "info" });
                                } else {
                                    this.props.enqueueSnackbar("Save Failed - Please Try Again", {variant: "warning"});
                                }
                            });
                        } else {
                            Content.newContent(this.state.content, this.state.type).then((result) => {
                                this.linkTag();
                                if (!!result) {
                                    this.setState({
                                        posting: false,
                                        locked: true,
                                    })
                                    this.props.enqueueSnackbar("Saved", { variant: "success" });
                                    this.props.enqueueSnackbar("To edit further find item in content list", { variant: "info" });
                                } else {
                                    this.props.enqueueSnackbar("Save Failed - Please Try Again", {variant: "warning"});
                                }
                            });
                        }
                    } else {
                        if (this.state.type.includes("stim")){
                            Stims.setStim(this.state.id, this.state.content, this.state.type).then(
                                (result) => {
                                    this.linkTag();
                                    this.setState(
                                        {
                                            posting: false,
                                        },
                                        () => {
                                            if (!!result) {
                                                this.props.enqueueSnackbar("Saved", { variant: "success" });
                                            } else {
                                                this.props.enqueueSnackbar(
                                                    "Save Failed - Please Try Again",
                                                    { variant: "warning" }
                                                );
                                            }
                                        }
                                    );
                                }
                            );
                        } else {
                            Content.setContent(this.state.id, this.state.content, this.state.type).then(
                                (result) => {
                                    this.linkTag();
                                    this.setState(
                                        {
                                            posting: false,
                                        },
                                        () => {
                                            if (!!result) {
                                                this.props.enqueueSnackbar("Saved", { variant: "success" });
                                            } else {
                                                this.props.enqueueSnackbar(
                                                    "Save Failed - Please Try Again",
                                                    { variant: "warning" }
                                                );
                                            }
                                        }
                                    );
                                }
                            );
                        }
                    }
                }
            );
        } else {
            this.props.enqueueSnackbar('Validation Failed Please Check Your Inputs', {variant:"error"});
        }
    }

    componentDidMount() {
        this.getContent();
    }

    //Updating state using a Curry function
    handleUpdate = (type, id) => {
        return (e, newValue) => {
            let content = this.state.content;
            switch (type) {
                case 1:
                    content[e.target.name] = e.target.value;
                    this.setState({content:content})
                    break;
                case 2:
                    if (content[e.target.name] === 1) {
                        content[e.target.name] = 0;
                    } else {
                        content[e.target.name] = 1;
                    }
                    this.setState({content:content})
                    break;
                case 3:
                    content["profile_id"] = newValue.id;
                    content["name"] = newValue.name;
                    content["pic"] = newValue.pic;
                    this.setState({content:content})
                    break;
                case 4:
                    content["like"] = 0
                    content["love"] = 0
                    content["haha"] = 0
                    content["wow"] = 0
                    content["sad"] = 0
                    content["angry"] = 0
                    newValue.forEach((item) => {
                        content[item.value] = 1
                    })
                    this.setState({content:content})
                    break;
                case 5:
                    this.setState({ [id]:newValue });
                    break;
                case 6:
                    content["article_id"] = newValue.id;
                    this.setState({content:content})
                    break;
                default:
                    console.log("ima do nothing")
                    break;
            }
        }
    }

    render() {
        const {isModal, errors, error, loaded, content, profiles, linkTag, tags, posting, locked, type, lookalike, lang, articles} = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!loaded) {
            return (
                <>
                    {isModal ? "" : (
                        <Header />
                    ) }
                    <div style={{ width: "50%" }}>
                        <LinearProgress />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    {isModal ? "" : (
                        <Header />
                    ) }
                    <div className="social">
                        <div className="box post-edit" style={isModal ? {margin: 0} : {}}>
                            {type === "content" && (
                                <PostLeft
                                    errors={errors}
                                    content={content}
                                    profiles={profiles}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "stim" && (
                                <StimLeft
                                    errors={errors}
                                    content={content}
                                    profiles={profiles}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "story" && (
                                <StoryLeft
                                    errors={errors}
                                    content={content}
                                    profiles={profiles}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "watch" && (
                                <WatchLeft
                                    errors={errors}
                                    content={content}
                                    profiles={profiles}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "watch_stim" && (
                                <WatchStimLeft
                                    errors={errors}
                                    content={content}
                                    profiles={profiles}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "reel" && (
                                <ReelLeft
                                    errors={errors}
                                    content={content}
                                    profiles={profiles}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "reel_stim" && (
                                <ReelStimLeft
                                    errors={errors}
                                    content={content}
                                    profiles={profiles}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "video" && (
                                <VideoLeft
                                    errors={errors}
                                    content={content}
                                    profiles={profiles}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "video_stim" && (
                                <VideoStimLeft
                                    errors={errors}
                                    content={content}
                                    profiles={profiles}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            <ContentPreview
                                content={content}
                                lookalike={lookalike}
                                type={this.state.type}
                            />
                            {type === "content" && (
                                <PostRight
                                    content={content}
                                    linkTag={linkTag}
                                    articles={articles}
                                    tags={tags}
                                    lang={lang}
                                    posting={posting}
                                    isModal={isModal}
                                    close={!!this.props.close && this.props.close}
                                    updateContent={this.updateContent}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "stim" && (
                                <StimRight
                                    errors={errors}
                                    content={content}
                                    linkTag={linkTag}
                                    articles={articles}
                                    tags={tags}
                                    lang={lang}
                                    posting={posting}
                                    isModal={isModal}
                                    close={!!this.props.close && this.props.close}
                                    updateContent={this.updateContent}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "story" && (
                                <StoryRight
                                    content={content}
                                    linkTag={linkTag}
                                    tags={tags}
                                    lang={lang}
                                    posting={posting}
                                    isModal={isModal}
                                    close={!!this.props.close && this.props.close}
                                    updateContent={this.updateContent}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "watch" && (
                                <WatchRight
                                    content={content}
                                    linkTag={linkTag}
                                    tags={tags}
                                    lang={lang}
                                    posting={posting}
                                    isModal={isModal}
                                    close={!!this.props.close && this.props.close}
                                    updateContent={this.updateContent}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {type === "watch_stim" && (
                                <WatchStimRight
                                    content={content}
                                    linkTag={linkTag}
                                    tags={tags}
                                    lang={lang}
                                    posting={posting}
                                    isModal={isModal}
                                    close={!!this.props.close && this.props.close}
                                    updateContent={this.updateContent}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                            {(type.includes("reel") || type.includes("snapchat") || type.includes("video")) && (
                                <TagRight
                                    content={content}
                                    linkTag={linkTag}
                                    tags={tags}
                                    lang={lang}
                                    posting={posting}
                                    isModal={isModal}
                                    close={!!this.props.close && this.props.close}
                                    updateContent={this.updateContent}
                                    handleUpdate={this.handleUpdate}
                                />
                            )}
                        </div>
                        {isModal ? "" : (
                            <div className="links">
                                <Link to={`/content/${this.state.type}`}>
                                    <Button
                                        disabled={posting}
                                        variant="contained"
                                        color="primary"
                                        className="spacer"
                                    >
                                        Back
                                    </Button>
                                </Link>
                                <Button
                                    disabled={posting || locked}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.updateContent()}
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(PostEdit);
